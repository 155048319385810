import React, { useEffect } from "react";
import jQuery from "jquery";
import $ from "jquery";
import { Header } from "../../Home/Header";
import BIMigrationImg2 from ".././../../../Assets/img/banner/1.jpg";
import about from "../../../../Assets/img/about/1.jpg";
import { Footer } from "../../Home/Footer";
import "../../../../Assets/js/progress-bar.min.js";
import "../../../../Assets/js/count-to.js";
// import "../../../../Assets/js/bootsnav.js";
import "../../../../Assets/js/main.js";
import "../../../../Assets/css/bootstrap.min.css";
import "../../../../Assets/css/font-awesome.min.css";
import "../../../../Assets/css/themify-icons.css";
import "../../../../Assets/css/elegant-icons.css";
import "../../../../Assets/css/flaticon-set.css";
import "../../../../Assets/css/magnific-popup.css";
import "../../../../Assets/css/owl.carousel.min.css";
import "../../../../Assets/css/owl.theme.default.min.css";
import "../../../../Assets/css/animate.css";
// import "../../../../Assets/css/bootsnav.css";
import "../../../../Assets/css/style.css";
import "../../../../Assets/css/responsive.css";
import ScrollToTop from "react-scroll-to-top";
import BIMigrationImg from ".././../../../Assets/img/BusinessIntellegence/BIMigration.png";
const BIMigration = () => {
  useEffect(() => {
    setTimeout(() => {
      $("#preloader").hide();
    }, 1000);
  }, []);

  return (
    <>
      <Header />
      <div
        className="breadcrumb-area text-center shadow dark text-light bg-cover"
        style={{ backgroundImage: `url(${BIMigrationImg2})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>BI Migration</h1>
              <ul className="breadcrumb">
                <li>
                  <a href="/">
                    <i className="fas fa-home" />
                    Home
                  </a>
                </li>
                <li className="active">BI Migration</li>
              </ul>{" "}
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="services-details-area default-padding">
        <div className="container">
          <div className="services-details-items">
            <div className="row">
              <div className="col-lg-8 services-single-content">
                <h2>BI Migration</h2>
                <p>
                  Business Intelligence tools such as Cognos, Business Objects,
                  OBIEE and SAS go through generational changes in their efforts
                  to leapfrog each other. Such changes can be challenging to
                  understand in terms of their implications to your existing BI
                  environments and investments.Vaanam educates clients on the
                  underlying technology changes and advises them on migration
                  paths to newer technology versions or even to different BI
                  platforms.
                </p>
                <p></p>
                <ul>
                  <li>
                    The Analysis Phase provides an assessment of your
                    environment that includes the existing performance and
                    capacity of your infrastructure and architecture, along with
                    data migration objects.
                  </li>
                  <li>
                    This phase provides recommendations and go-forward
                    strategies that identify options to achieve your business
                    objectives.
                  </li>
                  <li>
                    Vaanam has developed different implementation roadmaps that
                    align with the configuration and deployment procedures that
                    are necessary for the product line your company selects.
                  </li>
                  <li>
                    Our BI Migration services are deployed in three phases.The
                    Planning Phase consists of an initial briefing session to
                    identify resources, understand migration objective, set
                    expectations, and scope and collect documentation.
                  </li>
                </ul>

                <img src={BIMigrationImg} className="BIImg"></img>
              </div>

              <div className="col-lg-4 services-sidebar">
                {/* Single Widget */}
                <div className="single-widget services-list">
                  <h4 className="widget-title">Solutions List</h4>
                  <div className="content">
                    <ul>
                      <li>
                        <a href="/Enterprise">Enterprise Application</a>
                      </li>
                      <li>
                        <a href="/MicrosoftServices">Microsoft Services</a>
                      </li>
                      <li>
                        <a href="/AIML" style={{ textTransform: "initial" }}>
                          AI and ML Services
                        </a>
                      </li>
                      <li>
                        <a href="/Mobility">Mobility Services</a>
                      </li>
                      <li>
                        <a href="/BusinessIntellegence">
                          Business Intelligence
                        </a>
                      </li>
                      <ul>
                        <li>
                          <a href="/BIAssessment">BI Assessment</a>
                        </li>
                        <li className="current-item">
                          <a href="/BIMigration">BI Migration</a>
                        </li>
                        <li>
                          <a href="/BIReports">BI Reports & Analysis</a>
                        </li>
                      </ul>
                      <li>
                        <a href="/Reality">Reality Technologies</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Widget */}
                <div
                  className="single-widget quick-contact text-light"
                  style={{ backgroundImage: `url(${about})` }}
                >
                  <div className="content">
                    <i className="fas fa-phone" />
                    <h4>Need any help?</h4>

                    <h2>+91 422 256 7626</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop smooth color="#2b5690" />
      <Footer />
    </>
  );
};
export default BIMigration;
