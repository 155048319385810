import React, { useState, useReducer } from "react";
import { Header } from "../../Client/Home/Header";
import InquiryServices from "../../../Services/InquiryServices";
import "jquery";
import $ from "jquery";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
var InquiryServicesObj = new InquiryServices();
function ViewInquiry(props) {
  return (
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header no-bd">
          <h5 class="modal-title">
            <span class="fw-mediumbold">Inquiry Details</span>
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
            data-dismiss="modal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group  ">
                  <label>Name</label>

                  <input
                    type="text"
                    id="orderby"
                    className="form-control"
                    defaultValue={props.Model.name}
                    readOnly
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group  ">
                  <label>Email</label>

                  <input
                    type="text"
                    id="orderby"
                    className="form-control"
                    defaultValue={props.Model.email}
                    readOnly
                  />
                </div>
              </div>

              <div className="col-sm-12">
                <div className="form-group ">
                  <label>Phone</label>

                  <input
                    type="text"
                    id="orderby"
                    className="form-control"
                    defaultValue={props.Model.phone}
                    readOnly
                  />
                </div>
              </div>

              <div className="col-sm-12">
                <div className="form-group ">
                  <label>Company Name</label>

                  <input
                    type="text"
                    id="orderby"
                    className="form-control"
                    defaultValue={props.Model.companyName}
                    readOnly
                  />
                </div>
              </div>

              <div className="col-sm-12">
                <div className="form-group ">
                  <label>
                    Project Requirement<span className="text-danger"></span>
                  </label>

                  <textarea
                    id="question"
                    className="form-control"
                    defaultValue={props.Model.projectRequirement}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}
export default ViewInquiry;
