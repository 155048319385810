import React, { useState, useReducer, useEffect } from "react";
import HeaderDashboard from "../DashBoard/HeaderDashboard";
import Swal from "sweetalert2";
import { FaBeer } from "react-icons/fa";
import ContactServices from "../../../Services/ContactServices";
import ViewContact from "./ViewContact";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import InquiryServices from "../../../Services/InquiryServices";
import ViewInquiry from "../Inquiry/ViewInquiry";
import ScrollToTop from "react-scroll-to-top";
import $ from "jquery";
import Preloader from "../../Preloader.js";
import Cookies from "universal-cookie";
import LoginView from "../Login/LoginView";

var InquiryServicesObj = new InquiryServices();
var ContactServicesObj = new ContactServices();

function ContactListTable() {
  const cookies = new Cookies();
  const [Auth, SetAuth] = useState();
  const [ContactDetails, setContactDetails] = useState([]);

  function refreshPage() {
    window.location.reload(false);
  }
  const [InquiryDetails, setInquiryDetails] = useState([]);
  useEffect(() => {
    SetAuth(cookies.get("userID"));
    InquiryServicesObj.GetInquiryList().then((res) => {
      if (res.data != null && res.data.length > 0) {
        setInquiryDetails([...res.data]);
      }
    });
    ContactServicesObj.GetContactList().then((res) => {
      if (res.data != null && res.data.length > 0) {
        setContactDetails([...res.data]);
      }
      setTimeout(() => {
        $("#preloader").hide();
      }, 1000);
    });
  }, []);

  const [Model, setModel] = useState({
    ContactID: "",
    name: "",
    email: "",
    phone: "",
    companyName: "",
    aboutProject: "",
  });
  const [Model2, setModel2] = useState({
    talkID: "",
    name: "",
    email: "",
    phone: "",
    companyName: "",
    projectRequirement: "",
  });

  return Auth ? (
    <>
      <Preloader />

      <HeaderDashboard />
      <div class="main-panel">
        <div class="content">
          <div class="row">
            <div class="col-md-12">
              <div class="card grid">
                <Tabs>
                  <TabList>
                    <Tab onClick={refreshPage}>Inquiry From Home</Tab>
                    <Tab>Inquiry From ContactUs</Tab>
                  </TabList>
                  {/* tab-1 ---> Home */}
                  <TabPanel>
                    <div class="main-panel">
                      <div class="content">
                        <div className="page-inner">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="">
                                <div class="card-header">
                                  <div class="d-flex align-items-center">
                                    <h4 class="card-title">Inquiry </h4>
                                  </div>
                                </div>
                                <div class="card-body">
                                  <div class="table-responsive">
                                    <table
                                      className="display table table-striped table-hover"
                                      id="AssociateDisplay"
                                    >
                                      <thead>
                                        <tr>
                                          <th>Name</th>
                                          <th>Email</th>
                                          <th>Phone</th>
                                          <th>Company Name</th>
                                          <th>Project Requirement</th>
                                          <th>View</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {" "}
                                        {InquiryDetails != null &&
                                          InquiryDetails.length > 0 ? (
                                          InquiryDetails.map((v, i) => {
                                            return (
                                              <tr key={i}>
                                                <td>{v.name}</td>
                                                <td>{v.email}</td>
                                                <td>{v.phone}</td>
                                                <td>{v.companyName}</td>
                                                <td>
                                                  <div className="check">
                                                    {v.projectRequirement}
                                                  </div>
                                                </td>
                                                <td>
                                                  <div class="form-button-action tableoptions">
                                                    <button
                                                      type="button"
                                                      title=""
                                                      class="btn btn-link  btn-lg"
                                                      data-original-title="Edit"
                                                      data-toggle="modal"
                                                      data-target="#addRowModal"
                                                      onClick={
                                                        () =>
                                                          setModel2({ ...v })
                                                        // handleTabClick({0})
                                                        // GetEdit(
                                                        //   v.faqID,
                                                        //   v.question,
                                                        //   v.answer,
                                                        //   v.faqOrder
                                                        // )
                                                      }
                                                    >
                                                      <i className="fa fa-eye"></i>
                                                    </button>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })
                                        ) : (
                                          <label>No Data in Table</label>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div
                        class="modal fade"
                        id="addRowModal"
                        tabindex="-1"
                        role="dialog"
                        aria-hidden="true"
                        data-bs-backdrop="static"
                        data-backdrop="static"
                      >
                        <ViewInquiry Model={Model2} />
                      </div>
                    </div>
                  </TabPanel>
                  {/* tab-2 --> Contactus */}

                  <TabPanel>
                    <div class="main-panel">
                      <div class="content">
                        <div className="page-inner">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="card-header">
                                <div class="d-flex align-items-center">
                                  <h4 class="card-title">Inquiry </h4>
                                </div>
                                <div class="card-body">
                                  <div class="table-responsive">
                                    <table
                                      className="display table table-striped table-hover"
                                      id="AssociateDisplay"
                                    >
                                      <thead>
                                        <tr>
                                          <th>Name</th>
                                          <th>Email</th>
                                          <th>Phone</th>
                                          <th>Company Name</th>
                                          <th>Project Requirement</th>
                                          <th>View</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {" "}
                                        {ContactDetails != null &&
                                          ContactDetails.length > 0 ? (
                                          ContactDetails.map((v, i) => {
                                            return (
                                              <tr key={i}>
                                                <td>{v.name}</td>
                                                <td>{v.email}</td>
                                                <td>{v.phone}</td>
                                                <td>{v.companyName}</td>
                                                <td>
                                                  <div className="check">
                                                    {v.aboutProject}
                                                  </div>
                                                </td>
                                                <td>
                                                  <div class="form-button-action tableoptions">
                                                    <button
                                                      type="button"
                                                      title=""
                                                      class="btn btn-link  btn-lg"
                                                      data-original-title="Edit"
                                                      data-toggle="modal"
                                                      data-target="#addRowModal"
                                                      onClick={() =>
                                                        setModel({ ...v })
                                                      }
                                                    >
                                                      <i className="fa fa-eye"></i>
                                                    </button>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })
                                        ) : (
                                          <label>No Data in Table</label>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div
                        class="modal fade"
                        id="addRowModal"
                        tabindex="-1"
                        role="dialog"
                        aria-hidden="true"
                        data-bs-backdrop="static"
                        data-backdrop="static"
                      >
                        <ViewContact Model={Model} />
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="bg-dark text-light">
        {/* Start Footer Bottom */}
        <div className="footer-bottom">
          <div className="container">
            <div className="footer-bottom-box">
              <div className="row">
                <div className="col-lg-12">
                  <p>
                    © Copyright 2012 - {new Date().getFullYear()}. All Rights
                    Reserved by{" "}
                    <a href="/Dashboardview"> Vaanam Technologies Pvt Ltd.</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollToTop smooth color="#2b5690" />
        {/* End Footer Bottom */}
      </footer>
    </>
  ) : (
    <LoginView />
  );
}

export default ContactListTable;
