import React, { useState, useReducer,useEffect } from "react";
import "jquery";
import $ from "jquery";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";

function ViewRecuritment(props) {

  const [body, setBody] = useState("");

  useEffect(() => {
   setBody(props.Model.jobDescription);
  }, [props]);
  return (
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header no-bd">
          <h5 class="modal-title">
            <span class="fw-mediumbold">Job Details</span>
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
            data-dismiss="modal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div className="row careerdetails">
              <div className="col-md-8">
                <div className="form-group  ">
                  <label style={{ color: "blue" }}>Job Title :</label>{" "}
                  <div className="jobdetailstext" >{props.Model.jobTitle}</div>
                </div>
              </div>
              {/* <div className="col-md-12">
                <div className="form-group  ">
                  <label style={{ color: "blue" }}>Designation :</label>{" "}
                  <span>{props.Model.designation}</span>
                </div>
              </div> */}
              <div className="col-md-12">
                <div className="form-group  ">
                  <label style={{ color: "blue" }}>Experience :</label>{" "}
                  <div className="jobdetailstext">{props.Model.experience} Years</div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group  ">
                  <label style={{ color: "blue" }}>Job Type :</label>{" "}
                  <div className="jobdetailstext">{props.Model.jobType} </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group  ">
                  <label style={{ color: "blue" }}>Work Mode:</label>{" "}
                  <div className="jobdetailstext">{props.Model.workMode} </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group ">
                  <label style={{ color: "blue" }}>Location:</label>{" "}
                  <div className="jobdetailstext">{props.Model.location}</div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group clientcareerdescription">
                  <label style={{ color: "blue" }}>Description:</label>{" "}
                <div dangerouslySetInnerHTML={{ __html: body }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ViewRecuritment;
