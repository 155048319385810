import React from "react";
import AxiosServices from "./AxiosServices";

const Auth = new AxiosServices();

class FaqServices 
{
  Create = async (data) => {
    return Auth.post(`Faq/Create`, data);
  };
  GetFaqList = async () => {
    return Auth.get(`Faq/FaqList`);
  };
  Delete = async (id) => {
    return Auth.get(`Faq/DeleteFaq?id=${id}`);
  };
  Client_GetFaqList = async () => {
    return Auth.get(`Faq/Client_GetFaqList`);
  };
}

export default FaqServices;