import React, { useEffect } from "react";
import jQuery from "jquery";
import $ from "jquery";
import { Header } from "../../Home/Header";
import assessment from "../../../../Assets/img/banner/1.jpg";
import about from "../../../../Assets/img/about/1.jpg";
import { Footer } from "../../Home/Footer";
import "../../../../Assets/js/progress-bar.min.js";
import "../../../../Assets/js/count-to.js";
// import "../../../../Assets/js/bootsnav.js";
import "../../../../Assets/js/main.js";
import "../../../../Assets/css/bootstrap.min.css";
import "../../../../Assets/css/font-awesome.min.css";
import "../../../../Assets/css/themify-icons.css";
import "../../../../Assets/css/elegant-icons.css";
import "../../../../Assets/css/flaticon-set.css";
import "../../../../Assets/css/magnific-popup.css";
import "../../../../Assets/css/owl.carousel.min.css";
import "../../../../Assets/css/owl.theme.default.min.css";
import "../../../../Assets/css/animate.css";
// import "../../../../Assets/css/bootsnav.css";
import "../../../../Assets/css/style.css";
import "../../../../Assets/css/responsive.css";
import ScrollToTop from "react-scroll-to-top";
import BusinessAssessment from ".././../../../Assets/img/BusinessIntellegence/BIAssessment.png";
const BIAssessment = () => {
  useEffect(() => {
    setTimeout(() => {
      $("#preloader").hide();
    }, 1000);
  }, []);

  return (
    <>
      {" "}
      <Header />
      <div
        className="breadcrumb-area text-center shadow dark text-light bg-cover"
        style={{ backgroundImage: `url(${assessment})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>BI Assessment </h1>
              <ul className="breadcrumb">
                <li>
                  <a href="/">
                    <i className="fas fa-home" />
                    Home
                  </a>
                </li>
                <li className="active">BI Assessment </li>
              </ul>{" "}
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="services-details-area default-padding">
        <div className="container">
          <div className="services-details-items">
            <div className="row">
              <div className="col-lg-8 services-single-content">
                <h2>BI Assessment</h2>
                <p>
                  The overall success of your company's business intelligence
                  (BI) strategy can be challenged by poorly documented or
                  misunderstood data, along with varying requirement needs from
                  internal and external users. Vaanam BI Assessment Services
                  provide a low-cost examination of all key aspects of your
                  organization that could influence the overall success of your
                  BI strategy.
                </p>
                <p>
                  <strong>
                    Both long-term and short-term benefits are gained from
                    conducting a BI assessment by,
                  </strong>
                </p>
                <ul>
                  <li>
                    Obtaining a quick and low-cost validation that your
                    potential or existing BI solution is accurate for your
                    business and technical environment
                  </li>
                  <li>
                    Identifying weaknesses, roadblocks and skill-gaps to a
                    successful BI solution, and assisting in the development of
                    contingency plans
                  </li>
                  <li>
                    Improving the chances for long-term ROI by identifying
                    business opportunities and goals before the project is
                    launched
                  </li>
                  <li>
                    Increasing awareness and support for your BI initiative from
                    key stakeholders
                  </li>
                  <li>
                    Discovering the use and redundancies of your company's BI
                    information to pave the way for fundamental organization and
                    business process improvements
                  </li>
                </ul>
                that is accessible to the right people at the right time and
                through their delivery platform of choice.
                <p>
                  Vaanam uses a proven methodology to conduct BI Assessments to
                  ensure that we achieve a successful outcome.
                </p>
                <img src={BusinessAssessment} className="BiAssesmentimg"></img>
              </div>

              <div className="col-lg-4 services-sidebar">
                {/* Single Widget */}
                <div className="single-widget services-list">
                  <h4 className="widget-title">Solutions List</h4>
                  <div className="content">
                    <ul>
                      <li>
                        <a href="/Enterprise">Enterprise Application</a>
                      </li>
                      <li>
                        <a href="/MicrosoftServices">Microsoft Services</a>
                      </li>
                      <li>
                        <a href="/AIML" style={{ textTransform: "initial" }}>
                          AI and ML Services
                        </a>
                      </li>
                      <li>
                        <a href="/Mobility">Mobility Services</a>
                      </li>
                      <li>
                        <a href="/BusinessIntellegence">
                          Business Intelligence
                        </a>
                      </li>
                      <ul>
                        <li className="current-item">
                          <a href="/BIAssessment">BI Assessment</a>
                        </li>
                        <li>
                          <a href="/BIMigration">BI Migration</a>
                        </li>
                        <li>
                          <a href="/BIReports">BI Reports & Analysis</a>
                        </li>
                      </ul>
                      <li>
                        <a href="/Reality">Reality Technologies</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Widget */}
                <div
                  className="single-widget quick-contact text-light"
                  style={{ backgroundImage: `url(${about})` }}
                >
                  <div className="content">
                    <i className="fas fa-phone" />
                    <h4>Need any help?</h4>

                    <h2>+91 422 256 7626</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop smooth color="#2b5690" />
      <Footer />
    </>
  );
};
export default BIAssessment;
