import React from "react";
import AxiosServices from "./AxiosServices";

const Auth = new AxiosServices();

class ProjectServices {
  Create = async (data) => {
    return Auth.post(`Project/Create`, data);
  };
  UploadFiles = async (uploadfiles) => {
    return Auth.post(`Project/FilesUpload`, uploadfiles);
  };
  GetProjectList = async () => {
    return Auth.get(`Project/GetProjectList`);
  };
  Delete = async (id) => {
    return Auth.get(`Project/DeleteProject?id=${id}`);
  };
  Client_GetProjectList = async () => {
    return Auth.get(`Project/Client_GetProjectList`);
  };
  GetProjectClientImg = async (id) => {
    return Auth.get(`Project/GetProjectClintImg=${id}`);
  };

  DeleteClientLogo = async (id) => {
    return Auth.get(`Project/DeleteClientLogo?id=${id}`);
  };
}

export default ProjectServices;
