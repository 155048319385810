import React, { useEffect, useState, useCallback } from "react";
import jQuery from "jquery";
import $ from "jquery";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import EventServices from "../../../Services/EventServices";
import { useLocation } from "react-router-dom";
import { Header } from "./Header";
import ScrollToTop from "react-scroll-to-top";
import { Footer } from "./Footer";
import Banner from "../../../Assets/img/banner/1.jpg";
import "./EventImages.css"

var EventServicesObj = new EventServices();

export const EventImages = () => {
  const location = useLocation();

  const { id, date, title } = location.state;

  const [EventImages, setEventImages] = useState([]);

  useEffect(() => {
    EventServicesObj.Client_EventImages(id).then((res) => {
      if (res.data != null && res.data.length > 0) {
        setEventImages([...res.data]);
      }
      setTimeout(() => {
        $("#preloader").hide();
      }, 1000);
    });
  }, []);

  useEffect(() => {
    var getNav = $("nav.navbar.bootsnav"),
      getWindow = $(window).width(),
      getHeight = $(window).height(),
      getIn = getNav.find("ul.nav").data("in"),
      getOut = getNav.find("ul.nav").data("out");

    if (getWindow < 991) {
      // Height of scroll navigation sidebar
      $(".scroller").css("height", "auto");

      // Disable mouseenter event
      $("nav.navbar.bootsnav ul.nav").find("li.dropdown").off("mouseenter");
      $("nav.navbar.bootsnav ul.nav").find("li.dropdown").off("mouseleave");
      $("nav.navbar.bootsnav ul.nav").find(".title").off("mouseenter");
      $("nav.navbar.bootsnav ul.nav").off("mouseleave");

      // Enable click event
      $("nav.navbar.bootsnav ul.nav").each(function () {
        $(".dropdown-menu", this).removeClass(getOut);

        // Dropdown Fade Toggle
        $("a.dropdown-toggle", this).off("click");
        $("a.dropdown-toggle", this).on("click", function (e) {
          e.stopPropagation();
          $(this)
            .closest("li.dropdown")
            .find(".dropdown-menu")
            .first()
            .stop()
            .fadeToggle()
            .toggleClass(getIn);
          $(this).closest("li.dropdown").first().toggleClass("on");
          return false;
        });

        // Hidden dropdown action
        $("li.dropdown", this).each(function () {
          $(this).find(".dropdown-menu").stop().fadeOut();
          $(this).on("hidden.bs.dropdown", function () {
            $(this).find(".dropdown-menu").stop().fadeOut();
          });
          return false;
        });

        // Megamenu style
        $(".megamenu-fw", this).each(function () {
          $(".col-menu", this).each(function () {
            $(".content", this).stop().fadeOut();
            $(".title", this).off("click");
            $(".title", this).on("click", function () {
              $(this)
                .closest(".col-menu")
                .find(".content")
                .stop()
                .fadeToggle()
                .addClass(getIn);
              $(this).closest(".col-menu").toggleClass("on");
              return false;
            });

            $(".content", this).on("click", function (e) {
              e.stopPropagation();
            });
          });
        });
      });

      // Hidden dropdown
      var cleanOpen = function () {
        $("li.dropdown", this).removeClass("on");
        $(".dropdown-menu", this).stop().fadeOut();
        $(".dropdown-menu", this).removeClass(getIn);
        $(".col-menu", this).removeClass("on");
        $(".col-menu .content", this).stop().fadeOut();
        $(".col-menu .content", this).removeClass(getIn);
      };

      // Hidden om mouse leave
      $("nav.navbar.bootsnav").on("mouseleave", function () {
        cleanOpen();
      });

      // Enable click atribute navigation
      $("nav.navbar.bootsnav .attr-nav").each(function () {
        $("li.dropdown", this).off("mouseenter");
        $("li.dropdown", this).off("mouseleave");
        $("a.dropdown-toggle", this).off("click");
        $("a.dropdown-toggle", this).on("click", function (e) {
          e.stopPropagation();
          $(this)
            .closest("li.dropdown")
            .find(".dropdown-menu")
            .first()
            .stop()
            .fadeToggle();
          $(".navbar-toggle").each(function () {
            $(".fa", this).removeClass("fa-times");
            $(".fa", this).addClass("fa-bars");
            $(".navbar-collapse").removeClass("in");
            $(".navbar-collapse").removeClass("on");
          });
        });

        $(this).on("mouseleave", function () {
          $(".dropdown-menu", this).stop().fadeOut();
          $("li.dropdown", this).removeClass("on");
          return false;
        });
      });

      // Toggle Bars
      $(".navbar-toggle").each(function () {
        $(this).off("click");
        $(this).on("click", function () {
          $(".fa", this).toggleClass("fa-bars");
          $(".fa", this).toggleClass("fa-times");
          cleanOpen();
        });
      });
    } else if (getWindow > 991) {
      // Height of scroll navigation sidebar
      $(".scroller").css("height", getHeight + "px");

      // Navbar Sidebar
      if (getNav.hasClass("navbar-sidebar")) {
        // Hover effect Sidebar Menu
        $("nav.navbar.bootsnav ul.nav").each(function () {
          $("a.dropdown-toggle", this).off("click");
          $("a.dropdown-toggle", this).on("click", function (e) {
            e.stopPropagation();
          });

          $("li.dropdown", this).on("mouseenter", function () {
            $(".dropdown-menu", this).eq(0).removeClass(getOut);
            $(".dropdown-menu", this).eq(0).stop().fadeIn().addClass(getIn);
            $(this).addClass("on");
            return false;
          });

          $(".col-menu").each(function () {
            $(".title", this).on("mouseenter", function () {
              $(this)
                .closest(".col-menu")
                .find(".content")
                .stop()
                .fadeIn()
                .addClass(getIn);
              $(this).closest(".col-menu").addClass("on");
              return false;
            });
          });

          $(this).on("mouseleave", function () {
            $(".dropdown-menu", this).stop().removeClass(getIn);
            $(".dropdown-menu", this).stop().addClass(getOut).fadeOut();
            $(".col-menu", this)
              .find(".content")
              .stop()
              .fadeOut()
              .removeClass(getIn);
            $(".col-menu", this).removeClass("on");
            $("li.dropdown", this).removeClass("on");
            return false;
          });
        });
      } else {
        // Hover effect Default Menu
        $("nav.navbar.bootsnav ul.nav").each(function () {
          $("a.dropdown-toggle", this).off("click");
          $("a.dropdown-toggle", this).on("click", function (e) {
            e.stopPropagation();
          });

          $(".megamenu-fw", this).each(function () {
            $(".title", this).off("click");
            $("a.dropdown-toggle", this).off("click");
          });

          $("li.dropdown", this).on("mouseenter", function () {
            $(".dropdown-menu", this).eq(0).removeClass(getOut);
            $(".dropdown-menu", this).eq(0).stop().fadeIn().addClass(getIn);
            $(this).addClass("on");
            return false;
          });

          $("li.dropdown", this).on("mouseleave", function () {
            $(".dropdown-menu", this).eq(0).removeClass(getIn);
            $(".dropdown-menu", this).eq(0).stop().fadeOut().addClass(getOut);
            $(this).removeClass("on");
          });

          $(this).on("mouseleave", function () {
            $(".dropdown-menu", this).removeClass(getIn);
            $(".dropdown-menu", this).eq(0).stop().fadeOut().addClass(getOut);
            $("li.dropdown", this).removeClass("on");
            return false;
          });
        });
      }

      // ------------------------------------------------------------------------------ //
      // Hover effect Atribute Navigation
      // ------------------------------------------------------------------------------ //
      $("nav.navbar.bootsnav .attr-nav").each(function () {
        $("a.dropdown-toggle", this).off("click");
        $("a.dropdown-toggle", this).on("click", function (e) {
          e.stopPropagation();
        });

        $("li.dropdown", this).on("mouseenter", function () {
          $(".dropdown-menu", this).eq(0).removeClass(getOut);
          $(".dropdown-menu", this).eq(0).stop().fadeIn().addClass(getIn);
          $(this).addClass("on");
          return false;
        });

        $("li.dropdown", this).on("mouseleave", function () {
          $(".dropdown-menu", this).eq(0).removeClass(getIn);
          $(".dropdown-menu", this).eq(0).stop().fadeOut().addClass(getOut);
          $(this).removeClass("on");
        });

        $(this).on("mouseleave", function () {
          $(".dropdown-menu", this).removeClass(getIn);
          $(".dropdown-menu", this).eq(0).stop().fadeOut().addClass(getOut);
          $("li.dropdown", this).removeClass("on");
          return false;
        });
      });
    }

    // ------------------------------------------------------------------------------ //
    // Menu Fullscreen
    // ------------------------------------------------------------------------------ //
    if (getNav.hasClass("navbar-full")) {
      var windowHeight = $(window).height(),
        windowWidth = $(window).width();

      $(".nav-full").css("height", windowHeight + "px");
      $(".wrap-full-menu").css("height", windowHeight + "px");
      $(".wrap-full-menu").css("width", windowWidth + "px");

      $(".navbar-toggle").each(function () {
        var getId = $(this).data("target");
        $(this).off("click");
        $(this).on("click", function (e) {
          e.preventDefault();
          $(getId).removeClass(getOut);
          $(getId).addClass("in");
          $(getId).addClass(getIn);
          return false;
        });

        $("li.close-full-menu").on("click", function (e) {
          e.preventDefault();
          $(getId).addClass(getOut);
          setTimeout(function () {
            $(getId).removeClass("in");
            $(getId).removeClass(getIn);
          }, 500);
          return false;
        });
      });
    }
  }, []);

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const photos = [];
  EventImages.map(function (item, i) {
    var photo = {
      src: process.env.REACT_APP_API_IMAGEPATH + item.galleryImagePath,
      width: 4,
      height: 3,
    };
    photos.push(photo);
  });

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <>
      <Header />
      <div
        className="breadcrumb-area text-center shadow dark text-light bg-cover"
        style={{ backgroundImage: `url(${Banner})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>{title}</h1>
              <ul className="breadcrumb">
                <li>
                  <a href="/">
                    <i className="fas fa-home" />
                    Home{" "}
                  </a>
                </li>
                <li className="active">{date} </li>
              </ul>{" "}
            </div>
          </div>
        </div>
      </div>{" "}
      <div>
        <div className="container gallerycard">
          <div className="row">
            <div className="col-lg-12">
              <div className="site-heading text-center">
                <div className="container">
                  <div className="case-items-area">
                    <div className="masonary"></div>
                  </div>
                </div>
                <div className="container">
                  <div className="case-items-area">
                    <div className="masonary">
                      <Gallery photos={photos} onClick={openLightbox} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map((x) => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
      <ScrollToTop smooth color="#2b5690" />
      <Footer />
    </>
  );

};
