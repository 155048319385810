import React from "react";
import "jquery";
import $ from "jquery";
import ScrollToTop from "react-scroll-to-top";
import "../../../Assets/js/progress-bar.min.js";
import "../../../Assets/js/count-to.js";
import "../../../Assets/js/bootsnav.js";
import "../../../Assets/js/main.js";
import "../../../Assets/css/bootstrap.min.css";
import "../../../Assets/css/font-awesome.min.css";
import "../../../Assets/css/themify-icons.css";
import "../../../Assets/css/elegant-icons.css";
import "../../../Assets/css/flaticon-set.css";
import "../../../Assets/css/magnific-popup.css";
import "../../../Assets/css/owl.carousel.min.css";
import "../../../Assets/css/owl.theme.default.min.css";
import "../../../Assets/css/animate.css";
import "../../../Assets/css/bootsnav.css";
import "../../../Assets/css/style.css";
import "../../../Assets/css/responsive.css";

import { Header } from "./Header";
import { Banner } from "./Banner";
import { About } from "./About";
import { Services } from "./Services.js";
import { Whychooseuse } from "./Whychooseuse";
import { Partnerarea } from "./Partnerarea";
import { Testimonialarea } from "./Testimonialarea";
import { Projectsarea } from "./Projectsarea";
import { Blog } from "./Blog";
import Contactarea from "./Contactarea";
import { Footer } from "./Footer";
export const Home = () => (
  <>
    <div className="torch-red wrapper">
      <Header />
      <Banner />
      <About />
      <Services />
      <Whychooseuse />
      <Partnerarea />
      <Testimonialarea />
      <Projectsarea />
      <Blog />
      <Contactarea />
      <Footer />
      <ScrollToTop smooth color="#2b5690" />
    </div>
  </>
);
