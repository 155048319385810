import HeaderDashboard from "../DashBoard/HeaderDashboard";
import CareerServices from "../../../Services/CareerServices";
import Swal from "sweetalert2";
import React, { useState, useEffect, useReducer } from "react";
import { FaPencilAlt, FaTrashAlt, FaCloudDownloadAlt } from "react-icons/fa";
import AddOrEditCareer from "./AddOrEditCareer";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import RecruitmentServices from "../../../Services/RecruitmentServices";
import { saveAs } from "file-saver";
import ScrollToTop from "react-scroll-to-top";
import $ from "jquery";
import Preloader from "../../Preloader";
import Cookies from "universal-cookie";
import LoginView from "../Login/LoginView";

var CareerServicesObj = new CareerServices();
var RecruitmentServicesObj = new RecruitmentServices();
const CareerListTable = () => {
  // const[GridRender, SetGridRender] = useReducer(x => x+1, 0);
  const [Model, setModel] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    jobId: "",
    workId: "",
    location: "",
    designation: "",
    careerdetailId: "",
    workLocation: "",
    workLocationID: "",
    savedBy: "",
  });
  const [CareerDetails, setCareerDetail] = useState([]);
  const cookies = new Cookies();
  const [Auth, SetAuth] = useState();
  useEffect(() => {
    SetAuth(cookies.get("userID"));
    CareerServicesObj.GetCareerAdmin().then((res) => {
      if (res.data != null) {
        setCareerDetail([...res.data]);
      }
      setTimeout(() => {
        $("#preloader").hide();
      }, 1000);
    });
  }, []);

  function download(url, filename) {
    RecruitmentServicesObj.Download(url, filename).then((response, status, xhr) => {
      var ext = url.split('.');
      const contentType = response.headers["content-type"];
      if(response.status==200){
      saveAs(new Blob([response.data], { type: contentType }), filename + '.' + ext[1]);
      Toast.fire({
            icon: "success",
            title: "Download Successfully",
          });
        }else{
          Toast.fire({
                icon: "error",
                title: "Failed to download !",
              });

        }
    });
  }
  const [RecuritmentDetails, setRecuritmentDetail] = useState(null);
  useEffect(() => {
    SetAuth(cookies.get("userID"));
    RecruitmentServicesObj.GetRecuritmentAdmin()
      .then((res) => {
        if (res.data != null && res.data.length > 0) {
          setRecuritmentDetail([...res.data]);
        }
        setTimeout(() => {
          $("#preloader").hide();
        }, 1000);
      })
      .catch((err) => console.log(err));
  }, []);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  function DeleteCareer(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "Do You Want to Delete this Job?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        CareerServicesObj.Delete(id).then((res) => {
          if (res.data == 1) {
            Toast.fire({
              icon: "success",
              title: "Delete Success",
            });
            setTimeout(function () {
              window.location.reload();
            }, 750);
          } else {
            Toast.fire({
              icon: "error",
              title: "Delete Failed!",
            });
          }
        });
      }
    });
  }

  return Auth ? (
    <>
      <Preloader />
      <HeaderDashboard />
      <div class="main-panel">
        <div class="content">
          <div class="row">
            <div class="col-md-12">
              <div class="card grid">
                <Tabs>
                  <TabList>
                    <Tab>Jobs</Tab>
                    <Tab>Resumes</Tab>
                  </TabList>

                  {/* tab-1 ---> Jobs */}
                  <TabPanel>
                    <div className="page-inner">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="">
                            <div class="card-header">
                              <div class="d-flex align-items-center">
                                <h4 class="card-title">Jobs </h4>
                                <a
                                  href="#"
                                  className="btn btn-primary btn-round ml-auto"
                                  data-toggle="modal"
                                  data-target="#addRowModal"
                                >
                                  <i className="fa fa-plus"></i>
                                  Add Jobs
                                </a>
                              </div>
                            </div>
                            <div class="card-body">
                              <div class="table-responsive">
                                <table
                                  className="display table table-striped table-hover"
                                  id="AssociateDisplay"
                                >
                                  <thead>
                                    <tr>
                                      <th>Job Title</th>
                                      {/* <th>Job Description</th> */}

                                      <th>JobType</th>
                                      <th>Work Mode</th>
                                      <th>Experience</th>
                                      <th>Location</th>
                                      <th>Work Location</th>
                                      <th style={{ textAlign: "center" }}>
                                        Actions
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {" "}
                                    {CareerDetails != null &&
                                      CareerDetails.length > 0 ? (
                                      CareerDetails.map((v, i) => {
                                        return (
                                          <tr key={i}>
                                            <td>{v.jobTitle}</td>
                                            {/* <td>{v.jobDescription}</td> */}

                                            <td>{v.jobType}</td>
                                            <td>{v.workMode}</td>
                                            <td>{v.experience}</td>
                                            <td>{v.location}</td>
                                            <td>{v.workLocation}</td>
                                            <td>
                                              <div class="form-button-action tableoptions">
                                                <button
                                                  type="button"
                                                  title=""
                                                  class="btn btn-link  btn-lg"
                                                  data-original-title="Edit"
                                                  data-toggle="modal"
                                                  data-target="#addRowModal"
                                                  onClick={() =>
                                                    setModel({ ...v })
                                                  }
                                                >
                                                  <FaPencilAlt />
                                                </button>

                                                <button
                                                  type="button"
                                                  data-toggle="tooltip"
                                                  title=""
                                                  class="btn btn-link "
                                                  data-original-title="Remove"
                                                  onClick={() =>
                                                    DeleteCareer(
                                                      v.careerdetailId
                                                    )
                                                  }
                                                >
                                                  <FaTrashAlt />
                                                </button>
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <label>No Data in Table</label>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div
                        class="modal fade"
                        id="addRowModal"
                        tabindex="-1"
                        role="dialog"
                        aria-hidden="true"
                        data-bs-backdrop="static"
                        data-backdrop="static"
                      >
                        <AddOrEditCareer Model={Model} />
                      </div>
                    </div>
                  </TabPanel>

                  {/* tab-2 --> Resumes */}
                  <TabPanel>
                    <div className="page-inner">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="">
                            <div class="card-header">
                              <div class="d-flex align-items-center">
                                <h4 class="card-title">Resumes </h4>
                              </div>
                            </div>
                            <div class="card-body">
                              <div class="table-responsive">
                                <table
                                  className="display table table-striped table-hover"
                                  id="AssociateDisplay"
                                >
                                  <thead>
                                    <tr>
                                      <th>Job title</th>
                                      <th>Work Location</th>
                                      <th>Name</th>
                                      <th>Email</th>
                                      <th>Phone Number</th>
                                      <th>Experience</th>

                                      <th>Resume Download</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {" "}
                                    {RecuritmentDetails != null &&
                                      RecuritmentDetails.length > 0 ? (
                                      RecuritmentDetails.map((v, i) => {
                                        console.log(v);
                                        return (
                                          <tr key={i}>
                                            <td>{v.designation}</td>
                                            <td>{v.workLocation}</td>
                                            <td>{v.name}</td>
                                            <td>{v.email}</td>
                                            <td>  {v.phoneNumber}</td>
                                            <td>{v.experience}</td>


                                            <td>
                                              <div class="form-button-action tableoptions">
                                                <a
                                                  id="a1"
                                                  onClick={() =>
                                                    download(
                                                      v.resumePath,
                                                      v.name
                                                    )
                                                  }
                                                >
                                                  <FaCloudDownloadAlt />
                                                </a>
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <label>No Data in Table</label>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div
                        class="modal fade"
                        id="addRowModal"
                        tabindex="-1"
                        role="dialog"
                        aria-hidden="true"
                        data-bs-backdrop="static"
                        data-backdrop="static"
                      ></div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="bg-dark text-light">
        {/* Start Footer Bottom */}
        <div className="footer-bottom">
          <div className="container">
            <div className="footer-bottom-box">
              <div className="row">
                <div className="col-lg-12">
                  <p>
                    © Copyright 2012 - {new Date().getFullYear()}. All Rights
                    Reserved by{" "}
                    <a href="/Dashboardview"> Vaanam Technologies Pvt Ltd.</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollToTop smooth color="#2b5690" />
        {/* End Footer Bottom */}
      </footer>
    </>
  ) : (
    <LoginView />
  );
};

export default CareerListTable;
