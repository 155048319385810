import React, { useState, useEffect } from "react";
import EventServices from "../../../Services/EventServices";
import { Link } from "react-router-dom";
import "../../../Assets/css/responsive.css";
import $ from "jquery";

var EventServicesObj = new EventServices();

export const Blog = () => {
  const [EventDetails, setEventDetails] = useState([]); 
  useEffect(() => {
    EventServicesObj.Client_EventList().then((res) => {
      if (res.data != null && res.data.length > 0) {
        setEventDetails([...res.data]);
      }
    });
  }, []);

  const [isContentVisible, setIsContentVisible] = useState(false);

  function toggleContent(eventID) {
    setIsContentVisible(!isContentVisible);

    if (!isContentVisible) {
      var des = document.getElementById("Bdescription_" + eventID);
      var Btndes = document.getElementById("Btn_" + eventID);

      Btndes.textContent = "Show less";
      des.classList.remove("hideContent");
      des.classList.add("showContent");
    } else {
      var des = document.getElementById("Bdescription_" + eventID);
      var Btndes = document.getElementById("Btn_" + eventID);

      Btndes.textContent = "Show more";
      des.classList.remove("showContent");
      des.classList.add("hideContent");
    }
  }

  return (
    <>
      {/* Start Blog 
      ============================================= */}
      <div className="blog-area bg-gray default-padding bottom-less">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="site-heading text-center">
                <h4>From the blog</h4>
                <h2>Latest News &amp; Articles</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="blog-items">
            <div className="row">
              {EventDetails != null && EventDetails.length > 0 ? (
                EventDetails.map((v, i) => {
                  return (
                    <div
                      className="single-item col-lg-4 col-md-6 wow fadeInRight"
                      data-wow-delay="500ms"
                    >
                      <div className="item">
                        <div className="thumb">
                          <Link
                            to="/EventImages"
                            state={{
                              id: v.eventID,
                              date: v.eventDate,
                              title: v.eventTitle,
                            }}
                          >
                            <img
                              className="eventimage"
                              src={
                                process.env.REACT_APP_API_IMAGEPATH +
                                v.eventImgUrl
                              }
                              alt="Thumb"
                            />
                          </Link>
                          <div className="date">{v.eventDate}</div>
                        </div>
                        <div className="info">
                          {v.eventTitle.length < 30 ? (
                            <h5 style={{ marginTop: "5px" }}>
                              <Link
                                to="/EventImages"
                                state={{
                                  id: v.eventID,
                                  date: v.eventDate,
                                  title: v.eventTitle,
                                }}
                              >
                                {v.eventTitle}
                              </Link>
                            </h5>
                          ) : (
                            <h5>
                              <Link
                                to="/EventImages"
                                state={{
                                  id: v.eventID,
                                  date: v.eventDate,
                                  title: v.eventTitle,
                                }}
                              >
                                {v.eventTitle}
                              </Link>
                            </h5>
                          )}

                          {v.eventTitle.length < 30 ? (
                            <>
                              <br />
                            </>
                          ) : (
                            <></>
                          )}

                          <p
                            id={"Bdescription_" + v.eventID}
                            className="hideContent"
                          >
                            {v.description}
                          </p>
                          {v.description.length > 160 ? (
                            <a
                              id={"Btn_" + v.eventID}
                              onClick={() => toggleContent(v.eventID)}
                            >
                              Show more
                            </a>
                          ) : (
                            <a
                              style={{ visibility: "hidden" }}
                              id={"Btn_" + v.eventID}
                              onClick={() => toggleContent(v.eventID)}
                            >
                              Show more
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* End Blog Area */}
    </>
  );
};
