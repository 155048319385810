import React from "react";
import AxiosServices from "./AxiosServices";

const Auth = new AxiosServices();
class CareerServices {
    Create = async (data) => {
        return Auth.post('/Career/SaveCareerDetail', data);
    };
    Delete = async (id) => {
        return Auth.get(`/Career/DeleteCareer?id=${id}`);
      };
    GetDropdown = async () => {
        return Auth.get('/Career/GetDropDown');
      };
    GetCareerAdmin = async () => {
        return Auth.get('/Career/Career_GetCareerList');
      };
}

export default CareerServices;