import HeaderDashboard from "../DashBoard/HeaderDashboard";
import CareerServices from "../../../Services/CareerServices";

import Swal from "sweetalert2";
import React, { useState, useEffect, useReducer } from "react";
import { FaPencilAlt, FaTrashAlt, FaCloudDownloadAlt, FaEye, FaFilePdf } from "react-icons/fa";


import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import RecruitmentServices from "../../../Services/RecruitmentServices";
import { saveAs } from "file-saver";
import ScrollToTop from "react-scroll-to-top";
import $ from "jquery";
import Preloader from "../../Preloader";
import Cookies from "universal-cookie";
import LoginView from "../Login/LoginView";
import H1B_Services from "../../../Services/H1B_Serivices";
import Apply_H1B from "../../Client/Career/Apply_H1B";
import ApplyForm from "../../Client/Career/ApplyForm";


var CareerServicesObj = new CareerServices();
var RecruitmentServicesObj = new RecruitmentServices();
var H1B_ServicesObj = new H1B_Services();

const H1B_Appliers = () => {
  // const[GridRender, SetGridRender] = useReducer(x => x+1, 0);
  const [Model, setModel] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    jobId: "",
    workId: "",
    location: "",
    designation: "",
    careerdetailId: "",
    workLocation: "",
    workLocationID: "",
    savedBy: "",
  });
  const [CareerDetails, setCareerDetail] = useState([]);
  const [H1BAppliersLists, setH1BAppliersLists] = useState([]);
  const cookies = new Cookies();
  const [Auth, SetAuth] = useState();
  const [flag, setFlag] = useState(true)
  const [show, setShow] = useState(false)
  const [id, setId] = useState(false)
  const [firstName, setFirstName] = useState()
  useEffect(() => {
    SetAuth(cookies.get("userID"));
    CareerServicesObj.GetCareerAdmin().then((res) => {
      if (res.data != null) {
        setCareerDetail([...res.data]);
      }
      setTimeout(() => {
        $("#preloader").hide();
      }, 1000);
    });
  }, []);

  function download(url, filename) {

    RecruitmentServicesObj.Download(url, filename).then((response, status, xhr) => {
      var ext = url.split('.');
      const contentType = response.headers["content-type"];
      if (response.status == 200) {
        saveAs(new Blob([response.data], { type: contentType }), filename + '.' + ext[1]);
        Toast.fire({
          icon: "success",
          title: "Download Successfully",
        });
      } else {
        Toast.fire({
          icon: "error",
          title: "Failed to download !",
        });

      }
    });

  }
  const showPopup = (ApplierID, firstName) => {
    setId(ApplierID)
    setFirstName(firstName)
    setShow(true);
  }

  const [RecuritmentDetails, setRecuritmentDetail] = useState(null);
  useEffect(() => {
    SetAuth(cookies.get("userID"));

    H1B_ServicesObj.Get_H1B_Appliers_Lists()
      .then((res) => {
        if (res.data.length > 0) {
          
          setH1BAppliersLists(res.data)
        }

      })
      .catch((err) => console.log(err));
  }, []);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const exportPDF = () => {

    const pieChartElement = $("#Apply")[0]; 
    const content = pieChartElement.outerHTML;
    // pieChartElement.style.fontFamily = 'Calibri';
    pieChartElement.style.fontSize = '12px';
    kendo.drawing
      .drawDOM(pieChartElement, {
        paperSize: "A4",
        margin: { top: "1cm", left: "1cm", right: "1cm", bottom: "2cm" },
        scale: 0.8,
        pdf: {
          multiPage: true,
          fileName: `${firstName} H1B Deatils`,
          author: "Your Name",
          creator: "Your Application",
          subject: "PDF Subject",
          title: "PDF Title",
          keywords: "keyword1, keyword2",
        },
      })
      .then(function (group) {
        kendo.drawing.pdf.saveAs(group, `${firstName} H1B Deatils`);
      })
      .catch(function (error) {
        console.error("Error exporting PDF:", error);
      });

    $("#subpieBoxNoCount").css('margin-left', '');

    $(".subfileListNoCounts").hide();
    $(".main-loader").hide();
  };



  return Auth ? (
    <>
      <Preloader />
      <HeaderDashboard />
      <div class="main-panel">
        <div class="content">
          <div class="row">
            <div class="col-md-12">
              <div class="card grid">
                <Tabs>
                  <TabList>
                    <Tab style={{ width: '100%' }}>H1B Appliers List</Tab>

                  </TabList>
                  {/* tab-1 ---> Jobs */}
                  <TabPanel>
                    <div className="page-inner">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="">
                            <div class="card-header">
                              <div class="d-flex align-items-center">
                                <h4 class="card-title">H1B Lists </h4>

                              </div>
                            </div>
                            <div class="card-body">
                              <div class="table-responsive">
                                <table
                                  className="display table table-striped table-hover"
                                  id="AssociateDisplay"
                                >
                                  <thead>
                                    <tr>
                                      <th>H1B Code</th>
                                      <th>Name</th>
                                      {/* <th>Job Description</th> */}
                                      <th>Email</th>
                                      <th>MobileNumber</th>
                                      <th>Experience</th>
                                      <th>Passport Number</th>
                                      <th>AppliedDate</th>
                                      <th style={{ textAlign: "center" }}>
                                        Actions
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {" "}
                                    {H1BAppliersLists != null &&
                                      H1BAppliersLists.length > 0 ? (
                                      H1BAppliersLists.map((v, i) => {
                                        return (
                                          <tr key={i}>
                                            <td>{v.h1BCode}</td>
                                            <td>{v.firstName}</td>
                                            {/* <td>{v.jobDescription}</td> */}

                                            <td>{v.emailAddress}</td>
                                          
                                            <td>{v.contactNumber}</td>
                                            <td>{v.totalExprience}</td>
                                            <td>{v.passportnumber}</td>
                                            <td>{v.appliedDate}</td>

                                            <td>

                                              <div class="form-button-action tableoptions">
                                                <a
                                                  className="ms-2"
                                                  id="a1"
                                                  onClick={() =>
                                                    download(
                                                      v.passportCopy,
                                                      v.firstName + " PassportCopy"
                                                    )
                                                  }
                                                  title="Download Passport"
                                                >
                                                  <FaCloudDownloadAlt />
                                                </a>
                                                <a
                                                  className="ms-2"
                                                  id="a2"
                                                  onClick={() =>
                                                    download(
                                                      v.resumeCopy,
                                                      v.firstName + " ResumeCopy"
                                                    )
                                                  }
                                                  title="Download Resume"

                                                >
                                                  <FaCloudDownloadAlt />
                                                </a>
                                                <a
                                                  id="a3"
                                                  className="ms-2"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#addRowModal"
                                                  onClick={() => showPopup(v.applierId, v.firstName)}
                                                  // onClick={() =>
                                                  // showPopup(
                                                  //     v.applierId,
                                                  //     flag
                                                  //     )
                                                  //     }
                                                  title="View Details"
                                                >
                                                  <FaEye />
                                                </a>
                                              </div>

                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <label>No Data in Table</label>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {show && <div
                      class="modal fade show"
                      id="addRowModal"
                      tabindex="-1"
                      role="dialog"
                      aria-hidden="true"
                      data-bs-backdrop="static"
                      style={{ display: "block", overflowY: "scroll", background: 'rgba(0,0,0,.7)' }}
                    >
                      <div class="modal-dialog modal-xl" role="document">
                        <div class="modal-content">
                          <div class="modal-header no-bd">
                            <h5 class="modal-title">
                              <span class="fw-mediumbold">View Details</span>
                            </h5>
                           
                            <button
                            
                              type="button"
                              class="close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() => setShow(false)}

                            >

                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body" >
                            <div className="text-end mr-5">
                            <a
                          className="text-end"
                              id="a3"
                              onClick={() => exportPDF()}
                              title="View Details"
                              style={{
                                cursor: 'pointer'
                              }}
                            >
                              <FaFilePdf style={{ width: '24px', height: '24px' }} />
                            </a>
                            </div>
                          
                            <ApplyForm applierId={id} FirstName={firstName} flag={flag} />
                          </div>
                        </div>
                      </div>
                    </div>}

                  </TabPanel>


                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="bg-dark text-light">
        {/* Start Footer Bottom */}
        <div className="footer-bottom">
          <div className="container">
            <div className="footer-bottom-box">
              <div className="row">
                <div className="col-lg-12">
                  <p>
                    © Copyright 2012 - {new Date().getFullYear()}. All Rights
                    Reserved by{" "}
                    <a href="/Dashboardview"> Vaanam Technologies Pvt Ltd.</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollToTop smooth color="#2b5690" />
        {/* End Footer Bottom */}
      </footer>
    </>
  ) : (
    <LoginView />
  );
};

export default H1B_Appliers;
