import React from "react";
import AxiosServices from "./AxiosServices";

const Auth = new AxiosServices();

class ClientServices {
  Create = async (data) => {
    return Auth.post(`Project/SaveClient`, data);
  };
  UploadFiles = async (uploadfiles) => {
    return Auth.post(`Project/ClientFilesUpload`, uploadfiles);
  };
  GetClientList = async () => {
    return Auth.get(`Project/GetClientList`);
  };
  Delete = async (id) => {
    return Auth.get(`Project/DeleteClient?id=${id}`);
  };

}

export default ClientServices;
