import React from "react";
import AxiosServices from "./AxiosServices";

const Auth = new AxiosServices();

class TestimonialServices {
  Create = async (data) => {
    return Auth.post(`Testimonial/Create`, data);
  };
  UploadFiles = async (uploadfiles) => {
    return Auth.post(`Testimonial/FilesUpload`, uploadfiles);
  };
  GetTestimonialList = async () => {
    return Auth.get(`Testimonial/GetTestimonialList`);
  };
  Delete = async (id) => {
    return Auth.get(`Testimonial/DeleteTestimonial?id=${id}`);
  };
  Client_TestimonialList = async () => {
    return Auth.get(`Testimonial/Client_GetTestimonialList`);
  };
}

export default TestimonialServices;
