import { Header } from "../Home/Header";
import "jquery";
import $, { event } from "jquery";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import ServImg from "../../../Assets/img/banner/1.jpg";
import { useState, useEffect } from "react";
import ContactServices from "../../../Services/ContactServices";
import { Footer } from "../Home/Footer";

import React, { useRef } from "react";
import ScrollToTop from "react-scroll-to-top";

var ContactServicesObj = new ContactServices();

const Contact = () => {
  const [error, setError] = useState({
    name: "",
    email: "",
    phone: "",
    companyName: "",
    project: "",
  });

  useEffect(() => {
    setTimeout(() => {
      $("#preloader").hide();
    }, 1000);
  }, []);

  const cookies = new Cookies();
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 750,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const onKeyDown = (event) => {
    if (event.code === "Space") event.preventDefault();
  };
  function numberOnly(e) {
    var element = document.getElementById(e);
    element.value = element.value.replace(/[^0-9]/gi, "");
  }

  const name = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      myerror.name = "Name is Required";
    } else {
      myerror.name = "";
    }
    setError({ ...myerror });
  };

  const email = (e) => {
    var myerror = error;
    // else if(e.target.value == "r'^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$'")
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // (re.test(e.target.value) === false)
    if (e.target.value == null || e.target.value == "") {
      myerror.email = "Email is Required";
    } else {
      myerror.email = "";
    }
    setError({ ...myerror });
  };

  const phone = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      myerror.phone = "Phone is Required";
    } else {
      myerror.phone = "";
    }
    setError({ ...myerror });
    numberOnly("phone");
  };

  const companyName = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      myerror.companyName = "Company Name is Required";
    } else {
      myerror.companyName = "";
    }
    setError({ ...myerror });
  };

  const project = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      myerror.project = "Project Requirement is Required";
    } else {
      myerror.project = "";
    }
    setError({ ...myerror });
  };

  const captchaRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const onSubmit = (res) => {

    res.preventDefault();
    // const token = captchaRef.current.getValue();
    // captchaRef.current.reset();
    var myerror = error;

    var flag = true;
    var data = {
      ContactID: 0,
      Name: document.getElementById("name").value,
      Email: document.getElementById("email").value,
      Phone: document.getElementById("phone").value,
      CompanyName: document.getElementById("companyName").value,
      AboutProject: document.getElementById("project").value,
    };

    if (data.Name == null || data.Name == "") {
      myerror.name = "Name is Required";
      flag = false;
    } else {
      myerror.name = "";
    }

    if (data.Email == null || data.Email == "") {
      myerror.email = "Email is Required";
      flag = false;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.Email)) {
      console.log(data.Email);
      myerror.email = "Please Enter a Valid Email Address";
      flag = false;
    } else {
      myerror.email = " ";
    }

    if (data.Phone == null || data.Phone == "") {
      myerror.phone = "Phone is Required";
      flag = false;
    } else if (data.Phone.length <= 9) {
      console.log(data.Phone.length);
      myerror.phone = "Must Ten-Digit";
      flag = false;
    } else {
      myerror.phone = "";
    }

    if (data.CompanyName == null || data.CompanyName == "") {
      myerror.companyName = "Company Name is Required";
      flag = false;
    } else {
      myerror.companyName = "";
    }

    if (data.AboutProject == null || data.AboutProject == "") {
      myerror.project = "Project Requirement is Required";
      flag = false;
    } else {
      myerror.project = "";
    }
    setError({ ...myerror });

    if (flag) {
      // const formData = new FormData();
      console.log(data);
      ContactServicesObj.Create(data).then(async (res) => {
        if (res.data == 1) {
          $(".close").trigger("click");

          Toast.fire({
            icon: "success",
            title: "Submitted Successfully",
          });
          setTimeout(function () {
            window.location.reload();
          }, 750);
        } else {
          Toast.fire({
            icon: "error",
            title: "Failed to Send!",
          });
        }
      });
      setIsLoaded(true);
    }
  };

  return (
    <div>
      <Header />
      {/* Start Breadcrumb */}
      <div
        className="breadcrumb-area text-center shadow dark text-light bg-cover"
        style={{
          backgroundImage: `url(${ServImg})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>Contact Us</h1>
              <ul className="breadcrumb">
                <li>
                  <a href="/">
                    <i className="fas fa-home" /> Home
                  </a>
                </li>
                <li className="active">Contact Us</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumb */}
      {/* Start Contact Area 
    ============================================= */}
      <div className="contact-us-area default-padding">
        <div className="container">
          <div className="row align-center">
            <div className="col-lg-5 info">
              <div className="content">
                <h2>Let's connect?</h2>

                <ul>
                  <li>
                    <div className="icon">
                      <i className="fas fa-map-marker-alt" />
                    </div>
                    <div className="content">
                      <h5>Corporate Office</h5>
                      <p>
                        <span style={{ color: "#2b5690" }}>
                          Vaanam{" "}
                          <span style={{ color: "#2b5690" }}>
                            Technologies LLC,
                          </span>{" "}
                        </span>
                        <br />
                        1740 Grassland Parkway, Suit #404, Alpharetta, <br /> GA
                        - 30004, USA <br />
                        <a href="tel:+1 (201) 920-5046">+1 (201) 920-5046</a><br />
                        

                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="fas fa-map-marker-alt" />
                    </div>
                    <div className="content">
                      <h5>Offshore Development Office</h5>
                      <p>
                        <span style={{ color: "#2b5690" }}>Vaanam </span>{" "}
                        <span style={{ color: "#2b5690" }}>
                          Technologies Pvt Ltd,
                        </span>
                        <br /> Grand CAG Central, 787, Avinashi Road, Nava
                        India, Peelamedu,
                        <br />
                        Coimbatore, TN - 641 004, India.
                      </p>
                    </div>

                  </li>
                  <li>
                    <div className="icon">
                      <i className="fas fa-phone" />
                    </div>
                    <div className="content">
                      <h5>Reach Us</h5>
                      <p>
                         +91 422 256 7626
                        <br />
                        SALES:  +91 9043026014
                        <br />
                        HR:  +91 9043026025
                        <br />
                        <a href="mailto:info@vaanamtech.com">info@vaanamtech.com</a>
                      </p>
                    </div>

                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 offset-lg-1 contact-form-box">
              {/* <div className="col-lg-7 contact-form-box"> */}
              <div className="form-box">
                <form
                  onSubmit={onSubmit}
                  className="contact-form"
                  autoComplete="off"
                >
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          className="form-control"
                          id="name"
                          name="name"
                          placeholder="Name*"
                          type="text"
                          onChange={name}
                        />
                        <span className="text-danger"> {error.name}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder="Email*"
                          type="text"
                          onChange={email}
                        />
                        <span className="text-danger"> {error.email} </span>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          id="phone"
                          name="phone"
                          placeholder="Phone*"
                          type="text"
                          maxLength={10}
                          onKeyDown={(event) => onkeydown(event)}
                          onChange={phone}
                        // onChange={phone => numberOnly("phone")}
                        />
                        <span className="text-danger"> {error.phone} </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          className="form-control"
                          id="companyName"
                          name="companyName"
                          placeholder="Company Name*"
                          type="text"
                          onChange={companyName}
                        />
                        <span className="text-danger">
                          {" "}
                          {error.companyName}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group comments">
                        <textarea
                          className="form-control"
                          id="project"
                          name="project"
                          placeholder="Project Requirement*"
                          defaultValue={""}
                          onChange={project}
                        />
                        <span className="text-danger"> {error.project} </span>
                      </div>
                    </div>
                  </div>
                  {/* <input type="text" id="name" className="input"/>
                    <reCAPTCHA
                    sitekey={process.env.REACT_APP_SITE_KEY} 
                    ref={captchaRef}
                    /> */}
                  <div className="row">
                    <div className="col-lg-12">
                      {!isLoaded ? (
                        <button type="submit" name="submit" id="submit">
                          Send Message <i className="fa fa-paper-plane" />
                        </button>
                      ) : (
                        <button class="btn btn-primary" type="button" disabled>
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Loading...
                        </button>
                      )}
                    </div>
                  </div>
                  {/* Alert Message */}
                  <div className="col-lg-12 alert-notification">
                    <div id="message" className="alert-msg" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* End Contact Area */}
      {/* <div class="maps-area">
        <div class="google-maps">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.2808913615745!2d76.98900381389004!3d11.017539257711736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8583a7256f28b%3A0x9110bc8b8e1a5e33!2sVaanam%20Technologies%20Private%20Limited!5e0!3m2!1sen!2sin!4v1666013289806!5m2!1sen!2sin" />
        </div>
      </div> */}
      <ScrollToTop smooth color="#2b5690" />
      <Footer />
    </div>
  );
};
export default Contact;
