import React, { useState, useReducer } from "react";
import BannerServices from "../../../Services/BannerServices";
import "jquery";
import $ from "jquery";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { FaCloudDownloadAlt } from "react-icons/fa";
var BannerServicesObj = new BannerServices();
function AddOrEditBanner(props) {
  const [image, setImage] = useState();
  const [selectedimage, setselectedimage] = useState();

  const [error, setformError] = useState({
    slider_title: "",
    slider_description: "",
    slider_image: "",
    slider_url: "",
  }); 
  const cookies = new Cookies();
  const [isLoaded, setIsLoaded] = useState(false);
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 750,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const CloseModel = () => {
    document.getElementById("AddOrEditBanner").reset();
    props.Model.sliderID = "";
    props.Model.title = "";
    props.Model.description = "";
    props.Model.imageUrl = "";
    props.Model.sliderUrl = "";
    props.Model.sliderOrder = "";

    setformError({
      slider_title: "",
      slider_description: "",
      slider_image: "",
      slider_url: "",
    });
  };
  const onKeyDown = (event) => {
    if (event.code === "Space") event.preventDefault();
  };
  function numberOnly(e) {
    var element = document.getElementById(e);
    element.value = element.value.replace(/[^0-9]/gi, "");
  }
  const titlechange = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      myerror.slider_title = "Title is Required";
    } else {
      myerror.slider_title = "";
    }
    setformError({ ...myerror });
  };

  const descriptionchange = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      myerror.slider_description = "Description is Required";
    } else {
      myerror.slider_description = "";
    }
    setformError({ ...myerror });
  };
  const imageChange = (event) => {

    var myerror = error;
    const selectedFile = event.target.files[0];
    setselectedimage(selectedFile);
    if (selectedFile) {
      if (selectedFile.type != "image/jpg" && selectedFile.type != "image/jpeg") {
        myerror.slider_image = 'Please select a valid image file (Ex: .JPEG, .jpg)';
      } else {
        myerror.slider_image = "";
        setformError({ ...myerror });
      };

    } else {
      myerror.slider_image = "";
    }
  };

  const sliderurlchange = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      myerror.slider_url = "Banner Redirect Url is Required";
    } else {
      myerror.slider_url = "";
    }
    setformError({ ...myerror });
  };

  var uploadimage = { file: image };

  const onSubmit = async (res) => {
    debugger;
    res.preventDefault();

    var myerror = error;

    var flag = true;

    var data = {
      SavedBY: parseInt(cookies.get("userID")),

      SliderID: parseInt(props.Model.sliderID),
      Title: document.getElementById("title").value,
      Description: document.getElementById("description").value,
      ImageUrl: null,
      SliderUrl: document.getElementById("sliderurl").value,
      SliderOrder: parseInt(document.getElementById("orderby").value),
    };
    if (data.Title == null || data.Title == "") {
      myerror.slider_title = "Title is Required";

      flag = false;
    } else {
      myerror.slider_title = "";
    }

    if (data.Description == null || data.Description == "") {
      myerror.slider_description = "Description is Required";
      flag = false;
    } else {
      myerror.slider_description = "";
    }

    if (data.SliderUrl == null || data.SliderUrl == "") {
      myerror.slider_url = "Banner Redirect Url is Required";
      flag = false;
    } else {
      myerror.slider_url = "";
      flag = true;
    }
    if (props.Model.sliderID == null || props.Model.sliderID == "") {
      if (selectedimage == null) {
        myerror.slider_image = "Banner Image is Required";
        flag = false;
      } else if (selectedimage != null) {
        if (selectedimage != null) {
          const image = new Image();
          image.src = URL.createObjectURL(selectedimage);
          await new Promise((resolve) => {
            image.onload = function () {
              if (selectedimage.type != "image/jpg" && selectedimage.type != "image/jpeg") {
                myerror.slider_image = 'Please select a valid image file (Ex: .JPEG,.jpg)';
                flag = false;
              }
              resolve();
            };
          });
        }
      }
    }
    else {
      myerror.slider_image = "";
      flag = true;
    }


    if (data.SliderUrl == null || data.SliderUrl == "") {
      myerror.slider_url = "Banner Redirect Url is Required";
      flag = false;
    } else {
      myerror.slider_url = "";
    }
    setformError({ ...myerror });
    if (flag == true) {
      setIsLoaded(true);
      const formData = new FormData();

      $.each($("#image")[0].files, function (i, file) {
        formData.append("Image", file);
      });
      BannerServicesObj.UploadImage(formData).then((res) => {
        data.ImageUrl = res.data;

        BannerServicesObj.Create(data).then((res) => {
          if (res.data == 1) {
            $(".close").trigger("click");

            Toast.fire({
              icon: "success",
              title: "Save Success",
            });
            setTimeout(function () {
              window.location.reload();
            }, 750);
          } else {
            Toast.fire({
              icon: "error",
              title: "Failed to Save!",
            });
          }
        });
      });
    }
  };

  function extractImageName(url) {
    // Get the last part of the URL after the last "/"
    const imageNameWithGuid = url.substring(url.lastIndexOf('/') + 1);

    // Remove the GUID part by splitting at the underscore
    const imageNameParts = imageNameWithGuid.split('_');

    // Join the parts of the image name, excluding the GUID part
    const imageName = imageNameParts.slice(1).join('_');

    // Remove any query parameters or hashes from the image name
    const cleanImageName = imageName.split('?')[0].split('#')[0];

    return cleanImageName;
  }

  return (
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header no-bd">
          <h5 class="modal-title">
            <span class="fw-mediumbold">Add Banner</span>
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
            data-dismiss="modal"
            onClick={CloseModel}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <form onSubmit={onSubmit} id="AddOrEditBanner" autocomplete="off">
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-group ">
                    <label>
                      Title <span className="text-danger">*</span>
                    </label>

                    <input
                      type="text"
                      id="title"
                      className="form-control"
                      maxLength={200}
                      defaultValue={props.Model.title}
                      placeholder="Banner Title"
                      name="title"
                      onChange={titlechange}
                    />

                    <span className="text-danger">{error.slider_title}</span>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group  ">
                    <label>
                      Description<span className="text-danger">*</span>
                    </label>

                    <textarea
                      id="description"
                      className="form-control"
                      maxLength={2000}
                      defaultValue={props.Model.description}
                      placeholder="Banner Description"
                      name="description"
                      onChange={descriptionchange}
                    />

                    <span className="text-danger">
                      {error.slider_description}
                    </span>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group ">

                    <label>
                      Banner Image
                      {props.Model.sliderID == null ||
                        props.Model.sliderID == "" ? (<>
                          <span className="text-danger">*</span>&nbsp;
                          <span class="badge badge-danger">Max Img Size(1920x960)px, .JPEG, .jpg</span>
                        </>
                      ) : (
                        <></>
                      )}
                    </label>
                    <input
                      id="image"
                      type="file"
                      className="form-control"
                      defaultValue={props.Model.imageUrl}
                      name="image"
                      onChange={imageChange}
                      accept=".png, .jpg, .jpeg"
                    />
                    {props.Model.imageUrl ? <><span>{extractImageName(props.Model.imageUrl)}</span>&nbsp;
                      <a href={process.env.REACT_APP_API_IMAGEPATH + props.Model.imageUrl} target="_blank">
                        <FaCloudDownloadAlt />
                      </a></> : <></>}<br />
                    <span className="text-danger">{error.slider_image}</span>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group ">
                    <label>
                      Banner Redirect Url<span className="text-danger">*</span>
                    </label>

                    <input
                      type="text"
                      id="sliderurl"
                      className="form-control"
                      defaultValue={props.Model.sliderUrl}
                      placeholder="Banner Redirect Url"
                      name="sliderurl"
                      onChange={sliderurlchange}
                      onKeyDown={(event) => onKeyDown(event)}
                    />

                    <span className="text-danger">{error.slider_url}</span>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group ">
                    <label>Banner Order</label>

                    <input
                      type="text"
                      id="orderby"
                      className="form-control"
                      maxLength={3}
                      defaultValue={props.Model.sliderOrder}
                      placeholder="Banner Order"
                      name="orderby"
                      onKeyDown={(event) => onKeyDown(event)}
                      onChange={(e) => numberOnly("orderby")}
                    />
                  </div>
                </div>
              </div>

              <div className="modal-footer no-bd">
                {!isLoaded ? (
                  <button
                    type="submit"
                    id="addRowButton"
                    className="btn btn-primary"
                  >
                    Add
                  </button>
                ) : (
                  <button className="btn btn-primary" type="button" disabled>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading...
                  </button>
                )}

                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  onClick={CloseModel}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddOrEditBanner;
