import { Header } from "../Home/Header";
import ServImg from "../../../Assets/img/banner/1.jpg";
import bannerImg from "../../../Assets/img/shape/12.png";
import { Footer } from "../Home/Footer";
import about from "../../../Assets/img/about/1.jpg";
import ScrollToTop from "react-scroll-to-top";
import React, { useEffect } from "react";
import $ from "jquery";

const Mobility = () => {
  useEffect(() => {
    setTimeout(() => {
      $("#preloader").hide();
    }, 1000);
  }, []);

  return (
    <div>
      <Header />
      <div
        className="breadcrumb-area text-center shadow dark text-light bg-cover"
        style={{
          backgroundImage: `url(${ServImg})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>Mobility Services</h1>
              <ul className="breadcrumb">
                <li>
                  <a href="/">
                    <i className="fas fa-home" /> Home
                  </a>
                </li>
                <li className="active">Mobility Services </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumb */}
      {/* Star Services Details Area
    ============================================= */}
      <div className="services-details-area default-padding">
        <div className="container">
          <div className="services-details-items">
            <div className="row">
              <div className="col-lg-8 services-single-content">
                <h2>Mobility Service</h2>
                <h4>Mobile Technologies</h4>
                <p>
                  The way of managing the applications in any enterprise is
                  rapidly changing. They need to respond quickly in order to
                  remain competitive. Trend in enterprise applications is the
                  move to cloud computing, where the enterprise moves some or
                  its entire infrastructure to the cloud. Some enterprises
                  choose a hybrid solution where cloud applications are
                  integrated with on-premise systems.
                </p>
                <p>
                  Employees and Executives today are expected to be available
                  almost 24*7 for any urgencies and take any immediate action if
                  required on any business decision. Such changes in ecosystem
                  of businesses has given rise to the requirement of enterprise
                  mobility and planning the solutions on mobile devices in
                  parallel to legacy devices like desktop over internet.
                </p>
                <p>
                  Vaanam has helped many companies in the
                  past by creating mobility solutions as part of their offerings
                  to the clients. Such solutions are tailored for specific
                  business requirement and planned to support required
                  platforms, screen sizes and devices as per the policy and
                  needs of client.
                </p>

                <h2>Offerings</h2>
                <p>
                  Over the past years, Vaanam has been able to successfully
                  deliver and deploy many enterprise and consumer mobile apps
                  for its clients. The applications have been spread across
                  various usability features like,
                </p>
                <ul>
                  <li>Location / GPS Based Apps</li>
                  <li>Simple Games </li>
                  <li>Data Manipulation Apps</li>
                  <li>Consolidation</li>
                  <li>HTML 5 Apps</li>
                  <li>Mobility Workforce Apps</li>
                  <li>eVoting & eSurvey Apps</li>
                  <li>Mobile Porting Apps</li>
                </ul>
                <p>
                  The applications developed by Vaanam under mobility solutions
                  have been spread across various platforms and development
                  initiatives.
                </p>
              </div>
              <div className="col-lg-4 services-sidebar">
                {/* Single Widget */}
                <div className="single-widget services-list">
                  <h4 className="widget-title">Solutions List</h4>
                  <div className="content">
                    <ul>
                      <li>
                        <a href="/Enterprise">Enterprise Application</a>
                      </li>
                      <li>
                        <a href="/MicrosoftServices">Microsoft Services</a>
                      </li>
                      <li>
                        <a href="/AIML" style={{ textTransform: "initial" }}>
                          AI and ML Services
                        </a>
                      </li>
                      <li className="current-item">
                        <a href="/Mobility">Mobility Services</a>
                      </li>
                      <li>
                        <a href="/BusinessIntellegence">
                          Business Intelligence
                        </a>
                      </li>
                      <li>
                        <a href="/Reality">Reality Technologies</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Widget */}
                <div
                  className="single-widget quick-contact text-light"
                  style={{ backgroundImage: `url(${about})` }}
                >
                  <div className="content">
                    <i className="fas fa-phone" />
                    <h4>Need any help?</h4>

                    <h2>+91 422 256 7626</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Services Details Area */}
      {/* Star Services Area
    ============================================= */}
      <div className="services-area bg-gray default-padding bottom-less">
        {/* Fixed Shape */}
        <div
          className="fixed-shape"
          style={{
            backgroundImage: `url(${bannerImg})`,
          }}
        />
        {/* Fixed Shape */}
        <div className="container">
          <div className="services-heading">
            <div className="row">
              <div className="col-lg-7 left-info">
                <h3>Mobile Development</h3>
              </div>
            </div>
          </div>
          {/* Start Services Items */}
          <div className="services-content text-center">
            <div className="row">
              {/* Single Item */}
              <div className="single-item col-lg-3 col-md-6">
                <div className="item" style={{ height: "52rem" }}>
                  <img src={require("../../../Assets/img/Solutions/m1.png")} />
                  <h4>
                    <a className="chgcolor">iOS Mobile Development</a>
                  </h4>
                  <p>
                    Vaanam offers its development of
                    mobility solutions for iOS that form part of mostly used
                    apple devices. We have been involved in supporting devices
                    of all form factors including Phones, Tablets, and Wearable
                    Technology.
                  </p>
                  <br />
                  <p>
                    Many organizations focus on the commercial launch of an
                    iPhone application, often missing significant aspects of the
                    development process. This diminishes the potential of the
                    application to be prominent in the customer's mind against
                    other applications within the market.
                  </p>
                </div>
              </div>
              {/* End Single Item */}
              {/* Single Item */}
              <div className="single-item col-lg-3 col-md-6">
                <div className="item" style={{ height: "52rem" }}>
                  <img src={require("../../../Assets/img/Solutions/m2.png")} />
                  <h4>
                    <a className="chgcolor">Android Mobile Development</a>
                  </h4>
                  <p>
                    Android is an open source and most spread platform adopted
                    by most of leading mobile companies. Vaanam offers mobility
                    solutions on Android platform tailored as per needs of the
                    client across all devices including Phones, Tablets and
                    Wearable Technology.
                  </p>
                  <br />
                  <p>
                    Our passionate team of Android app designers and Android app
                    developers leverage latest Android technologies to develop
                    cutting-edge Android app solutions that offer stunning
                    designs and impeccable UX to Android end-users.
                  </p>
                </div>
              </div>
              {/* End Single Item */}
              {/* Single Item */}
              <div className="single-item col-lg-3 col-md-6">
                <div className="item" style={{ height: "52rem" }}>
                  <img src={require("../../../Assets/img/Solutions/m3.png")} />
                  <h4>
                    <a className="chgcolor">Windows Mobile Development</a>
                  </h4>
                  <p>
                    Microsoft has been slow in capturing the market but pushing
                    its windows platform very aggressively now on smart and
                    mobile devices. Vaanam has helped many companies by offering
                    its development solutions for windows mobile and tablet OS
                    using its expertise.
                  </p>
                  <br />
                  <p>
                    Organizations can take advantage of the user-friendly nature
                    of Windows Phone to design and deploy mobile applications
                    targeted at their customers. Another key advantage of
                    developing mobile applications on the Windows Phone platform
                    is the ability to provide offline and online functionality
                    tailored to meet client requests.
                  </p>
                </div>
              </div>
              {/* End Single Item */}
              {/* Single Item */}
              <div className="single-item col-lg-3 col-md-6">
                <div className="item" style={{ height: "52rem" }}>
                  <img src={require("../../../Assets/img/Solutions/m4.png")} />
                  <h4>
                    <a className="chgcolor">Hybrid Mobile Development</a>
                  </h4>
                  <p>
                    Mobile apps at times can take significant investment
                    especially with multiple platforms being promoted as
                    proprietary through various different companies and devices.
                    Vaanam has been an early mover to offer cross platform
                    programming and development solutions using Xamarin and
                    Flutter to be able to support and lower down the
                    costs significantly for its clients.
                  </p>
                </div>
              </div>
              {/* End Single Item */}
            </div>
          </div>
          {/* End Services Items */}
        </div>
      </div>
      <ScrollToTop smooth color="#2b5690" />
      {/* End Services Area */}
      {/* Start Footer 
    ============================================= */}
      <Footer />
      {/* End Footer */}
      {/* jQuery Frameworks
    ============================================= */}
    </div>
  );
};
export default Mobility;
