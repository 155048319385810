import React, { useEffect, useState } from "react";
import jQuery from "jquery";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import "../../../Assets/css/bootstrap.min.css";
import "../../../Assets/css/style.css";
// import "../../../node_modules/bootstrap/dist/css/bootstrap.css";
import "../../../Assets/css/elegant-icons.css";
import "../../../Assets/css/flaticon-set.css";
import "../../../Assets/css/magnific-popup.css";
import "../../../Assets/css/owl.carousel.min.css";
import HeaderDashboard from "./HeaderDashboard";

import ScrollToTop from "react-scroll-to-top";
import Preloader from "../../Preloader";
import LoginView from "../Login/LoginView";
{
  /* Start Preloader 
    ============================================= */
}
//  <div id="preloader">
//     <div id="vaanam-preloader" className="vaanam-preloader">
//       <div className="animation-preloader">
//         <div className="spinner" />
//         <div className="txt-loading">
//           <span data-text-preloader="V" className="letters-loading">
//             V
//           </span>
//           <span data-text-preloader="A" className="letters-loading">
//             A
//           </span>
//           <span data-text-preloader="A" className="letters-loading">
//             A
//           </span>
//           <span data-text-preloader="N" className="letters-loading">
//             N
//           </span>
//           <span data-text-preloader="A" className="letters-loading">
//             A
//           </span>
//           <span data-text-preloader="M" className="letters-loading">
//             M
//           </span>
//         </div>
//       </div>
//       <div className="loader">
//         <div className="row">
//           <div className="col-3 loader-section section-left">
//             <div className="bg" />
//           </div>
//           <div className="col-3 loader-section section-left">
//             <div className="bg" />
//           </div>
//           <div className="col-3 loader-section section-right">
//             <div className="bg" />
//           </div>
//           <div className="col-3 loader-section section-right">
//             <div className="bg" />
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
{
  /* End Preloader */
}
{
  /* Header 
    ============================================= */
}

export default function Dashboard() {
  const navigate = new useNavigate();
  const cookies = new Cookies();
  const [Auth, SetAuth] = useState();

  useEffect(() => {
    SetAuth(cookies.get("token"));
    setTimeout(() => {
      $("#preloader").hide();
    }, 1000);
  });

  return Auth ? (
    <>
      <Preloader />

      <HeaderDashboard />
      {/* Star Services Area
    ============================================= */}
      <div className="torch-red services-area default-padding bottom-less">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="site-heading text-center">
                <h4>Vaanam Technologies</h4>
                <h2>Dashboard</h2>
                <div className="devider" />
              </div>
            </div>
          </div>
        </div>
        <div className="container-full">
          <div className="services-items">
            <div className="row">
              {/* Single Item */}
              <div className="col-lg-3 col-md-6 single-item">
                <a href="/HomeBanner">
                  <div className="item">
                    <div className="info">
                      <i className="flaticon-creativity" />
                      <h4>Home Banners</h4>
                      <div className="bottom">
                        <span>01</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              {/* End Single Item */}
              {/* Single Item */}
              <div className="col-lg-3 col-md-6 single-item">
                <a href="/Projects">
                  <div className="item">
                    <div className="info">
                      <i className="flaticon-target" />
                      <h4>Products</h4>
                      <div className="bottom">
                        <span>02</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              {/* End Single Item */}
              {/* Single Item */}
              <div className="col-lg-3 col-md-6 single-item">
                <a href="/Testimonial">
                  <div className="item">
                    <div className="info">
                      <i className="flaticon-trophy" />
                      <h4>Testimonials</h4>
                      <div className="bottom">
                        <span>03</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              {/* End Single Item */}

              {/* Single Item */}
              <div className="col-lg-3 col-md-6 single-item">
                <a href="/Events">
                  <div className="item">
                    <div className="info">
                      <i className="flaticon-market-research" />
                      <h4>Latest Events</h4>
                      <div className="bottom">
                        <span>04</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              {/* End Single Item */}

              {/* Single Item */}
              <div className="col-lg-3 col-md-6 single-item">
                <a href="/AdminCareer">
                  <div className="item">
                    <div className="info">
                      <i className="flaticon-target-1" />
                      <h4>Careers</h4>
                      <div className="bottom">
                        <span>05</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              {/* End Single Item */}
              {/* Single Item */}
              <div className="col-lg-3 col-md-6 single-item">
                <a href="/AdminTerms">
                  <div className="item">
                    <div className="info">
                      <i className="flaticon-analytic" />
                      <h4>Terms</h4>
                      <div className="bottom">
                        <span>06</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              {/* End Single Item */}
              {/* Single Item */}
              <div className="col-lg-3 col-md-6 single-item">
                <a href="/AdminPrivacy">
                  <div className="item">
                    <div className="info">
                      <i className="flaticon-award-symbol" />
                      <h4>Privacy & Policy</h4>
                      <div className="bottom">
                        <span>07</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              {/* End Single Item */}
              {/* Single Item */}
              <div className="col-lg-3 col-md-6 single-item">
                <a href="/AdminFaq">
                  <div className="item">
                    <div className="info">
                      <i className="flaticon-question" />
                      <h4>FAQ</h4>
                      <div className="bottom">
                        <span>08</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              {/* End Single Item */}
              {/* Single Item */}
              <div className="col-lg-3 col-md-6 single-item">
                <a href="/ContactListTable">
                  <div className="item">
                    <div className="info">
                      <i className="flaticon-conversation" />
                      <h4>Inquiries</h4>
                      <div className="bottom">
                        <span>09</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-3 col-md-6 single-item">
                <a href="/clients">
                  <div className="item">
                    <div className="info">
                      <i className="flaticon-trophy" />
                      <h4>Clients</h4>
                      <div className="bottom">
                        <span>10</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-3 col-md-6 single-item">
                <a href="/H1B_Appliers">
                  <div className="item">
                    <div className="info">
                      <i className="flaticon-target-1" />
                      <h4>H1B Appliers</h4>
                      <div className="bottom">
                        <span>11</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              {/* End Single Item */}
            </div>
          </div>
        </div>
      </div>
      {/* End Services Area */}
      {/* Start Footer 
    ============================================= */}
      <footer className="bg-dark text-light">
        {/* Start Footer Bottom */}
        <div className="footer-bottom">
          <div className="container">
            <div className="footer-bottom-box">
              <div className="row">
                <div className="col-lg-12">
                  <p>
                    © Copyright 2012 - {new Date().getFullYear()}. All Rights
                    Reserved by{" "}
                    <a href="/Dashboardview"> Vaanam Technologies Pvt Ltd.</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollToTop smooth color="#2b5690" />
        {/* End Footer Bottom */}
      </footer>
      {/* End Footer */}
      {/* jQuery Frameworks
    ============================================= */}
    </>
  ) : (
    <LoginView />
  );
}
