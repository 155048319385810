import React, { useState, useReducer } from "react";
import { Header } from "../../Client/Home/Header";
import FaqServices from "../../../Services/FaqServices";
import "jquery";
import $ from "jquery";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
var FaqServicesObj = new FaqServices();
function AddOrEditFaq(props) {


    const { Model } = props;
    console.log(Model);


    const [error, setformError] = useState({
        faq_question: "",
        faq_answer: "",
    });
    const cookies = new Cookies();
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 750,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });

    const CloseModel = () => {
        document.getElementById("AddOrEditFaq").reset();
        props.Model.faqID = "";
        props.Model.question = "";
        props.Model.answer = "";
        props.Model.faqOrder = "";
        props.Model.savedBY = "";

        setformError({
            faq_question: "",
            faq_answer: "",
        });
    };
    const onKeyDown = (event) => {
        if (event.code === "Space") event.preventDefault();
    };
    function numberOnly(e) {
        var element = document.getElementById(e);
        element.value = element.value.replace(/[^0-9]/gi, "");
    }
    const questionchange = (e) => {
        var myerror = error;
        if (e.target.value == null || e.target.value == "") {
            myerror.faq_question = "Question is Required";
        } else {
            myerror.faq_question = "";
        }
        setformError({ ...myerror });
    };

    const answerchange = (e) => {
        var myerror = error;
        if (e.target.value == null || e.target.value == "") {
            myerror.faq_answer = "Answer is Required";
        } else {
            myerror.faq_answer = "";
        }
        setformError({ ...myerror });
    };

    const onSubmit = (res) => {
        res.preventDefault();

        var myerror = error;

        var flag = true;



        var data = {
            SavedBY: parseInt(cookies.get("userID")),
            FaqID: parseInt(props.Model.faqID),
            Question: document.getElementById("question").value,
            Answer: document.getElementById("answer").value,
            FaqOrder: parseInt(document.getElementById("orderby").value),
        };

        if (data.Question !== null && data.Question !== "") {
            myerror.faq_question = "";
        } else {
            myerror.faq_question = "Question is Required";
            flag = false;
        }

        if (data.Answer !== null && data.Answer !== "") {
            myerror.faq_answer = "";
        } else {
            myerror.faq_answer = "Answer is Required";
            flag = false;
        }
        setformError({ ...myerror });

        if (flag == true) {
            const formData = new FormData();

            FaqServicesObj.Create(data).then((res) => {
                if (res.data == 1) {
                    $(".close").trigger("click");

                    Toast.fire({
                        icon: "success",
                        title: "Save Success",
                    });
                    setTimeout(function () {
                        window.location.reload();
                    }, 750);
                } else {
                    Toast.fire({
                        icon: "error",
                        title: "Failed to Save!",
                    });
                }
            });
        }
    };

    return (
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header no-bd">
                    <h5 class="modal-title">
                        <span class="fw-mediumbold">Add FAQ</span>
                    </h5>
                    <button
                        type="button"
                        class="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        data-dismiss="modal"
                        onClick={CloseModel}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        <form onSubmit={onSubmit} id="AddOrEditFaq">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group  ">
                                        <label>
                                            Question<span className="text-danger">*</span>
                                        </label>

                                        <textarea
                                            id="question"
                                            className="form-control"
                                            maxLength={2000}
                                            defaultValue={props.Model.question}
                                            placeholder="FAQ Question"
                                            name="question"
                                            onChange={questionchange}
                                        />

                                        <span className="text-danger">
                                            {error.faq_question}
                                        </span>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group  ">
                                        <label>
                                            Answer<span className="text-danger">*</span>
                                        </label>

                                        <textarea
                                            id="answer"
                                            className="form-control"
                                            defaultValue={props.Model.answer}
                                            placeholder="FAQ Answer"
                                            name="answer"
                                            onChange={answerchange}
                                        />

                                        <span className="text-danger">
                                            {error.faq_answer}
                                        </span>
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="form-group ">
                                        <label>Faq Order</label>

                                        <input
                                            type="text"
                                            id="orderby"
                                            className="form-control"
                                            maxLength={3}
                                            defaultValue={props.Model.faqOrder}
                                            placeholder="Faq Order"
                                            name="orderby"
                                            onKeyDown={(event) => onKeyDown(event)}
                                            onChange={(e) => numberOnly("orderby")}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer no-bd">
                                <button
                                    type="submit"
                                    id="addRowButton"
                                    className="btn btn-primary"
                                >
                                    Add
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    data-dismiss="modal"
                                    onClick={CloseModel}
                                >
                                    Close
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AddOrEditFaq;
