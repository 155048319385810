import React, { useState, useEffect } from "react";
import { Header } from "../../Client/Home/Header";
import banner1 from "../../../Assets/img/banner/1.jpg";
import privacyServices from "../../../Services/PrivacyServices";
import { Footer } from "../Home/Footer";
import ScrollToTop from "react-scroll-to-top";
import $ from "jquery";

var PrivacyServicesObj = new privacyServices();

export const Privacy = () => {
  const [body, setBody] = useState("");

  useEffect(() => {
    PrivacyServicesObj.PrivacyList().then((res) => {
      if (res.data != null) {
        setBody(res.data.content);
      }
      setTimeout(() => {
        $("#preloader").hide();
      }, 1000);
    });
  }, []);

  return (
    <div>
      {/* Start Breadcrumb
============================================= */}

      <Header />
      <div
        className="breadcrumb-area text-center shadow dark text-light bg-cover"
        style={{
          backgroundImage: `url(${banner1})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>Privacy &amp; Policy</h1>
              <ul className="breadcrumb">
                <li>
                  <a href="/">
                    <i className="fas fa-home" /> Home
                  </a>
                </li>
                <li className="active">Privacy &amp; Policy</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumb */}
      {/* Start Privacy Policy */}
      <div className="terms-policy-area default-padding">
        <i className="" />
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div dangerouslySetInnerHTML={{ __html: body }}></div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop smooth color="#2b5690" />
      {/* End Privacy Policy */}
      <Footer />
    </div>
  );
};
