import React, { useState } from "react";
import TestimonialServices from "../../../Services/TestimonialServices";
import "jquery";
import $ from "jquery";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { FaCloudDownloadAlt } from "react-icons/fa";


var TestimonialServicesObj = new TestimonialServices();
function AddOrEditTestimonials(props) {
  const [logo, setlogo] = useState();
  const [error, setformError] = useState({
    client_name: "",
    client_designation: "",
    client_logo: "",
    description: "",
  });
  const cookies = new Cookies();
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 750,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const CloseModel = () => {
    document.getElementById("AddOrEditTestimonials").reset();
    props.Model.testimonialID = "";
    props.Model.clientName = "";
    props.Model.clientDesignation = "";
    props.Model.clientLogoUrl = "";
    props.Model.description = "";
    props.Model.testimonialOrder = "";

    setformError({
      client_name: "",
      client_designation: "",
      client_logo: "",
      description: "",
    });
  };
  const onKeyDown = (event) => {
    if (event.code === "Space") event.preventDefault();
  };
  function numberOnly(e) {
    var element = document.getElementById(e);
    element.value = element.value.replace(/[^0-9]/gi, "");
  }
  const clientnamechange = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      myerror.client_name = "Client Name is Required";
    } else {
      myerror.client_name = "";
    }
    setformError({ ...myerror });
  };

  const clientdesignationchange = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      myerror.client_designation = "Client Designation is Required";
    } else {
      myerror.client_designation = "";
    }
    setformError({ ...myerror });
  };
  const clientlogochange = (e) => {
    if (props.Model.testimonialID == null || props.Model.testimonialID == "") {
      var myerror = error;
      if (e.target.value == null || e.target.value == "") {
        myerror.client_logo = "Client Logo is Required";
        setlogo("");
      } else {
        setlogo(e.target.files[0]);
        myerror.client_logo = "";
      }
      setformError({ ...myerror });
    }
  };
  const descriptionchange = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      myerror.description = "Description is Required";
    } else {
      myerror.description = "";
    }
    setformError({ ...myerror });
  };

  var uploadfiles = { logo: logo };

  const onSubmit = (res) => {
    res.preventDefault();

    var myerror = error;

    var flag = true;

    var data = {
      SavedBY: parseInt(cookies.get("userID")),
      TestimonialID: parseInt(props.Model.testimonialID),
      ClientName: document.getElementById("clientname").value,
      ClientDesignation: document.getElementById("clientdesignation").value,
      ClientLogoUrl: null,
      Description: document.getElementById("description").value,

      TestimonialOrder: parseInt(document.getElementById("orderby").value),
    };
    if (data.ClientName == null || data.ClientName == "") {
      myerror.client_name = "Client Name is Required";

      flag = false;
    } else {
      myerror.client_name = "";
    }

    if (data.ClientDesignation == null || data.ClientDesignation == "") {
      myerror.client_designation = "Client Designation is Required";
      flag = false;
    } else {
      myerror.client_designation = "";
    }
    if (props.Model.testimonialID == null || props.Model.testimonialID == "") {
      if (uploadfiles.logo == null || uploadfiles.logo == "") {
        myerror.client_logo = "Client Logo is Required";
        flag = false;
      } else {
        myerror.client_logo = "";
      }
    }
    if (data.Description == null || data.Description == "") {
      myerror.description = "Description is Required";
      flag = false;
    } else {
      myerror.description = "";
    }
    setformError({ ...myerror });
    if (flag == true) {
      const formData = new FormData();

      $.each($("#clientlogo")[0].files, function (i, file) {
        formData.append("Logo", file);
      });

      TestimonialServicesObj.UploadFiles(formData).then((res) => {
        data.ClientLogoUrl = res.data;

        TestimonialServicesObj.Create(data).then((res) => {
          if (res.data == 1) {
            $(".close").trigger("click");
            Toast.fire({
              icon: "success",
              title: "Save Success",
            });
            setTimeout(function () {
              window.location.reload();
            }, 750);
          } else {
            Toast.fire({
              icon: "error",
              title: "Failed to Save!",
            });
          }
        });
      });
    }
  };

  function extractImageName(url) {
    // Get the last part of the URL after the last "/"
    const imageNameWithGuid = url.substring(url.lastIndexOf('/') + 1);

    // Remove the GUID part by splitting at the underscore
    const imageNameParts = imageNameWithGuid.split('_');

    // Join the parts of the image name, excluding the GUID part
    const imageName = imageNameParts.slice(1).join('_');

    // Remove any query parameters or hashes from the image name
    const cleanImageName = imageName.split('?')[0].split('#')[0];

    return cleanImageName;
  }
  return (
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header no-bd">
          <h5 class="modal-title">
            <span class="fw-mediumbold">Add Testimonial</span>
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
            data-dismiss="modal"
            onClick={CloseModel}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <form
              onSubmit={onSubmit}
              id="AddOrEditTestimonials"
              autocomplete="off"
            >
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-group ">
                    <label>
                      Client Name<span className="text-danger">*</span>
                    </label>

                    <input
                      type="text"
                      id="clientname"
                      className="form-control"
                      maxLength={200}
                      defaultValue={props.Model.clientName}
                      placeholder="Client Name"
                      name="clientname"
                      onChange={clientnamechange}
                    />

                    <span className="text-danger">{error.client_name}</span>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group  ">
                    <label>
                      Client Designation<span className="text-danger">*</span>
                    </label>

                    <input
                      type="text"
                      id="clientdesignation"
                      className="form-control"
                      maxLength={200}
                      defaultValue={props.Model.clientDesignation}
                      placeholder="Client Designation"
                      name="clientdesignation"
                      onChange={clientdesignationchange}
                    />

                    <span className="text-danger">
                      {error.client_designation}
                    </span>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group ">
                    <label>
                      Client Logo
                      {props.Model.testimonialID == null ||
                        props.Model.testimonialID == "" ? (
                        <span className="text-danger">*</span>
                      ) : (
                        <></>
                      )}
                    </label>
                    <input
                      id="clientlogo"
                      type="file"
                      className="form-control"
                      defaultValue={props.Model.clientLogoUrl}
                      name="clientlogo"
                      onChange={clientlogochange}
                      accept=".png, .jpg, .jpeg"
                    />
                    {props.Model.clientLogoUrl ? <><span>{extractImageName(props.Model.clientLogoUrl)}</span>&nbsp;
                      <a href={process.env.REACT_APP_API_IMAGEPATH + props.Model.clientLogoUrl} target="_blank">
                        <FaCloudDownloadAlt />
                      </a></> : <></>}<br />

                    <span className="text-danger">{error.client_logo}</span>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group ">
                    <label>
                      Description<span className="text-danger">*</span>
                    </label>

                    <textarea
                      id="description"
                      maxLength={2000}
                      className="form-control"
                      placeholder="Description"
                      defaultValue={props.Model.description}
                      name="description"
                      onChange={descriptionchange}
                    />

                    <span className="text-danger">{error.description}</span>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group ">
                    <label>Testimonial Order</label>

                    <input
                      type="text"
                      id="orderby"
                      className="form-control"
                      maxLength={3}
                      defaultValue={props.Model.testimonialOrder}
                      placeholder="Testimonial Order"
                      name="orderby"
                      onKeyDown={(event) => onKeyDown(event)}
                      onChange={(e) => numberOnly("orderby")}
                    />
                  </div>
                </div>
              </div>

              <div className="modal-footer no-bd">
                <button
                  type="submit"
                  id="addRowButton"
                  className="btn btn-primary"
                >
                  Add
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  onClick={CloseModel}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddOrEditTestimonials;
