import React from "react";
import AxiosServices from "./AxiosServices";

const Auth = new AxiosServices();

class BannerServices 
{
  Create = async (data) => {
    return Auth.post(`Banner/Create`, data);
  };
  UploadImage = async (uploadimage) => {
    return Auth.post(`Banner/ImageUpload`, uploadimage);
  };
  GetSliderList = async () => {
    return Auth.get(`Banner/SliderList`);
  };
  Delete = async (id) => {
    return Auth.get(`Banner/DeleteSlider?id=${id}`);
  };
  Client_GetSliderList = async () => {
    return Auth.get(`Banner/Client_GetSliderList`);
  };
}

export default BannerServices;
