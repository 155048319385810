import React, { useEffect, useState } from "react";
import jQuery from "jquery";
import $ from "jquery";
import { Header } from "./Header";
import { Footer } from "./Footer";
import EventServices from "../../../Services/EventServices";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import Banner from "../../../Assets/img/banner/1.jpg";

import ScrollToTop from "react-scroll-to-top";
import { Link } from "react-router-dom";
var EventServicesObj = new EventServices();

export const NewsandEvents = () => {
  const [EventDetails, setEventDetails] = useState([]);
  useEffect(() => {
    EventServicesObj.Client_AllEventList().then((res) => {
      if (res.data != null && res.data.length > 0) {
        setEventDetails([...res.data]);
      }
      setTimeout(() => {
        $("#preloader").hide();
      }, 1000);
    });
  }, []);
  return (
    <>
      <Header />
      <div
        className="breadcrumb-area text-center shadow dark text-light bg-cover"
        style={{ backgroundImage: `url(${Banner})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
            <h1>News &amp; Events</h1>
              <ul className="breadcrumb">
                <li>
                  <a href="/">
                    <i className="fas fa-home" />
                    Home
                  </a>
                </li>
                <li className="active"> News &amp; Events </li>
              </ul>{" "}
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="blog-area bg-gray default-padding bottom-less">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2"></div>
          </div>
        </div>

        <div className="container">
          <div className="blog-items">
            <div className="row">
              {EventDetails != null && EventDetails.length > 0 ? (
                EventDetails.map((v, i) => {
                  return (
                    <div
                      className="single-item col-lg-4 col-md-6 wow fadeInRight"
                      data-wow-delay="500ms"
                    >
                      <div className="item">
                        <div className="thumb">
                          <Link
                            to="/EventImages"
                            state={{
                              id: v.eventID,
                              date: v.eventDate,
                              title: v.eventTitle,
                            }}
                          >
                            <img
                              className="eventimage"
                              src={
                                process.env.REACT_APP_API_IMAGEPATH +
                                v.eventImgUrl
                              }
                              alt="Thumb"
                            />
                          </Link>
                          <div className="date">{v.eventDate}</div>
                        </div>
                        <div className="info">
                          <h4>
                            <Link
                              to="/EventImages"
                              state={{
                                id: v.eventID,
                                date: v.eventDate,
                                title: v.eventTitle,
                              }}
                            >
                              {v.eventTitle}
                            </Link>
                          </h4>
                          <p>{v.description}</p>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop smooth color="#2b5690" />
      <Footer />
    </>
  );
};
