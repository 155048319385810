import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import TestimonialServices from "../../../Services/TestimonialServices";

var TestimonialServicesObj = new TestimonialServices();
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
export const Testimonialarea = () => {
  const [TestimonialDetails, setTestimonialDetails] = useState([]);
  useEffect(() => {
    TestimonialServicesObj.Client_TestimonialList().then((res) => {
      if (res.data != null && res.data.length > 0) {
        setTestimonialDetails([...res.data]);
      }
    });
  }, []);
  return (
    <>
      {/* Star testimonials Area
    ============================================= */}
      <div className="testimonials-area bg-gray default-padding-bottom">
        <div className="container">
          <div className="testimonial-items">
            <div className="row align-center">
              <div className="col-lg-7 testimonials-content">
                <div className="testimonials-carousel owl-carousel owl-theme">
                  {/* Single Item */}

                  <Carousel
                  autoPlay={true}
          infinite={true}
          
                    autoPlaySpeed={2500}
                    responsive={responsive}
                    showDots={true}
                    removeArrowOnDeviceType={[
                      "tablet",
                      "mobile",
                      "superLargeDesktop",
                      "desktop",
                    ]}
                  >
                    {TestimonialDetails != null &&
                    TestimonialDetails.length > 0 ? (
                      TestimonialDetails.map((v, i) => {
                        return (
                          <div className="item">
                            <div className="info">
                            <img
                                    src={
                                      process.env.REACT_APP_API_IMAGEPATH +
                                      v.clientLogoUrl
                                    }
                                    alt="Author"
                                  />
                              <p>{v.description}</p>
                              <div className="provider">
                                <div className="thumb">
                             
                                </div>
                                <div className="content">
                                  <h4>{v.clientName}</h4>
                                  <span> {v.clientDesignation}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                    {/* End Single Item */}
                  </Carousel>
                </div>
              </div>
              <div className="col-lg-5 info">
                <h4>Testimonials</h4>
                <h2>Check what our satisfied clients said</h2>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End testimonials Area */}
      {/* End Overflow Hidden Box */}
    </>
  );
};
