import { Header } from "../Home/Header";
import ServImg from "../../../Assets/img/banner/1.jpg";
import EnterpriceImage from "../../../Assets/img/Solutions/Enterprise.png";
import { Footer } from "../Home/Footer";
import about from "../../../Assets/img/about/1.jpg";
import ScrollToTop from "react-scroll-to-top";
import React, { useEffect } from "react";
import $ from "jquery";
const Enterprise = () => {
  useEffect(() => {
    setTimeout(() => {
      $("#preloader").hide();
    }, 1000);
  }, []);

  return (
    <div>
      <Header />
      <div
        className="breadcrumb-area text-center shadow dark text-light bg-cover"
        style={{
          backgroundImage: `url(${ServImg})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>Enterprise Application</h1>
              <ul className="breadcrumb">
                <li>
                  <a href="/">
                    <i className="fas fa-home" /> Home
                  </a>
                </li>
                <li className="active">Enterprise Application</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumb */}
      {/* Star Services Details Area
    ============================================= */}
      <div className="services-details-area default-padding">
        <div className="container">
          <div className="services-details-items">
            <div className="row">
              <div className="col-lg-8 services-single-content">
                <h2>Enterprise Application Service</h2>
                <h4>Evolution in ERP</h4>
                <p>
                  The way of managing the applications in any enterprise is
                  rapidly changing. They need to respond quickly in order to
                  remain competitive. Trend in enterprise applications is the
                  move to cloud computing, where the enterprise moves some or
                  its entire infrastructure to the cloud. Some enterprises
                  choose a hybrid solution where cloud applications are
                  integrated with on-premise systems.
                </p>

                <img src={EnterpriceImage} alt="Services" />
                <h2>Services expertise under one roof</h2>
                <p>
                  Vaanam brings IT and engineering services
                  expertise under one roof to solve complex business problems
                  for its clients.
                </p>
                <ul>
                  <li>Automated Invoicing System</li>
                  <li>Payment Processing </li>
                  <li>Customer Relationship Management (CRM)</li>
                  <li>Enterprise Resource Planning (ERP)</li>
                  <li>Business Intelligence</li>
                  <li>HR Management</li>
                  <li>Business Continuity Planning</li>
                </ul>
              </div>
              <div className="col-lg-4 services-sidebar">
                {/* Single Widget */}
                <div className="single-widget services-list">
                  <h4 className="widget-title">Solutions List</h4>
                  <div className="content">
                    <ul>
                      <li className="current-item">
                        <a href="/Enterprise">Enterprise Application</a>
                      </li>
                      <li>
                        <a href="/MicrosoftServices">Microsoft Services</a>
                      </li>
                      <li>
                        <a href="/AIML" style={{ textTransform: "initial" }}>
                          AI and ML Services
                        </a>
                      </li>
                      <li>
                        <a href="/Mobility">Mobility Services</a>
                      </li>
                      <li>
                        <a href="/BusinessIntellegence">
                          Business Intelligence
                        </a>
                      </li>
                      <li>
                        <a href="/Reality">Reality Technologies</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Widget */}
                <div
                  className="single-widget quick-contact text-light"
                  style={{ backgroundImage: `url(${about})` }}
                >
                  <div className="content">
                    <i className="fas fa-phone" />
                    <h4>Need any help?</h4>

                    <h2>+91 422 256 7626</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop smooth color="#2b5690" />
      {/* End Services Details Area */}
      {/* Start Footer 
    ============================================= */}
      <Footer />
      {/* End Footer */}
      {/* jQuery Frameworks
    ============================================= */}
    </div>
  );
};
export default Enterprise;
