import React, { useState, useEffect } from "react";
import { Header } from "../../Client/Home/Header";
import bannerrightshape from "../../../Assets/img/banner/1.jpg";
import FaqServices from "../../../Services/FaqServices";
import GirlFaq from "../../../Assets/img/GirlFaq.png";
import { Footer } from "./Footer";
import ScrollToTop from "react-scroll-to-top";
import $ from "jquery";

var FaqServicesObj = new FaqServices();
function Faq() {
  const [FaqDetails, setFaqDetails] = useState([]);
  useEffect(() => {
    FaqServicesObj.Client_GetFaqList().then((res) => {
      if (res.data != null && res.data.length > 0) {
        setFaqDetails([...res.data]);
      }
    });
    setTimeout(() => {
      $("#preloader").hide();
    }, 1000);
  }, []);

  return (
    <div>
      {/* Start Breadcrumb 
      ============================================= */}
      <Header />
      <div
        className="breadcrumb-area text-center shadow dark text-light bg-cover"
        style={{
          backgroundImage: `url(${bannerrightshape})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>Faq</h1>
              <ul className="breadcrumb">
                <li>
                  <a href="/">
                    <i className="fas fa-home" /> Home
                  </a>
                </li>
                <li className="active">Faq</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumb */}
      {/* Star Faq
      ============================================= */}

      <div class="faq-area text-light overflow-hidden">
        <div class="container">
          <div class="faq-items">
            <div class="icon">
              <i class="flaticon-conversation"></i>
            </div>
            {/* <!-- Fixed Thumb --> */}

            {/* <!-- End Fixed Thumb --> */}
            <div class="row align-center">
              <div class="fixed-thumb">
                <img src={GirlFaq} alt="FaqImg"></img>
              </div>

              <div class="col-lg-8 offset-lg-2">
                <div class="faq-content textdark">
                  <h2 class="area-title">Questions &amp; Answers</h2>
                  <div className="accordion" id="accordionExample">
                    {FaqDetails != null &&
                      FaqDetails.length > 0 &&
                      FaqDetails.map((v, i) => {
                        return (
                          <div className="card">
                            <div className="card-header" id={`headingOne${i}`}>
                              <h4
                                className="mb-0 collapsed"
                                data-toggle="collapse"
                                data-target={`#collapseOne${i}`}
                                aria-expanded="false"
                                aria-controls="collapseOne"
                              >
                                {v.question}
                              </h4>
                            </div>

                            <div
                              id={`collapseOne${i}`}
                              className="collapse "
                              aria-labelledby="headingOne"
                              data-parent="#accordionExample"
                            >
                              <div className="card-body">
                                <p>{v.answer}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Faq */}
      <Footer />
      <ScrollToTop smooth color="#2b5690" />
    </div>
  );
}
export default Faq;
