import React, { useEffect } from "react";
import { Header } from "../../Home/Header";
import jQuery from "jquery";
import $ from "jquery";
import ServImg from "../../../../Assets/img/banner/1.jpg";
import about from "../../../../Assets/img/about/1.jpg";
import { Footer } from "../../Home/Footer";
import "../../../../Assets/js/progress-bar.min.js";
import "../../../../Assets/js/count-to.js";
// import "../../../../Assets/js/bootsnav.js";
import "../../../../Assets/js/main.js";
import "../../../../Assets/css/bootstrap.min.css";
import "../../../../Assets/css/font-awesome.min.css";
import "../../../../Assets/css/themify-icons.css";
import "../../../../Assets/css/elegant-icons.css";
import "../../../../Assets/css/flaticon-set.css";
import "../../../../Assets/css/magnific-popup.css";
import "../../../../Assets/css/owl.carousel.min.css";
import "../../../../Assets/css/owl.theme.default.min.css";
import "../../../../Assets/css/animate.css";
// import "../../../../Assets/css/bootsnav.css";
import "../../../../Assets/css/style.css";
import "../../../../Assets/css/responsive.css";
import ScrollToTop from "react-scroll-to-top";

import DataManagement from ".././../../../Assets/img/BusinessIntellegence/DataManagement.jpg";
const BusinessIntellegence = () => {
  useEffect(() => {
    setTimeout(() => {
      $("#preloader").hide();
    }, 1000);
  }, []);
  return (
    <>
      {" "}
      <Header />
      <div
        className="breadcrumb-area text-center shadow dark text-light bg-cover"
        style={{ backgroundImage: `url(${ServImg})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>Business Intelligence</h1>
              <ul className="breadcrumb">
                <li>
                  <a href="/">
                    <i className="fas fa-home" />
                    Home
                  </a>
                </li>
                <li className="active">Business Intelligence </li>
              </ul>{" "}
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="services-details-area default-padding">
        <div className="container">
          <div className="services-details-items">
            <div className="row">
              <div className="col-lg-8 services-single-content">
                <h2>Business Intelligence</h2>
                <p>
                  In today's global environment, managing and effectively using
                  your company's data is imperative in order to stay competitive
                  and stay in business.These services focus on capturing,
                  standardizing and utilizing your important company data that
                  is imperative for everyday decisions along with managing
                  crucial strategic goals.
                </p>
                <p>
                  Whether your company wants to capture important business
                  intelligence but never has, or tried a data integration
                  solution but is not satisfied, Vaanam Business Intelligence
                  Services is the answer. A study by IBM revealed that only 2%
                  to 4% of data stored in OLTP, ERP, CRM and various other
                  systems are utilized by a typical company.
                </p>
                <p>
                  {" "}
                  The best-of-breed technology from leading technology vendors
                  with our proven methodologies to provide a unique solution
                  that fits your company's business and technical requirements.
                </p>
                <p>
                  {" "}
                  Your enterprise data and its analyses can be crucial
                  components in your strategy to derive revenue growth, reduce
                  costs, identify new business opportunities or respond to
                  competitive threats.
                </p>
                <h2>Vaanam</h2>
                Enables your company to transform this valuable data into
                <ul>
                  <p></p>
                  <li>Accurate</li>
                  <li>Consistent</li>
                  <li>Actionable information </li>
                  <p></p>
                </ul>
                that is accessible to the right people at the right time and
                through their delivery platform of choice.
                <img src={DataManagement} className="BIImg"></img>
              </div>

              <div className="col-lg-4 services-sidebar">
                {/* Single Widget */}
                <div className="single-widget services-list">
                  <h4 className="widget-title">Solutions List</h4>
                  <div className="content">
                    <ul>
                      <li>
                        <a href="/Enterprise">Enterprise Application</a>
                      </li>
                      <li>
                        <a href="/MicrosoftServices">Microsoft Services</a>
                      </li>
                      <li>
                        <a href="/AIML" style={{ textTransform: "initial" }}>
                          AI and ML Services
                        </a>
                      </li>
                      <li>
                        <a href="/Mobility">Mobility Services</a>
                      </li>

                      <li className="current-item">
                        <a href="/BusinessIntellegence">
                          Business Intelligence
                        </a>
                      </li>
                      <ul>
                        <li>
                          <a href="/BIAssessment">BI Assessment</a>
                        </li>
                        <li>
                          <a href="/BIMigration">BI Migration</a>
                        </li>
                        <li>
                          <a href="/BIReports">BI Reports & Analysis</a>
                        </li>
                      </ul>
                      <li>
                        <a href="/Reality">Reality Technologies</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Widget */}
                <div
                  className="single-widget quick-contact text-light"
                  style={{ backgroundImage: `url(${about})` }}
                >
                  <div className="content">
                    <i className="fas fa-phone" />
                    <h4>Need any help?</h4>

                    <h2>+91 422 256 7626</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop smooth color="#2b5690" />
      <Footer />
    </>
  );
};
export default BusinessIntellegence;
