import { Header } from "../Home/Header";
import ServImg from "../../../Assets/img/banner/1.jpg";
import { Footer } from "../Home/Footer";
import about from "../../../Assets/img/about/1.jpg";
import ScrollToTop from "react-scroll-to-top";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import Enterprise from "../../../Assets/img/Services/Enterprise.png";
import Business from "../../../Assets/img/Services/Business.jpeg";
import Microsoft from "../../../Assets/img/Services/Microsoft.png";
import Mobility from "../../../Assets/img/Services/Mobility.jpeg";
import Reality from "../../../Assets/img/Services/Reality.jpeg";
import AIML from "../../../Assets/img/Services/AIMLHome.png";

const ServicesHome = () => {
  useEffect(() => {
    $("#preloader").hide();
  }, []);
  const [isContentVisible, setIsContentVisible] = useState(false);

  const toggleContent = () => {
    setIsContentVisible(!isContentVisible);
  };

  return (
    <>
      <Header />
      <div
        className="breadcrumb-area text-center shadow dark text-light bg-cover"
        style={{
          backgroundImage: `url(${ServImg})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>Our Services</h1>
              <ul className="breadcrumb">
                <li>
                  <a href="/">
                    <i className="fas fa-home" /> Home
                  </a>
                </li>
                <li className="active">Our Services</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumb */}

      <div>
        {" "}
        <div className="team-single-area default-padding">
          <div className="container">
            <div className="team-content-top">
              <div className="row">
                <div className="col-lg-5 left-info">
                  <div className="thumb">
                    <img src={Enterprise} alt="Thumb" />
                  </div>
                </div>
                <div className="col-lg-7 right-info">
                  <h2>Enterprise Application </h2>
                  <p
                    id={"description_"}

                  >
                    The way of managing the applications in any enterprise is
                    rapidly changing. They need to respond quickly in order to
                    remain competitive
                  </p>
                  <a href="/Enterprise">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {" "}
        <div className="team-single-area default-padding">
          <div className="container">
            <div className="team-content-top">
              <div className="row">
                <div className="col-lg-5 left-info">
                  <div className="thumb">
                    <img src={Microsoft} width="610" height="210" alt="Thumb" />
                  </div>
                </div>
                <div className="col-lg-7 right-info">
                  <h2>Microsoft Services</h2>
                  <p
                    id={"description_"}

                  >
                    Vaanam provides a wide range of Microsoft
                    Technology services and expertise to support all client
                    requirements.
                  </p>
                  <a href="/MicrosoftServices">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {" "}
        <div className="team-single-area default-padding">
          <div className="container">
            <div className="team-content-top">
              <div className="row">
                <div className="col-lg-5 left-info">
                  <div className="thumb">
                    <img src={AIML} width="610" height="210" alt="Thumb" />
                  </div>
                </div>
                <div className="col-lg-7 right-info">
                  <h2 style={{ textTransform: "initial" }}>
                    {" "}
                    AI and ML Services
                  </h2>
                  <p
                    id={"description_"}

                  >
                    Vaanam's Artificial Intelligence & Machine Learning
                    services, you can automate processes, strengthen customer
                    relations, leverage enhanced analytics, and future-proof
                    your business processes; ensuring you stay relevant to the
                    evolving market and customer needs.
                  </p>
                  <a href="/AIML">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {" "}
        <div className="team-single-area default-padding">
          <div className="container">
            <div className="team-content-top">
              <div className="row">
                <div className="col-lg-5 left-info">
                  <div className="thumb">
                    <img src={Mobility} width="610" height="210" alt="Thumb" />
                  </div>
                </div>
                <div className="col-lg-7 right-info">
                  <h2>Mobility Services</h2>
                  <p
                    id={"description_"}
                  >
                    The way of managing the applications in any enterprise is
                    rapidly changing. They need to respond quickly in order to
                    remain competitive.
                  </p>
                  <a href="/Mobility">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {" "}
        <div className="team-single-area default-padding">
          <div className="container">
            <div className="team-content-top">
              <div className="row">
                <div className="col-lg-5 left-info">
                  <div className="thumb">
                    <img src={Business} width="610" height="210" alt="Thumb" />
                  </div>
                </div>
                <div className="col-lg-7 right-info">
                  <h2>Business Intellegence</h2>
                  <p
                    id={"description_"}

                  >
                    The best-of-breed technology from leading technology vendors
                    with our proven methodologies to provide a unique solution.
                  </p>
                  <a href="/BusinessIntellegence">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {" "}
        <div className="team-single-area default-padding">
          <div className="container">
            <div className="team-content-top">
              <div className="row">
                <div className="col-lg-5 left-info">
                  <div className="thumb">
                    <img src={Reality} width="610" height="210" alt="Thumb" />
                  </div>
                </div>
                <div className="col-lg-7 right-info">
                  <h2>Reality Technologies</h2>
                  <p
                    id={"description_"}

                  >
                    The Internet of Things (IoT) is a system of interrelated
                    computing devices, mechanical and digital machines, objects,
                    animals or people.
                  </p>
                  <a href="/Reality">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop smooth color="#2b5690" />
      <Footer />
    </>
  );
};
export default ServicesHome;
