import React, { useState, useReducer, useEffect } from "react";
import CareerServices from "../../../Services/CareerServices";
import "jquery";
import $ from "jquery";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import ReactQuill from "react-quill";


import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

var CareerServicesObj = new CareerServices();

function AddOrEditCareer(props) {
  const { Model, SetGridRender } = props;
  const [body, setBody] = useState("");

  console.log(props);

  const [Render, PageRender] = useReducer((x) => x + 1, 0);
  const [careerDetailId, setCareerDetailId] = useState(0);
  const [GetDropDown, setDropdown] = useState([]);
  const [GetWork, setWork] = useState([]);
  const [JobId, setJobId] = useState(0);
  const [workId, setWorkId] = useState(0);

  useEffect(() => {
    CareerServicesObj.GetDropdown().then((res) => {
      if (res.data != null) {
        const { job } = res.data;
        setDropdown([...job]);
      }
      if (res.data != null) {
        const { work } = res.data;
        setWork([...work]);
      }
    });
  }, []);
  const [error, setformError] = useState({
    jobTitle: "",
    jobDescription: "",
    experience: "",
    jobId: "",
    workId: "",
    location: "",
    designation: "",
    worklocation: "",
  });

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 750,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const CloseModel = () => {
    document.getElementById("AddOrEditCareer1").reset();
    props.Model.careerDetailId = "";
    props.Model.jobTitle = "";
    props.Model.jobDescription = "";
    props.Model.experience = "";
    props.Model.jobId = "Select";
    props.Model.workId = "Select";
    props.Model.workLocationID = "Select";
    props.Model.location = "";
    props.Model.designation = "";

    setformError({
      jobTitle: "",
      jobDescription: "",
      experience: "",
      location: "",
      designation: "",
      jobId: "",
      workId: "",
      worklocation: "",
    });
  };

  const onKeyDown = (event) => {
    if (event.code === "Space") event.preventDefault();
  };
  function numberOnly(e) {
    var element = document.getElementById(e);
    element.value = element.value.replace(/[^0-9]/gi, "");
  }
  const jobtitle = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      myerror.jobTitle = "Job Title is Required";
    } else {
      myerror.jobTitle = "";
    }
    setformError({ ...myerror });
  };
  // const jobDescription = (e) => {
  //   var myerror = error;
  //   if (e.target.value == null || e.target.value == "") {
  //     myerror.jobDescription = "Job Description is Required";
  //   } else {
  //     myerror.jobDescription = "";
  //   }
  //   setformError({ ...myerror });
  // };

  // const designation = (e) => {
  //   var myerror = error;
  //   if (e.target.value == null || e.target.value == "") {
  //     myerror.designation = "Designation is Required";
  //   } else {
  //     myerror.designation = "";
  //   }
  //   setformError({ ...myerror });
  // };

  const location = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      myerror.location = "Location is Required";
    } else {
      myerror.location = "";
    }
    setformError({ ...myerror });
  };

  const onSubmit = (res) => {
    res.preventDefault();

    var myerror = error;

    var flag = true;

    // if ( props.Model.careerDetailId == 0) {

    //     setCareerDetailId(0);
    // } else {
    //     setCareerDetailId(props.Model.careerDetailId);
    // }
    var data = {
      // SavedBy:parseInt(cookies.get("userID")),
      careerdetailId: Number(careerDetailId),
      JobId: Number(document.getElementById("jobId").value),
      WorkId: Number(document.getElementById("workId").value),
      jobTitle: document.getElementById("jobtitle").value,
      jobDescription: body,
      // Designation: document.getElementById("designation").value,
      experience: Number(document.getElementById("experience").value),
      location: document.getElementById("location").value,
      workLocationID: Number(document.getElementById("worklocation").value),
      workLocation: $("#worklocation option:selected").text(),
    };



    if (data.jobTitle !== null && data.jobTitle !== "") {
      myerror.jobTitle = "";
    } else {
      myerror.jobTitle = "Job Title is Required";
      flag = false;
    }



    if (data.jobDescription == null || data.jobDescription == "undefined" || data.jobDescription == "") {
      flag = false;
      Toast.fire({
        icon: "error",
        title: "Enter Job Description !",
      });

    } else {
      var replce = body.replace(/(<([^>]+)>)/gi, "");
      if (replce == "") {
        Toast.fire({
          icon: "error",
          title: "Enter Job Description !",
        });
        flag = false;
      } else {

        flag = true;
      }

    }

    var Job = document.getElementById("jobId").value;

    if (Job == "Select") {
      myerror.jobId = "Select Job Type";

    } else {
      myerror.jobId = "";
    }

    var work = document.getElementById("workId").value;
    if (work == "Select") {
      myerror.workId = "Select Work Mode";
      flag = false;
    } else {
      myerror.workId = "";
    }

    var worklocation = document.getElementById("worklocation").value;
    if (worklocation == "Select") {
      myerror.worklocation = "Select Work Location";
    } else {
      myerror.worklocation = "";
    }
    if (data.location !== null && data.location !== "") {
      myerror.location = "";
    } else {
      myerror.location = "Location is Required";
      flag = false;
    }


    setformError({ ...myerror });

    if (flag == true) {
      console.log(data);
      CareerServicesObj.Create(data).then((res) => {
        if (res.data == 1) {
          $(".close").trigger("click");

          Toast.fire({
            icon: "success",
            title: "Save Success",
          });
          // SetGridRender()
          setTimeout(function () {
            window.location.reload();
          }, 750);
        } else {
          Toast.fire({
            icon: "error",
            title: "Failed to Save!",
          });
        }
      });
    }
  };

  useEffect(() => {
    setCareerDetailId(props.Model.careerdetailId);


    $(`#jobId`).val(props.Model.jobId);

    $(`#workId`).val(props.Model.workId);
    if (props.Model.workLocationID == null || props.Model.workLocationID == "" || props.Model.workLocationID == 0) {
      props.Model.workId = "Select";
    }
    else {
      $(`#worklocation`).val(props.Model.workLocationID);
    }
    setBody(props.Model.jobDescription);
  }, [JSON.stringify(props.Model)]);

  const commonChange = (e) => {
    var myerror = error;
    const { name, value } = e.target;
    $(`#${name}`).val(value);
    if (e.target.id == "jobId") {
      if (e.target.value == "Select") {
        myerror.jobId = "Select Job Type";
      } else {
        myerror.jobId = "";
      }
    } else if (e.target.id == "workId") {
      if (e.target.value == "Select") {
        myerror.workId = "Select Work Mode";
      } else {
        myerror.workId = "";
      }
    }
    else if (e.target.id == "worklocation") {
      if (e.target.value == "Select") {
        myerror.worklocation = "Select Work Location";
      } else {
        myerror.worklocation = "";
      }
    }
    setformError({ ...myerror });
  };

  return (
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header no-bd">
          <h5 class="modal-title">
            <span class="fw-mediumbold">Add Jobs </span>
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
            data-dismiss="modal"
            onClick={() => {
              CloseModel();
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <form onSubmit={onSubmit} id="AddOrEditCareer1" autoComplete="off">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group  ">
                    <label>
                      Job Title<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      id="jobtitle"
                      className="form-control"
                      maxLength={200}
                      defaultValue={props.Model.jobTitle}
                      placeholder="Job Title"
                      name="name"
                      onChange={jobtitle}
                    />

                    <span className="text-danger">{error.jobTitle}</span>
                  </div>
                </div>

                {/* <div className="col-sm-4">
                  <div className="form-group ">
                    <label>
                      Designation<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      id="designation"
                      className="form-control"
                      defaultValue={props.Model.designation}
                      placeholder="Designation"
                      name="orderby"
                      onChange={designation}
                    />
                    <span className="text-danger">{error.designation}</span>
                  </div>
                </div> */}

                <div className="col-sm-4">
                  <div className="form-group ">
                    <label>
                      Job Type<span className="text-danger">*</span>
                    </label>
                    <select
                      class="form-select"
                      id="jobId"
                      name="jobId"
                      onChange={(e) => {
                        commonChange(e);
                      }}
                      defaultValue={props.Model.jobId}
                    >
                      <option value="Select">Select</option>

                      {GetDropDown != null && GetDropDown.length > 0 ? (
                        GetDropDown.map((v, i) => {
                          return (
                            <option value={v.jobTypeId}>{v.jobTypeName}</option>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </select>
                    <span className="text-danger">{error.jobId}</span>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group ">
                    <label>
                      Work Mode<span className="text-danger">*</span>
                    </label>
                    <select
                      class="form-select"
                      name="workId"
                      onChange={commonChange}
                      id="workId"
                      defaultValue={props.Model.workId}
                    >
                      <option value="Select">Select</option>
                      {GetWork != null && GetWork.length > 0 ? (
                        GetWork.map((v, i) => {
                          return (
                            <option key={i} value={v.workModeId}>
                              {v.workModeName}
                            </option>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </select>
                    <span className="text-danger">{error.workId}</span>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group  ">
                    <label>
                      Job Description<span className="text-danger">*</span>
                    </label>

                    {/* <textarea
                      id="jobDescription"
                      className="form-control"
                      defaultValue={props.Model.jobDescription}
                      placeholder="Job Description"
                      name="answer"
                      rows="5"
                      onChange={jobDescription}
                    /> */}
                    <ReactQuill
                      placeholder="Job description..."
                      modules={AddOrEditCareer.modules}
                      formats={AddOrEditCareer.formats}
                      value={body}
                      onChange={setBody}
                    />
                    {/* <span className="text-danger">{error.jobDescription}</span> */}
                  </div>
                </div>


                <div className="col-sm-4">
                  <div className="form-group ">
                    <label>
                      Work Location<span className="text-danger">*</span>
                    </label>
                    <select
                      class="form-select"
                      name="worklocation"
                      onChange={commonChange}
                      id="worklocation"
                      Value={props.Model.workLocationID}
                    >
                      <option value="Select">Select</option>
                      <option value="1">India</option>
                      <option value="2">US</option>
                    </select>
                    <span className="text-danger">{error.worklocation}</span>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group ">
                    <label>Experience</label>
                    <input
                      type="text"
                      id="experience"
                      className="form-control"
                      maxLength={2}
                      defaultValue={props.Model.experience}
                      placeholder="Experience"
                      name="experience"
                      onChange={() => numberOnly("experience")}
                    />
                    <span className="text-danger">{error.experience}</span>
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="form-group ">
                    <label>
                      Location<span className="text-danger">*</span>
                    </label>

                    <textarea
                      type="text"
                      id="location"
                      className="form-control"
                      defaultValue={props.Model.location}
                      placeholder="Location"
                      name="orderby"
                      onChange={location}
                    />
                    <span className="text-danger">{error.location}</span>
                  </div>
                </div>
              </div>

              <div className="modal-footer no-bd">
                <button
                  type="submit"
                  id="addRowButton"
                  className="btn btn-primary"
                >
                  Add
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  onClick={CloseModel}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  );
}
AddOrEditCareer.modules = {
  toolbar: [
    [{ font: [] }],
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    ["link", "image"],
    ["code-block"],
    ["clean"],
  ],
};

AddOrEditCareer.formats = [
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "list",
  "bullet",
  "align",
  "color",
  "background",
  "link",
  "image",
  "code-block",
];

export default AddOrEditCareer;
