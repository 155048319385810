import { Header } from "../Home/Header";
import ServImg from "../../../Assets/img/banner/1.jpg";
import { Footer } from "../Home/Footer";
import about from "../../../Assets/img/about/1.jpg";
import ScrollToTop from "react-scroll-to-top";
import React, { useEffect } from "react";
import $ from "jquery";

const Reality = () => {
  useEffect(() => {
    setTimeout(() => {
      $("#preloader").hide();
    }, 1000);
  }, []);

  return (
    <div>
      <Header />
      <div
        className="breadcrumb-area text-center shadow dark text-light bg-cover"
        style={{
          backgroundImage: `url(${ServImg})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>Reality Technologies </h1>
              <ul className="breadcrumb">
                <li>
                  <a href="/">
                    <i className="fas fa-home" /> Home
                  </a>
                </li>
                <li className="active">Reality Technologies </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumb */}
      {/* Star Services Details Area
    ============================================= */}
      <div className="services-details-area default-padding">
        <div className="container">
          <div className="services-details-items">
            <div className="row">
              <div className="col-lg-8 services-single-content">
                <h2>Reality Technologies</h2>
                <h4>Reality Technologies</h4>
                <p>
                  Businesses are incorporating Reality – Virtuality
                  Technologies/Applications at a faster pace than ever before.
                  Vaanam is an Indian Player in the IoT,
                  AR, VR and Machine and Deep Learning. We evaluate client
                  requirements to develop most viable and profitable solutions
                  assuring improved usability for them.
                </p>
                <p>
                  The Internet of Things (IoT) is a system of interrelated
                  computing devices, mechanical and digital machines, objects,
                  animals or people. A thing, in the Internet of Things, can be
                  a person with a heart monitor implant, a farm animal with a
                  biochip transponder, an automobile that has built-in sensors
                </p>
                <p>
                  AR in simplest of words is the enhancement made to some
                  elements such as picture, sound, GPS data and motion etc. To
                  elevate the attractiveness and details associated with a view
                  for making the surroundings highly interactive and digitally
                  manipulated. Virtual reality (VR) is an artificial,
                  computer-generated simulation or recreation which immerces the
                  user by making them feel like they are experiencing the
                  simulated reality firsthand, primarily by stimulating their
                  vision and hearing.
                </p>
                <p>
                  Machine and Deep learing is about to learn from experience
                  with some class of tasks and performance measured by improving
                  with the experience.
                </p>
                <img src={require("../../../Assets/img/Solutions/iot-1.jpg")} />
              </div>
              <div className="col-lg-4 services-sidebar">
                {/* Single Widget */}
                <div className="single-widget services-list">
                  <h4 className="widget-title">Solutions List</h4>
                  <div className="content">
                    <ul>
                      <li>
                        <a href="/Enterprise">Enterprise Application</a>
                      </li>
                      <li>
                        <a href="/MicrosoftServices">Microsoft Services</a>
                      </li>
                      <li>
                        <a href="/AIML" style={{ textTransform: "initial" }}>
                          AI and ML Services
                        </a>
                      </li>
                      <li>
                        <a href="/Mobility">Mobility services</a>
                      </li>
                      <li>
                        <a href="/BusinessIntellegence">
                          Business Intelligence
                        </a>
                      </li>
                      <li className="current-item">
                        <a href="/Reality">Reality Technologies</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Widget */}
                <div
                  className="single-widget quick-contact text-light"
                  style={{ backgroundImage: `url(${about})` }}
                >
                  <div className="content">
                    <i className="fas fa-phone" />
                    <h4>Need any help?</h4>

                    <h2>+91 422 256 7626</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Services Details Area */}

      <div className="team-area bg-gray default-padding bottom-less">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="site-heading text-center">
                <h3>Vaanam expertices in,</h3>
                <div className="dev" />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="team-items text-center">
            <div
              className="row"
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              {/* Single Item */}
              <div className="single-item col-lg-4 col-md-6">
                <div className="item">
                  <div className="thumb">
                    <img
                      src={require("../../../Assets/img/Solutions/iot.png")}
                    />
                  </div>
                </div>
              </div>
              {/* End Single Item */}
              {/* Single Item */}
              <div className="single-item col-lg-4 col-md-6">
                <div className="item">
                  <div className="thumb">
                    <img
                      src={require("../../../Assets/img/Solutions/vr.jpg")}
                    />
                  </div>
                </div>
              </div>
              {/* End Single Item */}
              {/* Single Item */}
              <div className="single-item col-lg-4 col-md-6">
                <div className="item">
                  <div className="thumb">
                    <img
                      src={require("../../../Assets/img/Solutions/ar.jpg")}
                    />
                  </div>
                </div>
              </div>
              {/* End Single Item */}
              <div className="single-item col-lg-4 col-md-6">
                <div className="item">
                  <div className="thumb">
                    <img
                      src={require("../../../Assets/img/Solutions/deeplearning.jpg")}
                    />
                  </div>
                </div>
              </div>
              <div className="single-item col-lg-4 col-md-6">
                <div className="item">
                  <div className="thumb">
                    <img
                      src={require("../../../Assets/img/Solutions/ml.png")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Team Area */}
      {/* Start Footer 
    ============================================= */}
      <Footer />
      {/* End Footer */}
      {/* jQuery Frameworks
    ============================================= */}
    </div>
  );
};
export default Reality;
