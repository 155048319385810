import React, { useEffect } from "react";
import jQuery from "jquery";
import $ from "jquery";
import { Header } from "../../Home/Header";
import Report1 from ".././../../../Assets/img/banner/1.jpg";
import about from "../../../../Assets/img/about/1.jpg";
import { Footer } from "../../Home/Footer";
import "../../../../Assets/js/progress-bar.min.js";
import "../../../../Assets/js/count-to.js";
// import "../../../../Assets/js/bootsnav.js";
import "../../../../Assets/js/main.js";
import "../../../../Assets/css/bootstrap.min.css";
import "../../../../Assets/css/font-awesome.min.css";
import "../../../../Assets/css/themify-icons.css";
import "../../../../Assets/css/elegant-icons.css";
import "../../../../Assets/css/flaticon-set.css";
import "../../../../Assets/css/magnific-popup.css";
import "../../../../Assets/css/owl.carousel.min.css";
import "../../../../Assets/css/owl.theme.default.min.css";
import "../../../../Assets/css/animate.css";
// import "../../../../Assets/css/bootsnav.css";
import "../../../../Assets/css/style.css";
import "../../../../Assets/css/responsive.css";
import ScrollToTop from "react-scroll-to-top";
import Report from ".././../../../Assets/img/BusinessIntellegence/Reports.png";
import Reportimg from ".././../../../Assets/img/BusinessIntellegence/Reports1.png";
const BIReportsandAnalysis = () => {
  useEffect(() => {
    setTimeout(() => {
      $("#preloader").hide();
    }, 1000);
  }, []);

  return (
    <>
      {" "}
      <Header />
      <div
        className="breadcrumb-area text-center shadow dark text-light bg-cover"
        style={{ backgroundImage: `url(${Report1})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>BI Reports & Analysis</h1>
              <ul className="breadcrumb">
                <li>
                  <a href="/">
                    <i className="fas fa-home" />
                    Home
                  </a>
                </li>
                <li className="active">BI Reports & Analysis </li>
              </ul>{" "}
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="services-details-area default-padding">
        <div className="container">
          <div className="services-details-items">
            <div className="row">
              <div className="col-lg-8 services-single-content">
                <h2>Reporting &amp; Analysis</h2>
                <p>
                  Reporting and analysis provide a wide range of advantages and
                  opportunities for organizations. Vaanam’s reporting services
                  provides you access to a complete list of self-serve report
                  types, is adaptable to any data source, and operates from a
                  single platform for a variety of benefits. Many companies find
                  that they have many transaction systems generating data, but
                  are unable to turn their most valuable data into actionable
                  information.
                </p>
                <p>
                  Vaanam will enable client staffs to easily publish reports and
                  conduct analysis based on critical information, which will
                  improve decision making and allow easy and constant monitoring
                  of financial and service deliver performance.
                </p>
                <p>
                  {" "}
                  Vaanam's reporting and analysis service will enable you to:
                  Perform cross-production and other trending analyses
                  Distribute reports to multiple locations and in multiple
                  formats View, query, and analyze for collaborative
                  contribution to any report from a single platform Embed live
                  applications, Web sites, and non-BI content within a report
                  Easily create complex, multi-page layouts using different data
                  sources.
                </p>
                <h2>Dashboards</h2>
                Dashboards allow business managers and decision makers to
                continuously monitor the progress of their business operations.
                Vaanam can help business executives translate complex
                information to presentations that are easily understood using
                gauges, maps, charts, and other graphical elements. Our
                dashboard solutions:
                <ul>
                  <li>
                    Provide a simple yet comprehensive presentation for quick
                    understanding of a business situation
                  </li>
                  <li>Consolidate data across all departments</li>
                  <li>
                    Allows for cross-analysis of various data and results{" "}
                  </li>
                  <li>Present real-time performance metrics</li>
                  <li>
                    Enable decision makers to customize the information to his /
                    her particular requirements.
                  </li>
                </ul>
                <h2>Scorecards</h2>
                Scorecards assist business enterprises in improving performance
                across all sectors of the organization.Vaanams' scorecards
                service can provide clear performance indicators to enable top
                executives to quickly make strategic decisions. Vaanam can help
                you develop scorecards that can:
                <ul>
                  <li>
                    Communicate strategic goals clearly and constantly across
                    all departments
                  </li>
                  <li>
                    Illustrate relationships between departments and systems
                  </li>
                  <li>Manage data across all departments and applications </li>
                  <li>
                    Build metrics for better understanding of business
                    situations
                  </li>
                  <li>
                    Summarize data into clear and concise performance indicators
                  </li>
                </ul>
                <p>
                  Vaanam consultants will lead your staff through each of the
                  necessary steps to effectively utilize the value of
                  scorecards:
                </p>
                <ul>
                  <li>Define strategic measurements</li>
                  <li>Identify data sources</li>
                  <li>Design and implement technical and data architectures</li>
                  <li>Select a balanced scorecard application</li>
                  <li>
                    Implement and rollout the application to the client staff
                  </li>
                </ul>
              </div>

              <div className="col-lg-4 services-sidebar">
                {/* Single Widget */}
                <div className="single-widget services-list">
                  <h4 className="widget-title">Solutions List</h4>
                  <div className="content">
                    <ul>
                      <li>
                        <a href="/Enterprise">Enterprise Application</a>
                      </li>
                      <li>
                        <a href="/MicrosoftServices">Microsoft Services</a>
                      </li>
                      <li>
                        <a href="/AIML" style={{ textTransform: "initial" }}>
                          AI and ML Services
                        </a>
                      </li>
                      <li>
                        <a href="/Mobility">Mobility Services</a>
                      </li>
                      <li>
                        <a href="/BusinessIntellegence">
                          Business Intelligence
                        </a>
                      </li>
                      <ul>
                        <li>
                          <a href="/BIAssessment">BI Assessment</a>
                        </li>
                        <li>
                          <a href="/BIMigration">BI Migration</a>
                        </li>
                        <li className="current-item">
                          <a href="/BIReports">BI Reports & Analysis</a>
                        </li>
                      </ul>

                      <li>
                        <a href="/Reality">Reality Technologies</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Widget */}
                <div
                  className="single-widget quick-contact text-light"
                  style={{ backgroundImage: `url(${about})` }}
                >
                  <div className="content">
                    <i className="fas fa-phone" />
                    <h4>Need any help?</h4>

                    <h2>+91 422 256 7626</h2>
                  </div>
                </div>
                <img src={Reportimg} className="reportsideimg"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="services"
        className="services-style-six-area overflow-hidden default-padding bottom-less bg-gray BIReportgrid"
      >
        <div className="shape-box">
          <div className="shape-item" />
          <div className="shape-item" />
        </div>
        <div className="container">
          <div>
            <h2>Planning, Budgeting, and Forecasting</h2>
            <p>
              Our planning / budgeting / forecasting solution will provide
              detailed reporting capabilities against an organization's
              financial and cost information and improve the production needs
              for decision support, analysis, and reporting. Our service
              includes creating a production report that will serve as a central
              repository for production related - financial and cost information
              across different business units.
            </p>
            <p>
              Our solution will support the need for aggregated production cost
              data for cost-effective analysis and executive decision support as
              well as detailed historical, product and financial data for
              strategic planning, forecasting and budgeting. We can help you
              with getting location accounting systems to upload to single data
              repository that is accurate and complete.
            </p>
            <img src={Report} className="Reportimg"></img>
          </div>
        </div>
      </div>
      <ScrollToTop smooth color="#2b5690" />
      <Footer />
    </>
  );
};
export default BIReportsandAnalysis;
