import React, { useState } from "react";

import "jquery";
import $ from "jquery";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import ClientServices from "../../../Services/ClientsServices";
import { FaCloudDownloadAlt } from "react-icons/fa";

var ClientServicesobj = new ClientServices();
function AddOrEditClients(props) {
  const [isLoaded, setIsLoaded] = useState(false);

  const [logo, setlogo] = useState();
  const [error, setformError] = useState({

    client_logo: "",
  });
  const cookies = new Cookies();
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 750,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const CloseModel = () => {
    document.getElementById("AddOrEditClients").reset();
    props.Model.clientID = "";

    props.Model.logoUrl = "";
    props.Model.clientOrder = "";
    setlogo("");

    setformError({
      client_logo: ""
    });
  };
  const onKeyDown = (event) => {
    if (event.code === "Space") event.preventDefault();
  };
  function numberOnly(e) {
    var element = document.getElementById(e);
    element.value = element.value.replace(/[^0-9]/gi, "");
  }




  const logochange = (e) => {
    if (props.Model.clientID == null || props.Model.clientID == "") {
      var myerror = error;
      if (e.target.value == null || e.target.value == "") {
        myerror.client_logo = "Client Logo is Required";
        setlogo("");
      } else {
        setlogo(e.target.files[0]);
        myerror.client_logo = "";
      }
      setformError({ ...myerror });
    }
  };

  var uploadfiles = { logo: logo };

  const onSubmit = (res) => {
    res.preventDefault();

    var myerror = error;

    var flag = true;

    var data = {
      SavedBY: parseInt(cookies.get("userID")),
      ClientID: parseInt(props.Model.clientID),

      LogoUrl: null,

      ClientOrder: null,
    };


    if (props.Model.clientID == null || props.Model.clientID == "") {

      if (uploadfiles.logo == null || uploadfiles.logo == "") {
        myerror.client_logo = " Client Logo is Required";
        flag = false;
      } else {
        myerror.client_logo = "";
      }
    }

    setformError({ ...myerror });
    if (flag == true) {
      setIsLoaded(true);

      const formData = new FormData();
      $.each($("#logo")[0].files, function (i, file) {
        formData.append("Logo", file);
      });


      ClientServicesobj.UploadFiles(formData).then((res) => {

        data.LogoUrl = res.data.logoUrl;

        ClientServicesobj.Create(data).then((res) => {
          if (res.data == 1) {
            $(".close").trigger("click");
            Toast.fire({
              icon: "success",
              title: "Save Success",
            });
            setTimeout(function () {
              window.location.reload();
            }, 750);
          } else {
            Toast.fire({
              icon: "error",
              title: "Failed to Save!",
            });
          }
        });
      });
    }
  };
  function extractImageName(url) {
    // Get the last part of the URL after the last "/"
    const imageNameWithGuid = url.substring(url.lastIndexOf('/') + 1);

    // Remove the GUID part by splitting at the underscore
    const imageNameParts = imageNameWithGuid.split('_');

    // Join the parts of the image name, excluding the GUID part
    const imageName = imageNameParts.slice(1).join('_');

    // Remove any query parameters or hashes from the image name
    const cleanImageName = imageName.split('?')[0].split('#')[0];

    return cleanImageName;
  }
  return (
    <div class="modal-dialog modal-s" role="document">
      <div class="modal-content">
        <div class="modal-header no-bd">
          <h5 class="modal-title">
            <span class="fw-mediumbold">Add Client</span>
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
            data-dismiss="modal"
            onClick={CloseModel}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <form onSubmit={onSubmit} id="AddOrEditClients" autocomplete="off">
              <div className="row">


                <div className="col-10">
                  <div className="form-group ">
                    <label>
                      Client Logo
                      {props.Model.clientID == null ||
                        props.Model.clientID == "" ? (
                        <span className="text-danger">*</span>
                      ) : (
                        <></>
                      )}
                    </label>
                    <input
                      id="logo"
                      type="file"
                      className="form-control"
                      defaultValue={props.Model.logoUrl}
                      name="logo"
                      onChange={logochange}
                      accept=".png, .jpg, .jpeg"
                    />
                    {props.Model.logoUrl ? <><span>{extractImageName(props.Model.logoUrl)}</span>&nbsp;
                      <a href={process.env.REACT_APP_API_IMAGEPATH + props.Model.logoUrl} target="_blank">
                        <FaCloudDownloadAlt />
                      </a></> : <></>}<br />
                    <span className="text-danger">{error.client_logo}</span>
                  </div>
                </div>
                {/* 
                <div className="col-sm-4">
                  <div className="form-group ">
                    <label>Client Order</label>

                    <input
                      type="text"
                      id="orderby"
                      className="form-control"
                      maxLength={3}
                      defaultValue={props.Model.clientOrder}
                      placeholder="Client Order"
                      name="orderby"
                      onKeyDown={(event) => onKeyDown(event)}
                      onChange={(e) => numberOnly("orderby")}
                    />
                  </div>
                </div> */}
              </div>

              <div className="modal-footer no-bd">
                {!isLoaded ? (
                  <button
                    type="submit"
                    id="addRowButton"
                    className="btn btn-primary"
                  >
                    Add
                  </button>
                ) : (
                  <button className="btn btn-primary" type="button" disabled>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading...
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  onClick={CloseModel}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddOrEditClients;
