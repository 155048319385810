import React, { useState, useEffect } from "react";
import ProjectServices from "../../../Services/ProjectServices";
import "jquery";
import $ from "jquery";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { FaPencilAlt, FaTrashAlt, FaCloudDownloadAlt } from "react-icons/fa";

var ProjectServicesObj = new ProjectServices();


const AddOrEditProjects = (props) => {
  const [image, setImage] = useState();
  const [logo, setlogo] = useState();
  const [clientlogo, setclientlogo] = useState();
  const [ProjectDetails, setProjectDetails] = useState([]);


  const [error, setformError] = useState({
    project_name: "",
    project_description: "",
    project_image: "",
    project_logo: "",
    Client_logo: "",
  });
  const cookies = new Cookies();
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 750,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });


  function DeleteClientLogo(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "Do You Want to Delete this Project?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        ProjectServicesObj.DeleteClientLogo(id).then((res) => {
          if (res.data == 1) {
            Toast.fire({
              icon: "success",
              title: "Delete Success",
            });
            setTimeout(function () {
              window.location.reload();
            }, 750);
          } else {
            Toast.fire({
              icon: "error",
              title: "Delete Failed!",
            });
          }
        });
      }
    });
  }

  const CloseModel = () => {
    document.getElementById("AddOrEditProjects").reset();
    props.Model.projectID = "";
    props.Model.name = "";
    props.Model.description = "";
    props.Model.imageUrl = "";
    props.Model.logoUrl = "";
    props.Model.projectOrder = "";
    props.Model.websiteUrl = "";

    setformError({
      project_name: "",
      project_description: "",
      project_image: "",
      project_logo: "",
    });
  };
  const onKeyDown = (event) => {
    if (event.code === "Space") event.preventDefault();
  };
  function numberOnly(e) {
    var element = document.getElementById(e);
    element.value = element.value.replace(/[^0-9]/gi, "");
  }
  const namechange = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      myerror.project_name = "Product Name is Required";
    } else {
      myerror.project_name = "";
    }
    setformError({ ...myerror });
  };

  const descriptionchange = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      myerror.project_description = "Description is Required";
    } else {
      myerror.project_description = "";
    }
    setformError({ ...myerror });
  };
  const imagechange = (e) => {
    if (props.Model.projectID == null || props.Model.projectID == "") {
      var myerror = error;
      if (e.target.value == null || e.target.value == "") {
        myerror.project_image = "Product Image is Required";
        setImage("");
      } else {
        setImage(e.target.files[0]);
        myerror.project_image = "";
      }
      setformError({ ...myerror });
    }
  };
  const logochange = (e) => {
    if (props.Model.projectID == null || props.Model.projectID == "") {
      var myerror = error;
      if (e.target.value == null || e.target.value == "") {
        myerror.project_logo = "Product Logo is Required";
        setlogo("");
      } else {
        setlogo(e.target.files[0]);
        myerror.project_logo = "";
      }
      setformError({ ...myerror });
    }
  };

  const Clientlogochange = (e) => {
    if (props.Model.projectID == null || props.Model.projectID == "") {
      var myerror = error;
      if (e.target.value == null || e.target.value == "") {
        myerror.Client_logo = "Client Logo is Required";
        setclientlogo("");
      } else {
        setclientlogo([...e.target.files]);
        myerror.Client_logo = "";
      }
      setformError({ ...myerror });
    }
  };
  var uploadfiles = { image: image, logo: logo };


  const onSubmit = (res) => {
    res.preventDefault();

    var myerror = error;

    var flag = true;

    var data = {
      SavedBY: parseInt(cookies.get("userID")),
      ProjectID: parseInt(props.Model.projectID),
      Name: document.getElementById("name").value,
      Description: document.getElementById("description").value,
      ImageUrl: null,
      LogoUrl: null,
      WebsiteUrl: document.getElementById("websiteUrl").value,
      ProjectOrder: parseInt(document.getElementById("orderby").value),
      clientlogoUrls: null,
    };



    if (data.Name == null || data.Name == "") {
      myerror.project_name = "Product Name is Required";

      flag = false;
    } else {
      myerror.project_name = "";
    }

    if (data.Description == null || data.Description == "") {
      myerror.project_description = "Description is Required";
      flag = false;
    } else {
      myerror.project_description = "";
    }
    if (props.Model.projectID == null || props.Model.projectID == "") {
      if (uploadfiles.image == null || uploadfiles.image == "") {
        myerror.project_image = "Product Image is Required";
        flag = false;
      } else {
        myerror.project_image = "";
      }
      if (uploadfiles.logo == null || uploadfiles.logo == "") {
        myerror.project_logo = " Client Logo is Required";
        flag = false;
      } else {
        myerror.project_logo = "";
      }
    }

    setformError({ ...myerror });
    if (flag == true) {
      const formData = new FormData();
      const clientformData = new FormData();
      $.each($("#logo")[0].files, function (i, file) {
        formData.append("Logo", file);
      });
      $.each($("#image")[0].files, function (i, file) {
        formData.append("Image", file);
      });
      $.each($("#Clientlogo")[0].files, function (i, file) {
        formData.append("clientlogo", file);
      });





      ProjectServicesObj.UploadFiles(formData).then((res) => {
        data.ImageUrl = res.data.imageUrl;
        data.LogoUrl = res.data.logoUrl;
        data.clientlogoUrls = res.data.clientlogoUrls;
        // if(clientlogo != null && clientlogo != ""){
        //   clientformData.append("clientlogo", clientlogo);
        // }
        // data.Clientlogo = clientformData;
        ProjectServicesObj.Create(data).then((res) => {
          if (res.data == 1) {
            $(".close").trigger("click");
            Toast.fire({
              icon: "success",
              title: "Save Success",
            });
            setTimeout(function () {
              window.location.reload();
            }, 750);
          } else {
            Toast.fire({
              icon: "error",
              title: "Failed to Save!",
            });
          }
        });
      });
    }
  };

  function extractImageName(url) {
    // Get the last part of the URL after the last "/"
    const imageNameWithGuid = url.substring(url.lastIndexOf('/') + 1);

    // Remove the GUID part by splitting at the underscore
    const imageNameParts = imageNameWithGuid.split('_');

    // Join the parts of the image name, excluding the GUID part
    const imageName = imageNameParts.slice(1).join('_');

    // Remove any query parameters or hashes from the image name
    const cleanImageName = imageName.split('?')[0].split('#')[0];

    return cleanImageName;
  }

  return (
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header no-bd">
          <h5 class="modal-title">
            <span class="fw-mediumbold">Add Product</span>
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
            data-dismiss="modal"
            onClick={CloseModel}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <form onSubmit={onSubmit} id="AddOrEditProjects" autocomplete="off">
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-group ">
                    <label>
                      Product Name <span className="text-danger">*</span>
                    </label>

                    <input
                      type="text"
                      id="name"
                      className="form-control"
                      maxLength={200}
                      defaultValue={props.Model.name}
                      placeholder="Product Name"
                      name="name"
                      onChange={namechange}
                    />

                    <span className="text-danger">{error.project_name}</span>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group  ">
                    <label>
                      Description<span className="text-danger">*</span>
                    </label>

                    <textarea
                      id="description"
                      className="form-control"
                      maxLength={2000}
                      defaultValue={props.Model.description}
                      placeholder="Product Description"
                      name="description"
                      onChange={descriptionchange}
                    />

                    <span className="text-danger">
                      {error.project_description}
                    </span>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group ">
                    <label>
                      Product Logo
                      {props.Model.projectID == null ||
                        props.Model.projectID == "" ? (
                        <span className="text-danger">*</span>
                      ) : (
                        <></>
                      )}
                    </label>
                    <input
                      id="logo"
                      type="file"
                      className="form-control"
                      defaultValue={props.Model.logoUrl}
                      name="logo"
                      onChange={logochange}
                      accept=".png, .jpg, .jpeg"
                    />
                    {props.Model.logoUrl ? <><span>{extractImageName(props.Model.logoUrl)}</span>&nbsp;
                      <a href={process.env.REACT_APP_API_IMAGEPATH + props.Model.logoUrl} target="_blank">
                        <FaCloudDownloadAlt />
                      </a></> : <></>}<br />
                    <span className="text-danger">{error.project_logo}</span>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group ">
                    <label>
                      Product Image
                      {props.Model.projectID == null ||
                        props.Model.projectID == "" ? (
                        <span className="text-danger">*</span>
                      ) : (
                        <></>
                      )}
                    </label>

                    <input
                      id="image"
                      type="file"
                      className="form-control"
                      defaultValue={props.Model.imageUrl}
                      name="image"
                      onChange={imagechange}
                      accept=".png, .jpg, .jpeg"
                    />
                    {props.Model.imageUrl ? <><span>{extractImageName(props.Model.imageUrl)}</span>&nbsp;
                      <a href={process.env.REACT_APP_API_IMAGEPATH + props.Model.imageUrl} target="_blank">
                        <FaCloudDownloadAlt />
                      </a></> : <></>}<br />
                    <span className="text-danger">{error.project_image}</span>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group ">
                    <label>Product Url</label>

                    <input
                      type="text"
                      id="websiteUrl"
                      className="form-control"
                      defaultValue={props.Model.websiteUrl}
                      placeholder="Product Url"
                      name="websiteUrl"
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group ">
                    <label>Product Order</label>

                    <input
                      type="text"
                      id="orderby"
                      className="form-control"
                      maxLength={3}
                      defaultValue={props.Model.projectOrder}
                      placeholder="Product Order"
                      name="orderby"
                      onKeyDown={(event) => onKeyDown(event)}
                      onChange={(e) => numberOnly("orderby")}
                    />
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="form-group ">
                    <label>
                      Client Logo
                      {props.Model.projectID == null ||
                        props.Model.projectID == "" ? (
                        <span className="text-danger">*</span>
                      ) : (
                        <></>
                      )}
                    </label>
                    <input
                      id="Clientlogo"
                      type="file"
                      className="form-control"
                      defaultValue={props.Model.logoUrl}
                      name="Clientlogo"
                      onChange={Clientlogochange}
                      accept=".png, .jpg, .jpeg"
                      multiple={true}
                    />
                    <span className="text-danger">{error.Client_logo}</span>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table
                    className="display table table-striped table-hover"
                    id="AssociateDisplay"
                  >
                    <thead>
                      <tr>
                        <th>Client Logo</th>

                        <th>Action(s)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {" "}
                      {props.Model.clientlogoUrls != null &&
                        props.Model.clientlogoUrls.length > 0 ? (
                        props.Model.clientlogoUrls.map((v, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                <img
                                  className="tableimage"
                                  src={
                                    process.env.REACT_APP_API_IMAGEPATH +
                                    v.clientlogoUrl
                                  }
                                  alt="No Logo"
                                />
                              </td>


                              <td>
                                <div class="form-button-action tableoptions">


                                  <button
                                    type="button"
                                    data-toggle="tooltip"
                                    title=""
                                    class="btn btn-link "
                                    data-original-title="Remove"
                                    onClick={() =>
                                      DeleteClientLogo(v.projectClientId)
                                    }
                                  >
                                    <FaTrashAlt />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <label>No Data in Table</label>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="modal-footer no-bd">
                <button
                  type="submit"
                  id="addRowButton"
                  className="btn btn-primary"
                >
                  Add
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  onClick={CloseModel}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddOrEditProjects;
