import jQuery from "jquery";
import WOW from "wowjs";
(function ($) {
  "use strict";

  $(document).ready(function () {
    /* ==================================================
            # Wow Init
         ===============================================*/
    var wow = new WOW({
      boxClass: "wow", // animated element css class (default is wow)
      animateClass: "animated", // animation css class (default is animated)
      offset: 0, // distance to the element when triggering the animation (default is 0)
      mobile: true, // trigger animations on mobile devices (default is true)
      live: true, // act on asynchronously loaded content (default is true)
    });
    wow.init();

    /* ==================================================
            # Tooltip Init
        ===============================================*/
    $('[data-toggle="tooltip"]').tooltip();

    /* ==================================================
            # Smooth Scroll
         ===============================================*/
    $("body").scrollspy({
      target: ".navbar-collapse",
      offset: 200,
    });
    $("a.smooth-menu").on("click", function (event) {
      var $anchor = $(this);
      var headerH = "75";
      $("html, body")
        .stop()
        .animate(
          {
            scrollTop: $($anchor.attr("href")).offset().top - headerH + "px",
          },
          1500,
          "easeInOutExpo"
        );
      event.preventDefault();
    });

    /* ==================================================
            # Banner Animation
        ===============================================*/
    function doAnimations(elems) {
      //Cache the animationend event in a variable
      var animEndEv = "webkitAnimationEnd animationend";
      elems.each(function () {
        var $this = $(this),
          $animationType = $this.data("animation");
        $this.addClass($animationType).one(animEndEv, function () {
          $this.removeClass($animationType);
        });
      });
    }

    //Variables on page load
    var $immortalCarousel = $(".animate_text"),
      $firstAnimatingElems = $immortalCarousel
        .find(".item:first")
        .find("[data-animation ^= 'animated']");
    //Initialize carousel
    $immortalCarousel.carousel();
    //Animate captions in first slide on page load
    doAnimations($firstAnimatingElems);
    //Other slides to be animated on carousel slide event
    $immortalCarousel.on("slide.bs.carousel", function (e) {
      var $animatingElems = $(e.relatedTarget).find(
        "[data-animation ^= 'animated']"
      );
      doAnimations($animatingElems);
    });

    /* ==================================================
            # Youtube Video Init
         ===============================================*/
    $(".player").mb_YTPlayer();

    /* ==================================================
            # imagesLoaded active
        ===============================================*/
    $("#portfolio-grid,.blog-masonry").imagesLoaded(function () {
      /* Filter menu */
      $(".mix-item-menu").on("click", "button", function () {
        var filterValue = $(this).attr("data-filter");
        $grid.isotope({
          filter: filterValue,
        });
      });

      /* filter menu active class  */
      $(".mix-item-menu button").on("click", function (event) {
        $(this).siblings(".active").removeClass("active");
        $(this).addClass("active");
        event.preventDefault();
      });

      /* Filter active */
      var $grid = $("#portfolio-grid").isotope({
        itemSelector: ".pf-item",
        percentPosition: true,
        masonry: {
          columnWidth: ".pf-item",
        },
      });

      /* Filter active */
      $(".blog-masonry").isotope({
        itemSelector: ".blog-item",
        percentPosition: true,
        masonry: {
          columnWidth: ".blog-item",
        },
      });
    });

    /* ==================================================
            # Fun Factor Init
        ===============================================*/
    $(".timer").countTo();
    $(".fun-fact").appear(
      function () {
        $(".timer").countTo();
      },
      {
        accY: -100,
      }
    );

    /* ==================================================
            # Magnific popup init
         ===============================================*/
    $(".popup-link").magnificPopup({
      type: "image",
      // other options
    });

    $(".popup-gallery").magnificPopup({
      type: "image",
      gallery: {
        enabled: true,
      },
      // other options
    });

    $(".popup-youtube, .popup-vimeo, .popup-gmaps").magnificPopup({
      type: "iframe",
      mainClass: "mfp-fade",
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false,
    });

    $(".magnific-mix-gallery").each(function () {
      var $container = $(this);
      var $imageLinks = $container.find(".item");

      var items = [];
      $imageLinks.each(function () {
        var $item = $(this);
        var type = "image";
        if ($item.hasClass("magnific-iframe")) {
          type = "iframe";
        }
        var magItem = {
          src: $item.attr("href"),
          type: type,
        };
        magItem.title = $item.data("title");
        items.push(magItem);
      });

      $imageLinks.magnificPopup({
        mainClass: "mfp-fade",
        items: items,
        gallery: {
          enabled: true,
          tPrev: $(this).data("prev-text"),
          tNext: $(this).data("next-text"),
        },
        type: "image",
        callbacks: {
          beforeOpen: function () {
            var index = $imageLinks.index(this.st.el);
            if (-1 !== index) {
              this.goTo(index);
            }
          },
        },
      });
    });

    /* ==================================================
            # Testimonials Carousel
         ===============================================*/
    $(".testimonials-carousel").owlCarousel({
      loop: false,
      nav: true,
      margin: 30,
      dots: false,
      autoplay: false,
      items: 1,
      navText: ["<i class='arrow_left'></i>", "<i class='arrow_right'></i>"],
    });

    /* ==================================================
            # services Carousel
         ===============================================*/
    $(".services-carousel").owlCarousel({
      loop: false,
      nav: true,
      margin: 30,
      dots: false,
      autoplay: false,
      responsive: {
        0: {
          items: 1,
          dots: true,
          nav: false,
        },
        1200: {
          items: 3,
        },
        1601: {
          items: 3,
        },
        1900: {
          items: 3,
        },
      },
      navText: ["<i class='arrow_left'></i>", "<i class='arrow_right'></i>"],
    });

    /* ==================================================
            # Projects Carousel
         ===============================================*/
    $(".projects-carousel").owlCarousel({
      loop: true,
      center: true,
      margin: 70,
      nav: true,
      navText: [
        "<i class='fa fa-angle-left'></i>",
        "<i class='fa fa-angle-right'></i>",
      ],
      dots: false,
      autoplay: true,
      responsive: {
        0: {
          items: 1,
        },
        1200: {
          items: 1,
          stagePadding: 200,
        },
        1601: {
          items: 1,
          stagePadding: 250,
        },
        1900: {
          items: 1,
          stagePadding: 400,
        },
      },
    });

    /* ==================================================
            # We Do Carousel
         ===============================================*/
    $(".wedo-carousel").owlCarousel({
      loop: true,
      nav: false,
      margin: 30,
      dots: true,
      autoplay: true,
      items: 1,
      navText: [
        "<i class='fa fa-angle-left'></i>",
        "<i class='fa fa-angle-right'></i>",
      ],
      responsive: {
        1300: {
          stagePadding: 50,
          items: 2,
        },
      },
    });

    /* ==================================================
            # Partner Carousel
         ===============================================*/
    $(".partner-carousel").owlCarousel({
      loop: false,
      margin: 30,
      nav: false,
      navText: [
        "<i class='fa fa-angle-left'></i>",
        "<i class='fa fa-angle-right'></i>",
      ],
      dots: false,
      autoplay: true,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 2,
        },
        1000: {
          items: 3,
        },
      },
    });

    /* ==================================================
            # Services Carousel
         ===============================================*/
    $(".thumb-services-carousel").owlCarousel({
      loop: false,
      margin: 30,
      nav: false,
      navText: [
        "<i class='fa fa-angle-left'></i>",
        "<i class='fa fa-angle-right'></i>",
      ],
      dots: true,
      autoplay: true,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 2,
        },
        1000: {
          items: 3,
        },
      },
    });

    /* ==================================================
            Contact Form Validations
        ================================================== */
    $(".contact-form").each(function () {
      var formInstance = $(this);
      formInstance.submit(function () {
        var action = $(this).attr("action");

        $("#message").slideUp(750, function () {
          $("#message").hide();

          $("#submit")
            .after('<img src="assets/img/ajax-loader.gif" class="loader" />')
            .attr("disabled", "disabled");

          $.post(
            action,
            {
              name: $("#name").val(),
              email: $("#email").val(),
              phone: $("#phone").val(),
              comments: $("#comments").val(),
            },
            function (data) {
              document.getElementById("message").innerHTML = data;
              $("#message").slideDown("slow");
              $(".contact-form img.loader").fadeOut("slow", function () {
                $(this).remove();
              });
              $("#submit").removeAttr("disabled");
            }
          );
        });
        return false;
      });
    });
  }); // end document ready function



  /* ==================================================
        Preloader Init
     ===============================================*/
  function loader() {
    $(window).on("load", function () {
      $("#vaanam-preloader").addClass("loaded");
      $("#loading").fadeOut(500);
      // Una vez haya terminado el preloader aparezca el scroll

      if ($("#vaanam-preloader").hasClass("loaded")) {
        // Es para que una vez que se haya ido el preloader se elimine toda la seccion preloader
        $("#preloader")
          .delay(900)
          .queue(function () {
            $(this).remove();
          });
      }
    });
  }
  loader();

  var btn = $("#topbutton");

  $(window).scroll(function () {
    if ($(window).scrollTop() > 300) {
      btn.addClass("show");
    } else {
      btn.removeClass("show");
    }
  });

  btn.on("click", function (e) {
    e.preventDefault();
    $("html, body").animate({ scrollTop: 0 }, "300");
  });
})(jQuery); // End jQuery


