import { Header } from "../Home/Header";
import bannerrightshape from "../../../Assets/img/banner/1.jpg";
import React from "react";

import { Footer } from "../../Client/Home/Footer";
import ScrollToTop from "react-scroll-to-top";

import ApplyForm from "./ApplyForm";


function Apply_H1B(props) {
    
 return (
        <>
            <Header />
            <div
                className="breadcrumb-area text-center shadow dark text-light bg-cover"
                style={{
                    backgroundImage: `url(${bannerrightshape})`,
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <h1>NEW H1B PETITIONS 2024</h1>
                            <ul className="breadcrumb">
                         <li className="active">Submit the below application now to ensure swift processing and timely inclusion in the lottery.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrumb */}
            {/* Start Career 
     ============================================= */}
          <ApplyForm/>
            <ScrollToTop smooth color="#2b5690" />
            <Footer />
        </>
    );
}
export default Apply_H1B