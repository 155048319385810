import React, { useState, useEffect } from "react";
import "jquery";
import $ from "jquery";
// import { Splide, SplideSlide } from "@splidejs/react-splide";
// import "@splidejs/react-splide/css";
import Carousel from "react-multi-carousel";
import ProjectServices from "../../../Services/ProjectServices";

var ProjectServicesObj = new ProjectServices();
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
export const Projectsarea = () => {
  const [ProjectDetails, setProjectDetails] = useState([]);
  useEffect(() => {
    ProjectServicesObj.Client_GetProjectList().then((res) => {
      if (res.data != null && res.data.length > 0) {
        setProjectDetails([...res.data]);
      }
    });
  }, []);

  return (
    <>
      {/* Start Projects Area
      ============================================= */}
      <div className="projects-area carousel-shadow overflow-hidden default-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="site-heading text-center">
                <h4>Our Products</h4>
                <h2>Latest Products Showcase</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fill">
          <div className="projects-carousel owl-carousel owl-theme">
            <Carousel
              arrows={true}
              autoPlay={true}
              infinite={true}
              showDots={false}
              autoPlaySpeed={2500}
              responsive={responsive}

            // removeArrowOnDeviceType={[
            //   "tablet",
            //   "mobile",
            //   "superLargeDesktop",
            //   "desktop",
            // ]}
            >
              {ProjectDetails != null && ProjectDetails.length > 0 ? (
                ProjectDetails.map((v, i) => {
                  return (
                    <div className="single-item projects">
                      <div className="row">
                        <div
                          className="col-lg-7 thumb"
                        // style={{
                        //   backgroundImage: `url(${process.env.REACT_APP_API_IMAGEPATH + v.imageUrl
                        //     })`,
                        // }}
                        >
                          <img src={process.env.REACT_APP_API_IMAGEPATH + v.imageUrl}></img>
                        </div>
                        <div className="col-lg-5 info">
                          <div className="content projectcontent">
                            <a target="_blank" href={v.websiteUrl}>
                              <img
                                className="projectlogo"
                                src={
                                  process.env.REACT_APP_API_IMAGEPATH + v.logoUrl
                                }
                              />
                            </a>
                            <span>{v.name}</span>

                            <p>{v.description}</p>
                            <h6 className="projlink">
                              Web Site:{" "}
                              <a title={v.websiteUrl} target="_blank" href={v.websiteUrl}>
                                {v.websiteUrl}
                              </a>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
              {/* End Single Item */}
            </Carousel>
          </div>
        </div>
      </div>
      {/* End Projects Area */}
    </>
  );
};
