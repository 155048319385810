import about from "../../../Assets/img/about/1.png";
import nasscomimag from "../../../Assets/img/nasscom.png";
import isoImg from "../../../Assets/img/ISO 27001 - GR - UAF.jpg";
import isoImg1 from "../../../Assets/img/ISO 27001 - GR.jpg";
import isopdf from "../../../Assets/img/Vaanam Technologies Pvt Ltd._GR24_UAF-Cert (1) (1)_page-0001.jpg"

export const About = () => (
  <>
    {/* Start About 
      ============================================= */}
    <div className="about-us-area default-padding bg-gray">
      <div className="container">
        <div className="about-items">
          <div className="row align-center">
            <div className="col-lg-6">
              <div className="thumb wow fadeInUp">
                <img src={about} alt="Thumb" />
                <h2 className="wow fadeInLeft" data-wow-delay="300ms">
                  <strong>10</strong> years of <br /> business experience
                </h2>
              </div>
            </div>
            <div className="col-lg-6 info wow fadeInRight">
              <h2>
                Welcome to <br />
                Vaanam Technologies Pvt Ltd
              </h2>
              <h4>We Combine Technology with Business Requirements</h4>
              <p>
                Vaanam is a Software Development, Consulting, and Outsourcing
                Company was founded in Chicago US in 2011. Our dedicated ODC in
                Coimbatore, India established in 2012 with a pool of talented
                techies.
              </p>
              <p>
                Vaanam is an emerging leader in the Enterprise Applications and
                IT Services marketplace.
              </p>
              <a  target="_blank"
              data-bs-toggle="modal" data-bs-target="#addRowModal">
                <img
                  style={{
                    width: "300px",
                    marginBottom: "30px",
                    marginRight: "20px",
                  }}
                  src={isoImg}
                  alt="Thumb"
                  id="a3"
          className="ms-2"
                />
              </a>{" "}
              {/* <img style={{width:"90px",marginBottom:"30px"}} src={isoImg1} alt="Thumb" /> */}
              <img
                style={{ width: "160px", marginBottom: "30px" }}
                src={nasscomimag}
                alt="Thumb"
              />
              <div className="torch-red shadow bluebtn">
                <a
                  data-animation="animated zoomInUp"
                  // className="btn btn-theme effect btn-md"
                  className="btn btn-gradient-home effect btn-lg"
                  href={"/About"}
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* End About Area */}
    <div
        className="modal fade"
        id="addRowModal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
       aria-labelledby="addRowModalLabel"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header no-bd">
              <h5 className="modal-title"> ISO/IEC 27001:2022 (Information Security Management Systems)</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
            <img
                src={isopdf} 
                style={{ width: "100%", height: "auto" }}
                frameBorder="0"
              ></img>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
  </>
);
