import React, { useState, useEffect } from "react";
import EventServices from "../../../Services/EventServices";
import "jquery";
import $ from "jquery";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaTrashAlt } from "react-icons/fa";
var EventServicesObj = new EventServices();
function AddOrEditEvents(props) {
  const { eventID, refresh } = props;
  const [EventModel, setEventModel] = useState({});
  const [startDate, setStartDate] = useState(null);

  const [render, gridrender] = useState(false);
  const [images, setimages] = useState();
  const [error, setformError] = useState({
    event_title: "",
    description: "",
    event_image: "",
  });

  useEffect(() => {
    EventServicesObj.GetEdit(eventID).then((res) => {
      var data = res.data;
      setEventModel(data);
      if (eventID > 0) {
        const date = data.eventDate;
        setStartDate(new Date(date));
      } else {
        setStartDate(new Date());
      }
    });
  }, [eventID, refresh, render]);
  const cookies = new Cookies();
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 750,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  function DeleteEventImage(id) {
    EventServicesObj.DeleteImage(id).then((res) => {
      if (res.data == 1) {
        gridrender(!render);
      }
    });
  }
  const CloseModel = () => {
    document.getElementById("AddOrEditEvents").reset();
    setEventModel({});
    setimages("");
    setStartDate(new Date());
    setformError({
      event_title: "",
      description: "",
      event_image: "",
    });
  };
  const onKeyDown = (event) => {
    if (event.code === "Space") event.preventDefault();
  };
  function numberOnly(e) {
    var element = document.getElementById(e);
    element.value = element.value.replace(/[^0-9]/gi, "");
  }

  const eventtitlechange = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      myerror.event_title = "Event Title is Required";
    } else {
      myerror.event_title = "";
    }
    setformError({ ...myerror });
  };
  const eventimagechange = (e) => {
    var myerror = error;
    if (EventModel.eventID == null || EventModel.eventID == "") {
      if (e.target.value == null || e.target.value == "") {
        myerror.event_image = "Event Image is Required";
        setimages("");
      } else {
        setimages(e.target.files);
        myerror.event_image = "";
      }
      setformError({ ...myerror });
    }
    if (EventModel.eventID != null && EventModel.eventID != "") {
      if (EventModel.galleryImages == null) {
        if (e.target.value == null || e.target.value == "") {
          myerror.event_image = "Event Image is Required";
          setimages("");
        } else {
          setimages(e.target.files);
          myerror.event_image = "";
        }
      }
      setformError({ ...myerror });
    }
  };
  const descriptionchange = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      myerror.description = "Description is Required";
    } else {
      myerror.description = "";
    }
    setformError({ ...myerror });
  };

  var uploadfiles = { images: images };

  const onSubmit = (res) => {
    res.preventDefault();

    var myerror = error;

    var flag = true;

    var data = {
      SavedBY: parseInt(cookies.get("userID")),
      EventID: EventModel.eventID,
      EventDate: startDate.toLocaleDateString("pt-PT"),
      EventTitle: document.getElementById("eventtitle").value,
      EventImageUrl: null,
      Description: document.getElementById("description").value,

      EventOrder: parseInt(document.getElementById("orderby").value),
    };

    if (data.EventTitle == null || data.EventTitle == "") {
      myerror.event_title = "Event Title is Required";
      flag = false;
    } else {
      myerror.event_title = "";
    }
    if (data.Description == null || data.Description == "") {
      myerror.description = "Description is Required";
      flag = false;
    } else {
      myerror.description = "";
    }
    if (EventModel.eventID == null || EventModel.eventID == "") {
      if (uploadfiles.images == null || uploadfiles.images == "") {
        myerror.event_image = "Event Image is Required";
        flag = false;
      } else {
        myerror.event_image = "";
      }
    }
    if (EventModel.eventID != null && EventModel.eventID != "") {
      if (EventModel.galleryImages == null) {
        if (uploadfiles.images == null || uploadfiles.images == "") {
          myerror.event_image = "Event Image is Required";
          flag = false;
        } else {
          myerror.event_image = "";
        }
      }
    }

    setformError({ ...myerror });
    if (flag == true) {
      const formData = new FormData();

      $.each($("#eventimages")[0].files, function (i, file) {
        formData.append("Images", file);
      });

      EventServicesObj.UploadFiles(formData).then((res) => {
        data.eventImageUrl = res.data;

        EventServicesObj.Create(data).then((res) => {
          if (res.data == 1) {
            $(".close").trigger("click");
            Toast.fire({
              icon: "success",
              title: "Save Success",
            });
            setTimeout(function () {
              window.location.reload();
            }, 750);
          } else {
            Toast.fire({
              icon: "error",
              title: "Failed to Save!",
            });
          }
        });
      });
    }
  };

  return (
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header no-bd">
          <h5 class="modal-title">
            <span class="fw-mediumbold">Add Event</span>
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
            data-dismiss="modal"
            onClick={CloseModel}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <form onSubmit={onSubmit} id="AddOrEditEvents" autocomplete="off">
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-group ">
                    <label>Event Date</label>

                    <DatePicker
                      id="eventdate"
                      className="form-control"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      dateFormat="dd-MM-yyyy"
                      Value={EventModel.eventDate}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group  ">
                    <label>
                      Event Title<span className="text-danger">*</span>
                    </label>

                    <input
                      type="text"
                      id="eventtitle"
                      className="form-control"
                      defaultValue={EventModel.eventTitle}
                      maxLength={200}
                      placeholder="Event Title"
                      name="eventtitle"
                      onChange={eventtitlechange}
                    />

                    <span className="text-danger">{error.event_title}</span>
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="form-group ">
                    <label>
                      Description<span className="text-danger">*</span>
                    </label>
                    {EventModel.eventID == null || EventModel.eventID == "" ? (
                      <textarea
                        id="description"
                        maxLength={2000}
                        className="form-control"
                        defaultValue={""}
                        placeholder="Description"
                        name="description"
                        onChange={descriptionchange}
                      />
                    ) : (
                      <textarea
                        id="description"
                        maxLength={2000}
                        className="form-control"
                        defaultValue={EventModel.description}
                        placeholder="Description"
                        name="description"
                        onChange={descriptionchange}
                      />
                    )}

                    <span className="text-danger">{error.description}</span>
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="form-group ">
                    <label>
                      Event Images
                      {EventModel.eventID == null ||
                        EventModel.eventID == "" ? (
                        <>
                          <span className="text-danger">*</span>&nbsp;
                          <span class="badge badge-danger">Max Img Size(1920x960)px, .JPEG, .jpg</span>
                        </>
                      ) : (
                        <></>
                      )}
                    </label>
                    <input
                      id="eventimages"
                      type="file"
                      className="form-control"
                      name="eventimages"
                      onChange={eventimagechange}
                      multiple
                      accept=".png, .jpg, .jpeg"
                    />

                    <span className="text-danger">{error.event_image}</span>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group ">
                    <label>Event Order</label>

                    <input
                      type="text"
                      id="orderby"
                      className="form-control"
                      maxLength={3}
                      placeholder="Event Order"
                      defaultValue={EventModel.eventOrder}
                      name="orderby"
                      onKeyDown={(event) => onKeyDown(event)}
                      onChange={(e) => numberOnly("orderby")}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group imagegrid">
                    {EventModel.galleryImages != null &&
                      EventModel.galleryImages.length > 0 ? (
                      EventModel.galleryImages.map((v, i) => {
                        return (
                          <div className="galleryimages">
                            <img
                              src={
                                process.env.REACT_APP_API_IMAGEPATH +
                                v.galleryImagePath
                              }
                            />
                            <div class="edit">
                              <a
                                onClick={() =>
                                  DeleteEventImage(v.galleryImageID)
                                }
                              >
                                {" "}
                                <FaTrashAlt />
                              </a>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>

              <div className="modal-footer no-bd">
                <button
                  type="submit"
                  id="addRowButton"
                  className="btn btn-primary"
                >
                  Add
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  onClick={CloseModel}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddOrEditEvents;
