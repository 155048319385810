import ServImg from "../../../Assets/img/banner/1.jpg";
import partner from "../../../Assets/img/about/1.jpg";
import { Header } from "../Home/Header";
import { Partnerarea } from "../Home/Partnerarea";
import { Footer } from "../Home/Footer";
import React, { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";
import $ from "jquery";
import isoImg from "../../../Assets/img/ISO 27001 - GR - UAF.jpg"
import nasscomimag from "../../../Assets/img/nasscom.png"
import isopdf from "../../../Assets/img/Vaanam Technologies Pvt Ltd._GR24_UAF-Cert (1) (1)_page-0001.jpg"

const About = () => {
  useEffect(() => {
    setTimeout(() => {
      $("#preloader").hide();
    }, 1000);
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div>
      <Header />
      {/* Start Breadcrumb 
    ============================================= */}
      <div
        className="breadcrumb-area text-center shadow dark text-light bg-cover"
        style={{ backgroundImage: `url(${ServImg})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>About Us</h1>
              <ul className="breadcrumb">
                <li>
                  <a href="/">
                    <i className="fas fa-home" /> Home
                  </a>
                </li>
                <li className="active">About Us</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumb */}
      {/* Start About 
    ============================================= */}
      <div className="about-us-area default-padding">
        <div className="container">
          <div className="about-items">
            <div className="row align-center">
              <div className="col-lg-12 info">
                <h2>About Vaanam Technologies Pvt Ltd</h2>
                <p>
                  Vaanam is a Software Development,
                  Consulting, and Outsourcing Company was founded in Chicago US
                  in 2011. Our dedicated ODC in Coimbatore, India established in
                  2012 with a pool of talented techies.
                </p>
                <p>
                  Vaanam is an emerging leader in the Enterprise Applications
                  and IT Services marketplace. With a strong focus on frameworks
                  for information availability and analysis, Vaanam has
                  developed Centers of Excellence for each of its service areas,
                  ranging from Cloud Applications to Mobiles Solutions and
                  Business Intelligence. Given this understanding and the
                  expertise of its employees, Vaanam Technologies has
                  consistently delivered high value solutions and support to its
                  customers.
                </p>
                <p>
                  Vaanam consultants have delivered thousands of hours of
                  business analytics, performance management, business
                  intelligence and data warehousing instruction at conferences,
                  seminars, educational institutions and major organizations in
                  a wide range of industries.
                </p>
                <p>
                  Vaanam has a focused vision in the design and development of
                  BI solutions/frame work, backed by the wide range of domain
                  experience including{" "}
                </p>
                <ul>
                  <li>Banking and Financial Services</li>
                  <li>Telecom, Healthcare</li>
                  <li>Manufacturing</li>
                  <li>Retail &amp; Consumer Products</li>
                  <li>Travel, Transportation &amp;</li>
                  <li>Hospitality </li>
                </ul>
                <br />
                <p>
                  with Business Intelligence tools and technologies from various
                  vendors (including but not limited to Oracle, SAP, IBM and
                  Microsoft).
                </p>
                <a  target="_blank"
              data-bs-toggle="modal" data-bs-target="#addRowModal">
                <img
                  style={{
                    width: "300px",
                    marginBottom: "30px",
                    marginRight: "20px",
                  }}
                  src={isoImg}
                  alt="Thumb"
                  id="a3"
          className="ms-2"
                />
              </a>{" "}


                <img style={{width:"160px",marginBottom:"30px"}} src={nasscomimag} alt="Thumb" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area */}
      {/* Star About Area
    ============================================= */}
      <div className="about-area bg-dark overflow-hidden text-light relative">
        {/* Fixed Shape */}
        <div
          className="fixed-shape"
          style={{ backgroundImage: "url(img/shape/8.png)" }}
        />
        {/* End Fixed Shape */}
        <div className="container">
          <div className="about-items">
            <div className="row">
              <div
                className="col-lg-6 thumb order-lg-last wow fadeInUp"
                style={{ backgroundImage: `url(${partner})` }}
              >
                <div className="successr-ate">
                  <div className="icon">
                    <i className="flaticon-target" />
                  </div>
                  <div className="content">
                    <h2>100%</h2>
                    <span>Success Rate</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 info wow fadeInDown">
                <h4> Vaanam Technologies Pvt Ltd</h4>
                <h2>Mission</h2>
                <p>
                  At Vaanam, our mission is to enable our
                  clients to have full confidence that their products/services
                  provide them with uninterrupted business
                  continuity/productivity.
                </p>
                <p>
                  To meet our mission, we strive to maintain technical
                  leadership and methodologies to meet/exceed customer
                  expectation in quality and cost aspects.
                </p>
                <p>
                  Vaanam is aimed at designing and implementing solutions that
                  helps our client to be "Ahead of Curve" in their business.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area */}
      {/* Star Partner Area
    ============================================= */}

      <Partnerarea />
      <ScrollToTop smooth color="#2b5690" />
      {/* End Partner Area */}
      <Footer />
      <div
        className="modal fade"
        id="addRowModal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
       aria-labelledby="addRowModalLabel"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header no-bd">
              <h5 className="modal-title"> ISO/IEC 27001:2022 (Information Security Management Systems)</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
            <img
                src={isopdf} 
                style={{ width: "100%", height: "auto" }}
                frameBorder="0"
              ></img>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  );
};
export default About;
