import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ClientsServices from "../../../Services/ClientsServices";

var ProjectServicesObj = new ClientsServices();

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 7,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

export const Partnerarea = () => {
  const [ProjectDetails, setProjectDetails] = useState([]);
  useEffect(() => {
    ProjectServicesObj.GetClientList().then((res) => {
      if (res.data != null && res.data.length > 0) {
        setProjectDetails([...res.data]);
      }
    });
  }, []);


  const containerStyle = {
    textAlign: 'center',
    marginBottom: '20px'
  };


  return (
    <>
      {/* Star Partner Area
//     ============================================= */}
      <div className="partner-area overflow-hidden text-light">
        <div className="container">
       
          <div className="item-box">
          
            <div className="row ">
            <div  className="col-lg-12 info\">
            <h4 style={containerStyle}>
                  We're Trusted by Many Professional Customers
                </h4>
              </div>

              <div className="col-lg-12 clients">
             
                <div className="partner-carousel owl-carousel owl-theme text-center">
                  <Carousel
                  autoPlay={true}
                    infinite={true}
          
                    autoPlaySpeed={2500}
                    responsive={responsive}
                    showDots={true}
                    removeArrowOnDeviceType={[
                      "tablet",
                      "mobile",
                      "superLargeDesktop",
                      "desktop",
                    ]}
                  >
                    {ProjectDetails != null && ProjectDetails.length > 0 ? (
                      ProjectDetails.map((v, i) => {
                        return (
                          <div>
                            <div className="single-item clients">
                              <a>
                                <img
                                  src={
                                    process.env.REACT_APP_API_IMAGEPATH +
                                    v.logoUrl
                                  }
                                  alt="Clients"
                                />
                              </a>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Partner Area */}
    </>
  );
};
