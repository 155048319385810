import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Enterprise from "../../../Assets/img/Solutions/Enterprise.png";
import EnterpriseApplication from "../../../Assets/img/Solutions/EnterpriseApplication.png";
import Mobility from "../../../Assets/img/Solutions/Mobility.png";
import Business from "../../../Assets/img/Solutions/BusinessInteligence.png";
import Reality from "../../../Assets/img/Solutions/Reality.png";
import Window from "../../../Assets/img/Solutions/Microsoft_icon.png";
import AIML from "../../../Assets/img/Solutions/AI_mL.png";
import MobBackgroundImage from "../../../Assets/img/SolutionBackgorundImg/MbilitySerImage.jpg";
import EnterpriseBackgroundImage from "../../../Assets/img/SolutionBackgorundImg/EnterpriseImg.jpg";
import BussinessBackgroundImage from "../../../Assets/img/SolutionBackgorundImg/Business-Intelligence.jpeg";
import RealityBackgroundImage from "../../../Assets/img/SolutionBackgorundImg/Reality_Image.jpg";
import AIbackgroundImage from "../../../Assets/img/SolutionBackgorundImg/Al_Ml.jpg";
import MicrosoftBackgroundImage from "../../../Assets/img/SolutionBackgorundImg/microsoft.jpg";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const MicrosoftStyle = {
  backgroundImage: `url(${MicrosoftBackgroundImage})`,
};
const mobStyle = {
  backgroundImage: `url(${MobBackgroundImage})`,
};
const enterPriseStyle = {
  backgroundImage: `url(${EnterpriseBackgroundImage})`,
};
const bussinessStyle = {
  backgroundImage: `url(${BussinessBackgroundImage})`,
};

const RealityStyle = {
  backgroundImage: `url(${RealityBackgroundImage})`,
};

const AIStyle = {
  backgroundImage: `url(${AIbackgroundImage})`,
};
export const Services = () => (
  <>
    {/* Star Services Area
    ============================================= */}
    <div className="thumb-services-area inc-thumbnail default-padding bottom-less">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="site-heading text-center">
              <h4 className="Title">Our Services</h4>
              <h2>What We Bring To You</h2>
              <div className="devider" />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="services-items text-center">
          <div className="row">
            <div className="services-carousel owl-carousel owl-theme">
              <Carousel
                autoPlay={true}
                infinite={true}
                autoPlaySpeed={2500}
                responsive={responsive}
                showDots={true}
                removeArrowOnDeviceType={[
                  "tablet",
                  "mobile",
                  "superLargeDesktop",
                  "desktop",
                ]}
              >
                {/* Enterprise */}
                <div className="col-lg-12 col-md-12 single-item">
                  <div className="item" style={enterPriseStyle}>
                    <div className="info">
                      <i class="flaticon-">
                        {" "}
                        <img
                          class="solution-logo"
                          src={EnterpriseApplication}
                        />
                      </i>
                      <h4>Enterprise Application</h4>
                      <p>
                        The way of managing the applications in any enterprise
                        is rapidly changing. They need to respond quickly in
                        order to remain competitive
                      </p>
                      <div className="bottom">
                        <a href="/Enterprise"><i className="fas fa-arrow-right" /> Read More</a>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Microsoft*/}
                <div className="col-lg-12 col-md-12 single-item">
                  <div className="item" style={MicrosoftStyle}>
                    <div className="info">
                      <i class="flaticon-">
                        {" "}
                        <img class="window-logo" src={Window} />
                      </i>
                      <h4>Microsoft Services</h4>
                      <p>
                        Vaanam provides a wide range of
                        Microsoft Technology services and expertise to support
                        all client requirements.
                      </p>
                      <div className="bottom">
                        <a href="/MicrosoftServices"><i className="fas fa-arrow-right" /> Read More</a>
                      </div>
                    </div>
                  </div>
                </div>

                {/* AI and ML Services */}
                <div className="col-lg-12 col-md-12 single-item">
                  <div className="item" style={AIStyle}>
                    <div className="info" >
                      <i class="flaticon-">
                        {" "}
                        <img class="Mobility-logo" src={AIML} style={{ width: '50px' }} />
                      </i>
                      <h4>AI and ML Services</h4>
                      <p>
                        Artificial intelligence has brought a change in the operations of industry, driven by a new form of interaction between man and machine.                      </p>
                      <div className="bottom">
                        <a href="AIML"><i className="fas fa-arrow-right" /> Read More</a>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Mobility */}
                <div className="col-lg-12 col-md-12 single-item">
                  <div className="item" style={mobStyle}>
                    <div className="info">
                      <i class="flaticon-">
                        {" "}
                        <img class="Mobility-logo" src={Mobility} />
                      </i>
                      <h4>Mobility Services</h4>
                      <p>
                        The way of managing the applications in any enterprise
                        is rapidly changing. They need to respond quickly in
                        order to remain competitive.
                      </p>
                      <div className="bottom">
                        <a href="/Mobility"><i className="fas fa-arrow-right" /> Read More</a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Business Intellegence */}
                <div className="col-lg-12 col-md-12 single-item">
                  <div className="item" style={bussinessStyle}>
                    <div className="info">
                      <i class="flaticon-">
                        {" "}
                        <img class="Mobility-logo" src={Business} style={{ width: '50px' }} />
                      </i>
                      <h4>Business Intellegence</h4>
                      <p>
                        The best-of-breed technology from leading technology
                        vendors with our proven methodologies to provide a
                        unique solution.
                      </p>
                      <div className="bottom">
                        <a href="/BusinessIntellegence"><i className="fas fa-arrow-right" /> Read More</a>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Reality Technologies */}
                <div className="col-lg-12 col-md-12 single-item">
                  <div className="item" style={RealityStyle}>
                    <div className="info">
                      <i class="flaticon-">
                        {" "}
                        <img class="Mobility-logo" src={Reality} style={{ width: '50px' }} />
                      </i>
                      <h4>Reality Technologies</h4>
                      <p>
                        The Internet of Things (IoT) is a system of interrelated
                        computing devices, mechanical and digital machines,
                        objects, animals or people.
                      </p>
                      <div className="bottom">
                        <a href="/Reality"><i className="fas fa-arrow-right" /> Read More</a>
                      </div>
                    </div>
                  </div>
                </div>


              </Carousel>
            </div>
          </div>

        </div>
      </div>
    </div >
    {/* End Services Area */}
  </>
);
