import React from "react";
import AxiosServices from "./AxiosServices";

const Auth= new AxiosServices();

class TermsServices{
  Create = async (data) => {
        return Auth.post(`Terms/Create`, data);
      };
  GetTermsAdmin = async () => {
        return Auth.get(`Terms/Terms_GetTermsDetails`);
      };

  GetTermsClient = async () => {
        return Auth.get(`Terms/Terms_GetCLient`);
      };
}

export default TermsServices;