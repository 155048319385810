import React from "react";
import "../../../Assets/css/bootstrap.min.css";
import "../../../Assets/css/style.css";

// import "../../../../node_modules/bootstrap/dist/css/bootstrap.css";
import "../../../Assets/css/font-awesome.min.css";
import "../../../Assets/css/themify-icons.css";
import "../../../Assets/css/elegant-icons.css";
import "../../../Assets/css/magnific-popup.css";
import "../../../Assets/css/owl.carousel.min.css";
import "../../../Assets/css/owl.theme.default.min.css";
import "../../../Assets/css/animate.css";
import "../../../Assets/css/bootsnav.css";
// import "../../../Assets/css/style.css";
import "../../../Assets/css/responsive.css";
import vaanam from "../../../Assets/img/Vaanam.png";
import Cookies from "universal-cookie";
// import "bootstrap";
// import "bootstrap/dist/css/bootstrap.css";

import "bootstrap/dist/js/bootstrap.js";
import "../../../Assets/js/bootsnav.js";
import Logout from "../../../Assets/img/logout.png";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
export default function HeaderDashboard() {
  const navigate = useNavigate();
  const cookies = new Cookies();
  function logout() {
    localStorage.removeItem("token");
    cookies.remove("emailID");
    cookies.remove("userID");
    cookies.remove("userName");
    cookies.remove("token");
    window.location.href = "/Admin";
  }
  const DropdownView = (val) => {

    var ClassVal = "." + val;
    $(ClassVal).css("display", "block");
  };
  const DropdownLeave = (val) => {

    var ClassVal = "." + val;
    $(ClassVal).css("display", "none");
  };
  return (
    <>
      <header id="home" className="dashboard">
        {/* Start Navigation */}
        <nav className="navbar navbar-default navbar-sticky bootsnav">
          <div className="container-full">
            {/* Start Header Navigation */}
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target="#navbar-menu"
              >
                <i className="fa fa-bars" />
              </button>
              <a className="navbar-brand" href="/DashboardView">
                <img src={vaanam} className="logo" alt="Logo" />
              </a>
            </div>
            {/* End Header Navigation */}
            {/* Collect the nav links, forms, and other content for toggling */}
            <div
              className="collapse navbar-collapse"
              id="navbar-menu"
              style={{ display: "block" }}
            >
              <ul
                className="nav navbar-nav navbar-center navdirection"
                data-in="#"
                data-out="#"
              >
                <li>
                  <a href="/Dashboardview">Home</a>
                </li>
                <li
                  className="dropdown"
                  onMouseEnter={() => DropdownView("ddm")}
                  ontouchstart={() => DropdownView("ddm")}
                  onMouseLeave={() => DropdownLeave("ddm")}
                >
                  <a
                    href="#"
                    className="dropdown-toggle active"
                    data-toggle="dropdown"
                  >
                    Company
                  </a>
                  <ul className="dropdown-menu ddm">
                    <li>
                      <a href="/HomeBanner">Home Banners</a>
                    </li>
                    <li>
                      <a href="/AdminCareer">Careers</a>
                    </li>
                    <li>
                      <a href="/ContactListTable">Inquiries</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="/Projects">Products</a>
                </li>
                <li
                  className="dropdown"
                  onMouseEnter={() => DropdownView("ddc")}
                  ontouchstart={() => DropdownView("ddc")}
                  onMouseLeave={() => DropdownLeave("ddc")}
                >
                  <a
                    href="#"
                    className="dropdown-toggle active"
                    data-toggle="dropdown"
                  >
                    Clients
                  </a>
                  <ul className="dropdown-menu ddc">
                    <li>
                      <a href="/clients">Clients</a>
                    </li>
                    <li>
                      <a href="/Testimonial">Testimonials</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="/Events">Events</a>
                </li>
                <li
                  className="dropdown"
                  onMouseEnter={() => DropdownView("ddp")}
                  ontouchstart={() => DropdownView("ddp")}
                  onMouseLeave={() => DropdownLeave("ddp")}
                >
                  <a
                    href="#"
                    className="dropdown-toggle active"
                    data-toggle="dropdown"
                  >
                    Privacy & Terms
                  </a>
                  <ul className="dropdown-menu ddp">
                    <li>
                      <a href="/AdminPrivacy">Privacy & Policy</a>
                    </li>
                    <li>
                      <a href="/AdminTerms"> Terms & Conditions</a>
                    </li>
                    <li>
                      <a href="/AdminFaq">FAQ </a>
                    </li>
                  </ul>
                </li>
                <li
                  className="dropdown profiledd"
                  onMouseEnter={() => DropdownView("ddl")}
                  ontouchstart={() => DropdownView("ddl")}
                  onMouseLeave={() => DropdownLeave("ddl")}
                >
                  <a href="#" className="active" data-toggle="dropdown">
                    {" "}
                    <div className="profilename">
                      <i className="fas fa-user" />
                      &nbsp;
                      <label
                        className="usernameicon"
                        title={cookies.get("userName")}
                      >
                        {cookies.get("userName")}
                      </label>
                    </div>
                  </a>
                  <ul className="dropdown-menu ddl">
                    <li>
                      <a href="/ChangePassword">
                        <i class="fa fa-key" aria-hidden="true"></i> Change
                        Password
                      </a>
                    </li>
                    <li>
                      <a onClick={() => logout()}>
                        <i class="fas fa-sign-out-alt"></i> Log out
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            {/* /.navbar-collapse */}
          </div>
        </nav>
        {/* End Navigation */}
      </header>
      {/* End Header */}
    </>
  );
}
