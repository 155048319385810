import React, { useEffect } from "react";
import { Header } from "../../Client/Home/Header";
import bannerrightshape from "../../../Assets/img/banner/1.jpg";
import Microsoft from "../../../Assets/img/Microsoft.png";
import Agile from "../../../../src/Assets/img/agile-logo.png";
import Scrum from "../../../Assets/img/Scrum.png";
import UiUx from "../../../Assets/img/Ui-Ux.png";
import AspDotNet from "../../../Assets/img/AspDotNet.jpeg";
import silverlight from "../../../Assets/img/silverlight.jpeg";
import dotnet from "../../../Assets/img/dotnet.png";
import WindowsPhone from "../../../Assets/img/windows-phone.png";
import WebApi from "../../../Assets/img/WebApi.png";
import Azure from "../../../Assets/img/Microsoft Azure.png";
import Crm from "../../../Assets/img/Crm.jpeg";
import DotNetSupport from "../../../Assets/img/NET-Framework-Logo.png";
import Umbraco from "../../../Assets/img/Umbraco.png";
import DotNetDev from "../../../Assets/img/DotNetDev.png";
import About from "../../../Assets/img/about/1.jpg";
import powerBI from "../../../Assets/img/PowerBI-Logo.jpg";
import Telerik from "../../../Assets/img/Telerik_OG.jpg";
import { Footer } from "./Footer";
import ScrollToTop from "react-scroll-to-top";
import $ from "jquery";

function MicrosoftServices() {
  useEffect(() => {
    setTimeout(() => {
      $("#preloader").hide();
    }, 1000);
  });
  return (
    <div>
      <Header />
      <div
        className="breadcrumb-area text-center shadow dark text-light bg-cover"
        style={{
          backgroundImage: `url(${bannerrightshape})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>Microsoft Services</h1>
              <ul className="breadcrumb">
                <li>
                  <a href="/">
                    <i className="fas fa-home" /> Home
                  </a>
                </li>
                <li className="active">Microsoft Services</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="services-details-area default-padding">
        <div className="container">
          <div className="services-details-items">
            <div className="row">
              <div className="col-lg-8 services-single-content">
                <h2>Microsoft Services</h2>
                <h4>Microsoft Technologies</h4>
                <p>
                  Vaanam provides a wide range of Microsoft
                  Technology services and expertise to support all client
                  requirements. Our services spans from conceptualization, proof
                  of concept, architecture, design, development, testing, test
                  automation, deployment, Operations support and Maintenance. We
                  have utilized Microsoft technology to implement industry
                  specific solutions that are conceived and developed to provide
                  a competitive edge to the client’s business.
                </p>
                <p>
                  Vaanam has been involved with .NET programming right from its
                  inception. Our expertise has range from initial 1.0 version of
                  .NET to latest MVC based frameworks. With our ability to
                  uniquely combine modern AJAX and JS based frameworks with
                  ASP.NET, we have been able to successfully deliver high end
                  .NET based web software applications.
                </p>
                <img src={Microsoft} />
              </div>
              <div className="col-lg-4 services-sidebar">
                <div className="single-widget services-list">
                  <h4 className="widget-title">Services List</h4>
                  <div className="content">
                    <ul>
                      <li>
                        <a href="/Enterprise">Enterprise Application</a>
                      </li>
                      <li className="current-item">
                        <a href="/MicrosoftServices">Microsoft Services</a>
                      </li>
                      <li>
                        <a href="/AIML" style={{ textTransform: "initial" }}>
                          AI and ML Services
                        </a>
                      </li>
                      <li>
                        <a href="/Mobility">Mobility Services</a>
                      </li>
                      <li>
                        <a href="/BusinessIntellegence">
                          Business Intelligence
                        </a>
                      </li>
                      <li>
                        <a href="/Reality">Reality Technologies</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="single-widget quick-contact text-light"
                  style={{ backgroundImage: `url(${About})` }}
                >
                  <div className="content">
                    <i className="fas fa-phone" />
                    <h4>Need any help?</h4>

                    <h2>+91 422 256 7626</h2>
                  </div>
                </div>
              </div>
              {/* Vaanam Technologies offers its expertise in .NET development under these areas */}
            </div>
          </div>
        </div>
      </div>
      <div className="services-area bg-gray default-padding bottom-less">
        {/* Fixed Shape */}
        {/* <div className="fixed-shape" style={{ backgroundImage: 'url(assets/img/shape/12.png)' }} /> */}
        {/* Fixed Shape */}
        <div className="container">
          <div className="services-heading">
            <div className="row">
              <div className="col-lg-7 left-info">
                {/* <h4>Vaanam Technologies offers its expertise in .NET development under these areas</h4> */}
                <h4> Vaanam offers</h4>
                <p>Its expertise in .NET development under these areas</p>
              </div>
            </div>
          </div>
          {/* Start Services Items */}
          <div className="services-content text-center">
            <div
              className="row"
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              {/* powerBI */}
              <div className="single-item col-lg-3 col-md-6">
                <div className="item">
                  {/* <i className="flaticon-investment" /> */}
                  {/* <i className="AspDotNet" /> */}
                  <div className="microsoftlogos">
                    <img src={powerBI} />
                  </div>
                  <h4>
                    <a className="chgcolor">
                      Microsoft PowerBI Solution
                    </a>
                  </h4>
                </div>
              </div>

              {/* Azure */}
              <div className="single-item col-lg-3 col-md-6">
                <div className="item">
                  <div className="microsoftlogos">
                    <img src={Azure} />
                  </div>
                  <h4>
                    <a className="chgcolor">Microsoft Azure Solutions</a>
                  </h4>
                </div>
              </div>


              {/* Dynamites */}
              <div className="single-item col-lg-3 col-md-6">
                <div className="item">
                  <div className="microsoftlogos">
                    <img src={Crm} />
                  </div>
                  <h4>
                    <a className="chgcolor">Microsoft Dynamics CRM Solutions</a>
                  </h4>
                </div>
              </div>
              {/* Application support */}
              <div className="single-item col-lg-3 col-md-6">
                <div className="item">
                  <div className="microsoftlogos">
                    <img src={DotNetSupport} />
                  </div>
                  <h4>
                    <a className="chgcolor">.Net Applications Support</a>
                  </h4>
                </div>
              </div>


              {/* Umbraco */}
              <div
                className="single-item col-lg-3 col-md-6"
                style={{ height: "20rem" }}
              >
                <div className="item" style={{ height: "20rem" }}>
                  {/* <i className="flaticon-analysis" /> */}
                  <div className="microsoftlogos">
                    <img src={Umbraco} />
                  </div>
                  <h4>
                    <a className="chgcolor">
                      .NET Content Management Solutions (Umbraco, DotNetNuke)
                      {/* (Umbraco, DotNetNuke) */}
                    </a>
                  </h4>
                </div>
              </div>

              {/* Telerik */}
              <div
                className="single-item col-lg-3 col-md-6"
                style={{ height: "20rem" }}
              >
                <div className="item" style={{ height: "20rem" }}>
                  {/* <i className="flaticon-analysis" /> */}
                  <div className="microsoftlogos">
                    <img src={Telerik} />
                  </div>
                  <h4>
                    <a className="chgcolor">
                      Telerik Solution
                      {/* (Umbraco, DotNetNuke) */}
                    </a>
                  </h4>
                </div>
              </div>

              {/* webApi */}
              <div className="single-item col-lg-3 col-md-6">
                <div className="item">
                  {/* <i className="flaticon-analysis" /> */}
                  <div className="microsoftlogos">
                    <img src={WebApi} />
                  </div>
                  <h4>
                    <a className="chgcolor">.NET Web Services & API</a>
                  </h4>
                </div>
              </div>


              {/* Asp.net */}
              <div className="single-item col-lg-3 col-md-6">
                <div className="item">
                  {/* <i className="flaticon-money-1" /> */}
                  <div className="microsoftlogos">
                    <img src={dotnet} />
                  </div>
                  <h4>
                    <a className="chgcolor">.NET Desktop Development</a>
                  </h4>
                </div>
              </div>

              {/* phone */}
              <div className="single-item col-lg-3 col-md-6">
                <div className="item">
                  {/* <i className="flaticon-analysis" /> */}
                  <div className="microsoftlogos">
                    <img src={WindowsPhone} />
                  </div>
                  <h4>
                    <a className="chgcolor">.NET Windows Phone Development</a>
                  </h4>
                </div>
              </div>
              {/* custom asp.net */}
              <div className="single-item col-lg-3 col-md-6">
                <div className="item">
                  <div className="microsoftlogos">
                    <img src={AspDotNet} />
                  </div>
                  <h4>
                    <a className="chgcolor">
                      Custom .NET / ASP.NET Development
                    </a>
                  </h4>
                </div>
              </div>

              {/* silverlight */}
              <div className="single-item col-lg-3 col-md-6">
                <div className="item">
                  <div className="microsoftlogos">
                    <img src={silverlight} />
                  </div>
                  <h4>
                    <a className="chgcolor">Silverlight .NET WPF Development</a>
                  </h4>
                </div>
              </div>

              {/* End Single Item */}
            </div>
          </div>
          {/* End Services Items */}
        </div>
      </div>
      <div class="blog-area bg-gray default-padding bottom-less">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 offset-lg-2">
              <div class="site-heading text-center">
                {/* <h4>Our Services</h4> */}
                <h2>System Integrations</h2>
                <div class="devider"></div>
                <p>
                  Companies today have invested heavily in systems ranging from
                  off the shelf products to legacy and new custom software
                  written specific to their needs. .NET based mid-tier and API
                  integrations have helped many businesses to get the
                  information flow between these systems.
                </p>
                <p>
                  Vaanam has been instrumental in providing
                  our .NET based expertise in providing integration
                  possibilities using web services to capture and retrieve the
                  information from two or more software systems. We have worked
                  on specific .NET based software middleware solutions based on
                  DLL, API or Web Services providing access to information that
                  otherwise is hard to obtain through different software
                  solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="services-items">
            <div class="row">
              {/* <!-- Single Item --> */}
              <div
                class="col-lg-3 col-md-6 single-item wow fadeInUp"
                data-wow-delay="300ms"
              >
                <div class="item">
                  <div class="card">
                    <div class="card-body">
                      {/* <i class="flaticon-money"></i> */}
                      <div className="microsoftlogos">
                        <img src={Agile} />
                      </div>
                      <h4>
                        <a>True Agile</a>
                      </h4>
                      <p>
                        Vaanam offers its services in true
                        agile fashion. Being nature as is of app development,
                        today’s companies need partners that believe in
                        philosophy of agile and being lean at all times. We are
                        a true agile .net application development company
                        offering intuitive engagement models that help
                        businesses to bring changes to requirements at any stage
                        of their software development.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End Single Item -->
                            <!-- Single Item --> */}
              <div
                class="col-lg-3 col-md-6 single-item wow fadeInUp"
                data-wow-delay="500ms"
              >
                <div class="item">
                  <div class="card">
                    <div class="card-body">
                      {/* <i class="flaticon-budget"></i> */}
                      <div className="microsoftlogos">
                        <img src={Scrum} />
                      </div>
                      <h4>
                        <a>Scrum</a>
                      </h4>
                      <p>
                        Software development life cycle has gone far from being
                        linear and waterfall model no longer serves the purpose
                        of complexity involved in today’s innovation needs. We
                        offer agile project development methodology in our .NET
                        / ASP.NET application software development services.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End Single Item -->
                            <!-- Single Item --> */}
              <div
                class="col-lg-3 col-md-6 single-item wow fadeInUp"
                data-wow-delay="700ms"
              >
                <div class="item">
                  <div class="card">
                    <div class="card-body">
                      {/* <i class="flaticon-money-1"></i> */}
                      <div className="microsoftlogos">
                        <img src={UiUx} />
                      </div>
                      <h4>
                        <a>UI / UX</a>
                      </h4>
                      <p>
                        Vaanam hosts its own team of UI /
                        UX specialists that guide us create intuitive, user
                        friendly user interfaces with unique designs yet
                        accessible to the needs of users with respect to the
                        screen sizes available. With our team of .NET developers
                        working along with UX specialists, we make sure that all
                        screens are tested for UX needs of users before
                        converting into actual software.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End Single Item -->
                            <!-- Single Item --> */}
              <div
                class="col-lg-3 col-md-6 single-item wow fadeInUp align-item-center"
                data-wow-delay="400ms"
              >
                <div class="item">
                  <div class="card">
                    <div class="card-body">
                      {/* <i class="flaticon-funds"></i> */}
                      <div className="microsoftlogos">
                        <img src={DotNetDev} />
                      </div>
                      <h4>
                        <a>Top .NET developers</a>
                      </h4>
                      <p>
                        We only hire top of the top .NET developers that have
                        gained experience in working with unique set of client
                        problems helping us serve our clients with ease. Our
                        .NET engineers have access to special training and
                        resources that gives us an edge in handling complex .NET
                        software requirements.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End Single Item --> */}
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop smooth color="#2b5690" />
      <Footer />
    </div>
  );
}

export default MicrosoftServices;
