import React from "react";
import AxiosServices from "./AxiosServices";

const Auth = new AxiosServices();

class EventServices {
  Create = async (data) => {
    return Auth.post(`Event/Create`, data);
  };
  UploadFiles = async (uploadfiles) => {
    return Auth.post(`Event/FilesUpload`, uploadfiles);
  };
  GetEdit = async (eventID) => {
    return Auth.get(`Event/GetEdit?id=${eventID}`);
  };
  GetEventList = async () => {
    return Auth.get(`Event/GetEventList`);
  };
  Delete = async (id) => {
    return Auth.get(`Event/DeleteEvent?id=${id}`);
  };
  DeleteImage = async (id) => {
    return Auth.get(`Event/DeleteEventImage?id=${id}`);
  };
  Client_EventList = async () => {
    return Auth.get(`Event/Client_GetEventList`);
  };
  Client_AllEventList = async () => {
    return Auth.get(`Event/Client_GetAllEventList`);
  };
  Client_EventImages = async (id) => {
    return Auth.get(`Event/Client_EventImages?id=${id}`);
  };
}

export default EventServices;
