import AxiosServices from "./AxiosServices";

const Auth = new AxiosServices();

class H1B_Services{
   

      Add_ApplierDetails = async (data) => {
        
        return await Auth.post(`H1B/Add_ApplierDetails`, data);
      };
      PassportUpload = async (uploadresume) => {
        
         return await Auth.post('H1B/PassportUpload', uploadresume, {
          "content-type": "multipart/form-data"
        });
      };

      Get_H1B_Appliers_Lists = async () => {
       
        return await Auth.get('/H1b/Get_H1B_Appliers_Lists');

      };

     
      ViewApplierById = async (ID) => {
        
        return  await Auth.get(`H1B/ViewApplierById?id=${ID}`);
      };
      GetCountryList  = async () => {
        
        return await Auth.get(`H1B/GetCountryList`);
      };
      

}
export default H1B_Services