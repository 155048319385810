import React, { useEffect } from "react";
import { Header } from "../../Client/Home/Header";
import bannerrightshape from "../../../Assets/img/banner/1.jpg";
import AIML from "../../../Assets/img/banner/6.jpg";
import Agile from "../../../../src/Assets/img/agile-logo.png";
import Scrum from "../../../Assets/img/Scrum.png";
import UiUx from "../../../Assets/img/Ui-Ux.png";
import AspDotNet from "../../../Assets/img/AspDotNet.jpeg";
import silverlight from "../../../Assets/img/silverlight.jpeg";
import dotnet from "../../../Assets/img/dotnet.png";
import WindowsPhone from "../../../Assets/img/windows-phone.png";
import WebApi from "../../../Assets/img/WebApi.png";
import Azure from "../../../Assets/img/Microsoft Azure.png";
import Crm from "../../../Assets/img/Crm.jpeg";
import DotNetSupport from "../../../Assets/img/NET-Framework-Logo.png";
import Umbraco from "../../../Assets/img/Umbraco.png";
import DotNetDev from "../../../Assets/img/DotNetDev.png";
import About from "../../../Assets/img/about/1.jpg";
import { Footer } from "./Footer";
import ScrollToTop from "react-scroll-to-top";
import $ from "jquery";

function AIMLService() {
  useEffect(() => {
    setTimeout(() => {
      $("#preloader").hide();
    }, 1000);
  });
  return (
    <div>
      <Header />
      <div
        className="breadcrumb-area text-center shadow dark text-light bg-cover"
        style={{
          backgroundImage: `url(${bannerrightshape})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1 style={{ textTransform: "initial" }}>AI and ML Services</h1>
              <ul className="breadcrumb">
                <li>
                  <a href="/">
                    <i className="fas fa-home" /> Home
                  </a>
                </li>
                <li className="active" style={{ textTransform: "initial" }}>
                  AI and ML Services
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="services-details-area default-padding">
        <div className="container">
          <div className="services-details-items">
            <div className="row">
              <div className="col-lg-8 services-single-content">
                <h2 style={{ textTransform: "initial" }}>AI and ML Services</h2>
                <h4 style={{ textTransform: "initial" }}>
                  Artificial Intelligence Machine Learning
                </h4>
                <p>
                  Artificial Intelligence and Machine Learning solutions helps the businesses succeed by recognising the latest trends, allowing smart decision-making, and emerging market-winning strategies. Vaanam provide artificial intelligence solutions with human-like skills, such as reasoning, learning, and self-improvement, without pre-program the software.
                </p>

                <p>
                  With Vaanam's Artificial Intelligence & Machine Learning services, we can automate processes, strengthen customer relations, control enhanced analytics, and advanced business processes; ensuring you to stay relevant to the developing market and customer needs.
                </p>
                <h4>Industry 4.0</h4>
                <img src={AIML} width="100%" />
                <br /> <br />
                <p>
                  Artificial intelligence has brought a change in the operations of industry, driven by a new form of interaction between man and machine. This industrial revolution brings the Industry 4.0.
                </p>
                <p>Vaanam will help you to make smart decisions and expand your business over time. We provides end-to-end IoT solutions, including a predictive maintenance model, structures Inspection platform, and energy consumption prediction model.</p>

                <h4>Other Industries We Serve</h4>
                <p>
                  Our customized AI and ML solutions can serve industries like healthcare, eCommerce, retail, and more.
                </p>
              </div>
              <div className="col-lg-4 services-sidebar">
                <div className="single-widget services-list">
                  <h4 className="widget-title">Services List</h4>
                  <div className="content">
                    <ul>
                      <li>
                        <a href="/Enterprise">Enterprise Application</a>
                      </li>
                      <li>
                        <a href="/MicrosoftServices">Microsoft Services</a>
                      </li>
                      <li className="current-item">
                        <a href="/AIML" style={{ textTransform: "initial" }}>
                          AI and ML Services
                        </a>
                      </li>
                      <li>
                        <a href="/Mobility">Mobility Services</a>
                      </li>
                      <li>
                        <a href="/BusinessIntellegence">
                          Business Intelligence
                        </a>
                      </li>
                      <li>
                        <a href="/Reality">Reality Technologies</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="single-widget quick-contact text-light"
                  style={{ backgroundImage: `url(${About})` }}
                >
                  <div className="content">
                    <i className="fas fa-phone" />
                    <h4>Need any help?</h4>

                    <h2>+91 422 256 7626</h2>
                  </div>
                </div>
              </div>
              {/* Vaanam Technologies offers its expertise in .NET development under these areas */}
            </div>
          </div>
        </div>
      </div>

      <ScrollToTop smooth color="#2b5690" />
      <Footer />
    </div>
  );
}

export default AIMLService;
