import about2 from "../../../Assets/img/about/2.jpg";
export const Whychooseuse = () => (
  <>
    {/* Star Why Choose Us Area
      ============================================= */}
    <div className="choose-us-area default-padding-bottom">
      <div className="container">
        <div className="item-box">
          <div className="row">
            <div
              className="col-lg-6 thumb"
              style={{ backgroundImage: `url(${about2})` }}
            >
              <div className="transform-text">
                <h2>vaanam</h2>
              </div>
            </div>
            <div className="col-lg-6 info">
              <h4>Why Choose Us</h4>
              <h2>We working for you to build your business fast</h2>
              <p>
                We have a strong focus on frameworks for information
                availability and analysis, Vaanam has developed
                Centers of Excellence for each of its service areas, ranging
                from Cloud Applications to Mobiles Solutions and Business
                Intelligence.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End Why Choose Us Area */}
  </>
);
