import { Header } from "../Home/Header";
import ServImg from "../../../Assets/img/banner/1.jpg";
import { Footer } from "../Home/Footer";

import ScrollToTop from "react-scroll-to-top";
import React, { useEffect, useState } from "react";
import $ from "jquery";

import ClientServices from "../../../Services/ClientsServices";

var ClientServicesobj = new ClientServices();
const OurClients = () => {
  const [ClientDetails, setClientDetails] = useState([]);
  useEffect(() => {
    ClientServicesobj.GetClientList().then((res) => {
      if (res.data != null && res.data.length > 0) {
        setClientDetails([...res.data]);
        $("#preloader").hide();
      }
    });
  }, []);

  return (
    <>
      <Header />
      <div
        className="breadcrumb-area text-center shadow dark text-light bg-cover"
        style={{
          backgroundImage: `url(${ServImg})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
            <h1>Our Clients</h1>
              <ul className="breadcrumb">
                <li>
                  <a href="/">
                    <i className="fas fa-home" /> Home
                  </a>
                </li>
                <li className="active">Our Clients</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumb */}
  
      <div className="services-area ourclientsarea bg-gray default-padding bottom-less">
        {/* Fixed Shape */}
        {/* <div className="fixed-shape" style={{ backgroundImage: 'url(assets/img/shape/12.png)' }} /> */}
        {/* Fixed Shape */}
        <div className="container">
          <div className="services-heading">
            <div className="row">
              <div className="col-lg-7 left-info">
              
              </div>
            </div>
          </div>
          {/* Start Services Items */}
          <div className="services-content text-center">
            <div
              className="row"
              style={{ justifyContent: "center", alignItems: "center" }}
            >
             
             {ClientDetails != null && ClientDetails.length > 0 ? (
        ClientDetails.map((v, i) => {
          return (
            <div className="single-item col-lg-3 col-md-6">
            <div className="item">
              {/* <i className="flaticon-analysis" /> */}
              <div className="microsoftlogos">
                <img src={ process.env.REACT_APP_API_IMAGEPATH + v.logoUrl} />
              </div>
              <h4>
               
              </h4>
            </div>
          </div>
          );
        })
      ) : (
        <></>
      )}

        
            
       
          
              
              {/* End Single Item */}
            </div>
          </div>
          {/* End Services Items */}
        </div>
      </div>
      <ScrollToTop smooth color="#2b5690" />
      <Footer />
    </>
  );
};
export default OurClients;
