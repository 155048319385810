import { Header } from "../Home/Header";
import ServImg from "../../../Assets/img/banner/1.jpg";
import { Footer } from "../Home/Footer";
import about from "../../../Assets/img/about/1.jpg";
import ScrollToTop from "react-scroll-to-top";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import bgImage from "../../../Assets/img/shape/1.png";
import ProjectServices from "../../../Services/ProjectServices";
import Carousel from "react-multi-carousel";
import { useLocation } from 'react-router-dom';

var ProjectServicesObj = new ProjectServices();

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const containerStyle = {
  textAlign: 'center',
  marginBottom: '20px'
};

const OurProducts = () => {
  const [ProjectDetails, setProjectDetails] = useState([]);

  const location = useLocation();

  useEffect(() => {
    var getNav = $("nav.navbar.bootsnav"),
      getWindow = $(window).width(),
      getHeight = $(window).height(),
      getIn = getNav.find("ul.nav").data("in"),
      getOut = getNav.find("ul.nav").data("out");

    if (getWindow < 991) {
      // Height of scroll navigation sidebar
      $(".scroller").css("height", "auto");

      // Disable mouseenter event
      $("nav.navbar.bootsnav ul.nav").find("li.dropdown").off("mouseenter");
      $("nav.navbar.bootsnav ul.nav").find("li.dropdown").off("mouseleave");
      $("nav.navbar.bootsnav ul.nav").find(".title").off("mouseenter");
      $("nav.navbar.bootsnav ul.nav").off("mouseleave");

      // Enable click event
      $("nav.navbar.bootsnav ul.nav").each(function () {
        $(".dropdown-menu", this).removeClass(getOut);

        // Dropdown Fade Toggle
        $("a.dropdown-toggle", this).off("click");
        $("a.dropdown-toggle", this).on("click", function (e) {
          e.stopPropagation();
          $(this)
            .closest("li.dropdown")
            .find(".dropdown-menu")
            .first()
            .stop()
            .fadeToggle()
            .toggleClass(getIn);
          $(this).closest("li.dropdown").first().toggleClass("on");
          return false;
        });

        // Hidden dropdown action
        $("li.dropdown", this).each(function () {
          $(this).find(".dropdown-menu").stop().fadeOut();
          $(this).on("hidden.bs.dropdown", function () {
            $(this).find(".dropdown-menu").stop().fadeOut();
          });
          return false;
        });

        // Megamenu style
        $(".megamenu-fw", this).each(function () {
          $(".col-menu", this).each(function () {
            $(".content", this).stop().fadeOut();
            $(".title", this).off("click");
            $(".title", this).on("click", function () {
              $(this)
                .closest(".col-menu")
                .find(".content")
                .stop()
                .fadeToggle()
                .addClass(getIn);
              $(this).closest(".col-menu").toggleClass("on");
              return false;
            });

            $(".content", this).on("click", function (e) {
              e.stopPropagation();
            });
          });
        });
      });

      // Hidden dropdown
      var cleanOpen = function () {
        $("li.dropdown", this).removeClass("on");
        $(".dropdown-menu", this).stop().fadeOut();
        $(".dropdown-menu", this).removeClass(getIn);
        $(".col-menu", this).removeClass("on");
        $(".col-menu .content", this).stop().fadeOut();
        $(".col-menu .content", this).removeClass(getIn);
      };

      // Hidden om mouse leave
      $("nav.navbar.bootsnav").on("mouseleave", function () {
        cleanOpen();
      });

      // Enable click atribute navigation
      $("nav.navbar.bootsnav .attr-nav").each(function () {
        $("li.dropdown", this).off("mouseenter");
        $("li.dropdown", this).off("mouseleave");
        $("a.dropdown-toggle", this).off("click");
        $("a.dropdown-toggle", this).on("click", function (e) {
          e.stopPropagation();
          $(this)
            .closest("li.dropdown")
            .find(".dropdown-menu")
            .first()
            .stop()
            .fadeToggle();
          $(".navbar-toggle").each(function () {
            $(".fa", this).removeClass("fa-times");
            $(".fa", this).addClass("fa-bars");
            $(".navbar-collapse").removeClass("in");
            $(".navbar-collapse").removeClass("on");
          });
        });

        $(this).on("mouseleave", function () {
          $(".dropdown-menu", this).stop().fadeOut();
          $("li.dropdown", this).removeClass("on");
          return false;
        });
      });

      // Toggle Bars
      $(".navbar-toggle").each(function () {
        $(this).off("click");
        $(this).on("click", function () {
          $(".fa", this).toggleClass("fa-bars");
          $(".fa", this).toggleClass("fa-times");
          cleanOpen();
        });
      });
    } else if (getWindow > 991) {
      // Height of scroll navigation sidebar
      $(".scroller").css("height", getHeight + "px");

      // Navbar Sidebar
      if (getNav.hasClass("navbar-sidebar")) {
        // Hover effect Sidebar Menu
        $("nav.navbar.bootsnav ul.nav").each(function () {
          $("a.dropdown-toggle", this).off("click");
          $("a.dropdown-toggle", this).on("click", function (e) {
            e.stopPropagation();
          });

          $("li.dropdown", this).on("mouseenter", function () {
            $(".dropdown-menu", this).eq(0).removeClass(getOut);
            $(".dropdown-menu", this).eq(0).stop().fadeIn().addClass(getIn);
            $(this).addClass("on");
            return false;
          });

          $(".col-menu").each(function () {
            $(".title", this).on("mouseenter", function () {
              $(this)
                .closest(".col-menu")
                .find(".content")
                .stop()
                .fadeIn()
                .addClass(getIn);
              $(this).closest(".col-menu").addClass("on");
              return false;
            });
          });

          $(this).on("mouseleave", function () {
            $(".dropdown-menu", this).stop().removeClass(getIn);
            $(".dropdown-menu", this).stop().addClass(getOut).fadeOut();
            $(".col-menu", this)
              .find(".content")
              .stop()
              .fadeOut()
              .removeClass(getIn);
            $(".col-menu", this).removeClass("on");
            $("li.dropdown", this).removeClass("on");
            return false;
          });
        });
      } else {
        // Hover effect Default Menu
        $("nav.navbar.bootsnav ul.nav").each(function () {
          $("a.dropdown-toggle", this).off("click");
          $("a.dropdown-toggle", this).on("click", function (e) {
            e.stopPropagation();
          });

          $(".megamenu-fw", this).each(function () {
            $(".title", this).off("click");
            $("a.dropdown-toggle", this).off("click");
          });

          $("li.dropdown", this).on("mouseenter", function () {
            $(".dropdown-menu", this).eq(0).removeClass(getOut);
            $(".dropdown-menu", this).eq(0).stop().fadeIn().addClass(getIn);
            $(this).addClass("on");
            return false;
          });

          $("li.dropdown", this).on("mouseleave", function () {
            $(".dropdown-menu", this).eq(0).removeClass(getIn);
            $(".dropdown-menu", this).eq(0).stop().fadeOut().addClass(getOut);
            $(this).removeClass("on");
          });

          $(this).on("mouseleave", function () {
            $(".dropdown-menu", this).removeClass(getIn);
            $(".dropdown-menu", this).eq(0).stop().fadeOut().addClass(getOut);
            $("li.dropdown", this).removeClass("on");
            return false;
          });
        });
      }

      // ------------------------------------------------------------------------------ //
      // Hover effect Atribute Navigation
      // ------------------------------------------------------------------------------ //
      $("nav.navbar.bootsnav .attr-nav").each(function () {
        $("a.dropdown-toggle", this).off("click");
        $("a.dropdown-toggle", this).on("click", function (e) {
          e.stopPropagation();
        });

        $("li.dropdown", this).on("mouseenter", function () {
          $(".dropdown-menu", this).eq(0).removeClass(getOut);
          $(".dropdown-menu", this).eq(0).stop().fadeIn().addClass(getIn);
          $(this).addClass("on");
          return false;
        });

        $("li.dropdown", this).on("mouseleave", function () {
          $(".dropdown-menu", this).eq(0).removeClass(getIn);
          $(".dropdown-menu", this).eq(0).stop().fadeOut().addClass(getOut);
          $(this).removeClass("on");
        });

        $(this).on("mouseleave", function () {
          $(".dropdown-menu", this).removeClass(getIn);
          $(".dropdown-menu", this).eq(0).stop().fadeOut().addClass(getOut);
          $("li.dropdown", this).removeClass("on");
          return false;
        });
      });
    }

    // ------------------------------------------------------------------------------ //
    // Menu Fullscreen
    // ------------------------------------------------------------------------------ //
    if (getNav.hasClass("navbar-full")) {
      var windowHeight = $(window).height(),
        windowWidth = $(window).width();

      $(".nav-full").css("height", windowHeight + "px");
      $(".wrap-full-menu").css("height", windowHeight + "px");
      $(".wrap-full-menu").css("width", windowWidth + "px");

      $(".navbar-toggle").each(function () {
        var getId = $(this).data("target");
        $(this).off("click");
        $(this).on("click", function (e) {
          e.preventDefault();
          $(getId).removeClass(getOut);
          $(getId).addClass("in");
          $(getId).addClass(getIn);
          return false;
        });

        $("li.close-full-menu").on("click", function (e) {
          e.preventDefault();
          $(getId).addClass(getOut);
          setTimeout(function () {
            $(getId).removeClass("in");
            $(getId).removeClass(getIn);
          }, 500);
          return false;
        });
      });
    }
  }, []);

  useEffect(() => {
    ProjectServicesObj.Client_GetProjectList().then((res) => {

      if (res.data != null && res.data.length > 0) {
        setProjectDetails([...res.data]);
        setTimeout(() => {
          $("#preloader").hide();
        }, 2000);
        var isslid = window.location.hash;
        if (isslid.length > 4) {
          var slides = document.getElementById("Clickin");
          slides.click();
        }
      }
    });
  }, []);

  useEffect(() => {
    debugger;
    const queryParams = new URLSearchParams(location.search);
    const scrollToProjectID = queryParams.get('scrollToProjectID');

    if (scrollToProjectID) {
      let targetDiv = document.getElementById(`Pdescriptions_${scrollToProjectID}`);
      if (targetDiv) {
        const headerHeight = 70; // Height of your header, adjust as needed
        const offset = targetDiv.getBoundingClientRect().top + window.scrollY - headerHeight;
        window.scrollTo({
          top: offset,
          behavior: 'smooth',
        });
      }
    }
  }, [location.search, ProjectDetails]);
  const [isContentVisible, setIsContentVisible] = useState(false);

  function toggleContent(projectID) {

    setIsContentVisible(!isContentVisible);

    if (!isContentVisible) {
      var des = document.getElementById("Pdescription_" + projectID);
      var Btndes = document.getElementById("PBtn_" + projectID);

      Btndes.textContent = "Show less";
      des.classList.remove("hideContent");
      des.classList.add("showContent");
    } else {
      var des = document.getElementById("Pdescription_" + projectID);
      var Btndes = document.getElementById("PBtn_" + projectID);

      Btndes.textContent = "Show more";
      des.classList.remove("showContent");
      des.classList.add("hideContent");
    }
  }

  const scrollToDiv = (projectID) => {
    const targetDiv = document.getElementById(`Pdescription_${projectID}`);
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Header />
      <div
        className="breadcrumb-area text-center shadow dark text-light bg-cover"
        style={{
          backgroundImage: `url(${ServImg})`,
        }}
      >
        <div className="container">
          <a
            href={window.location.hash}
            id="Clickin"
            style={{ display: "none" }}
            className="anchor"
          >
            fhf
          </a>
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>Our Products</h1>
              <ul className="breadcrumb">
                <li>
                  <a href="/">
                    <i className="fas fa-home" /> Home
                  </a>
                </li>
                <li className="active">Our Products</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            {/* End Breadcrumb */}
            {ProjectDetails != null && ProjectDetails.length > 0 ? (
              ProjectDetails.map((v, i) => {
                return (
                  <div id={`Pdescriptions_${v.projectID}`}>
                    {" "}
                    <div className="team-single-area default-padding">
                      <div className="container">
                        <div className="team-content-top">
                          <div className="row">
                            <div className="col-lg-12 left-info">
                              <h2>
                                {" "}
                                <a target="_blank" href={v.websiteUrl}>
                                  <img
                                    className="projectlogodet"
                                    src={
                                      process.env.REACT_APP_API_IMAGEPATH +
                                      v.logoUrl
                                    }
                                  />
                                </a>
                              </h2>

                            </div>
                            <div className="col-lg-12 right-info">
                              <div className="thumb">
                                <img
                                  src={
                                    process.env.REACT_APP_API_IMAGEPATH +
                                    v.imageUrl
                                  }
                                  alt="Thumb"
                                />
                              </div>
                              <br />
                              <br />
                              <p
                                id={"Pdescription_" + v.projectID}
                                className="hideContent"
                              >
                                {v.description}
                              </p>
                              {v.description.length < 380 ? (
                                <a
                                  style={{ display: "none" }}
                                  id={"PBtn_" + v.projectID}
                                  onClick={() => toggleContent(v.projectID)}
                                >
                                  Show more
                                </a>
                              ) : (
                                <a
                                  id={"PBtn_" + v.projectID}
                                  onClick={() => toggleContent(v.projectID)}
                                >
                                  Show more
                                </a>
                              )}
                              {v.description.length > 380 ? (
                                <>
                                  {" "}
                                  <br />
                                  <br />
                                </>
                              ) : (
                                <></>
                              )}

                              <h6>
                                Web Site:{" "}
                                <a target="_blank" href={v.websiteUrl}>
                                  {v.websiteUrl}
                                </a>
                              </h6>
                            </div>
                          </div>
                          <br />
                          <br />
                          {v.clientlogoUrls != null && v.clientlogoUrls.length > 0 ?
                            <div className="partner-area overflow-hidden text-light" style={{ height: "150px" }}>
                              <div className="">

                                <div className="item-box">

                                  <div className="row ">


                                    <div className="col-lg-12 clients">

                                      <div className="partner-carousel owl-carousel owl-theme text-center">
                                        <Carousel
                                          autoPlay={true}
                                          infinite={true}

                                          autoPlaySpeed={2500}
                                          responsive={responsive}
                                          showDots={false}

                                          removeArrowOnDeviceType={[
                                            "tablet",
                                            "mobile",
                                            "superLargeDesktop",
                                            "desktop",
                                          ]}
                                        >
                                          {v.clientlogoUrls != null && v.clientlogoUrls.length > 0 ? (
                                            v.clientlogoUrls.map((v, i) => {
                                              return (
                                                <div>
                                                  <div className="single-item clients">
                                                    <a>
                                                      <img
                                                        src={
                                                          process.env.REACT_APP_API_IMAGEPATH +
                                                          v.clientlogoUrl
                                                        }
                                                        alt="Clients"
                                                      />
                                                    </a>
                                                  </div>
                                                </div>
                                              );
                                            })
                                          ) : (
                                            <></>
                                          )}
                                        </Carousel>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> : <></>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>

          <div className="col-lg-4">
            <br />
            <div class="container ">
              <div class="content-box">
                <div class="info">
                  <h4>Need any help?</h4>
                </div>
                <hr style={{ borderTop: "1px solid #212529" }} />
                <div className="col info wow fadeInDown">
                  <div class="call">
                    <div class="icon">
                      <i class="flaticon-telephone"></i>
                    </div>
                    <div class="intro">
                      <a href="tel:+91 422 256 7626">
                        {" "}
                        <h5>+91 422 256 7626</h5>
                      </a>
                      <span>Call to ask any question</span>
                    </div>
                  </div>
                  <div class="callss"></div>
                </div>
                <div className="col-lg-6 info wow fadeInDown">
                  <div class="call">
                    <div class="icon">
                      <i
                        class="fas fa-envelope"
                        style={{ lineHeight: "72px", fontSize: "37px" }}
                      ></i>
                    </div>
                    <div class="intro">
                      <a href="mailto:info@vaanamtech.com">
                        <h5>info@vaanamtech.com</h5>
                      </a>
                      <span>Mail us for any clarifications</span>
                    </div>
                  </div>
                  <div class="callss"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop smooth color="#2b5690" />

      <Footer />
    </>
  );
};
export default OurProducts;
