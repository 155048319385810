import { data } from "jquery";
import AxiosServices from "./AxiosServices";

const Auth = new AxiosServices();

class privacyServices {
    Create = async (data) => {
        return Auth.post(`Privacy/Create`,data);
    };
    PrivacyList = async () => {
        return Auth.get(`Privacy/PrivacyList`);
      };
}
export default privacyServices;