import { Banner } from "./Banner";
import { Header } from "./Header";
import React, { useEffect, useState } from "react";
import bannerrightshape from "../../../Assets/img/banner/1.jpg";
import TermsServices from "../../../Services/TermsServices";
import { Footer } from "./Footer";
import ScrollToTop from "react-scroll-to-top";
import $ from "jquery";

var TermsServicesObj = new TermsServices();

function Terms() {
  const [body, setBody] = useState("");
  useEffect(() => {
    TermsServicesObj.GetTermsClient().then((res) => {
      console.log(res.data.content);
      if (res.data != null) {
        setBody(res.data.content);
      }
      setTimeout(() => {
        $("#preloader").hide();
      }, 1000);
    });
  }, []);

  return (
    <div>
      {/* Start Breadcrumb 
============================================= */}

      <Header />
      <div
        className="breadcrumb-area text-center shadow dark text-light bg-cover"
        style={{
          backgroundImage: `url(${bannerrightshape})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>Terms &amp; Conditions</h1>
              <ul className="breadcrumb">
                <li>
                  <a href="/">
                    <i className="fas fa-home" /> Home
                  </a>
                </li>
                <li className="active">Terms &amp; Conditions</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumb */}
      {/* Start Terms Policy 
============================================= */}
      <div className="terms-policy-area default-padding">
        <i className="" />
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div
                dangerouslySetInnerHTML={{ __html: body }}
                className="terms"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop smooth color="#2b5690" />
      {/* End Terms Policy */}
      <Footer />
    </div>
  );
}
export default Terms;
