import React from "react";
import "./App.css";
import "../src/Assets/kendo-ui/kendo-ui/styles/kendo.common.min.css";
import "../src/Assets/kendo-ui/kendo-ui/styles/kendo.material.min.css";
import "../src/Assets/kendo-ui/kendo-ui/js/kendo.all.min.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginView from "../src/Components/Admin/Login/LoginView";
import ForgotView from "../src/Components/Admin/ForgotPassWord/ForgotPassWord";
import ConfirmPassword from "../src/Components/Admin/ForgotPassWord/ChangePassword";
import OtpView from "../src/Components/Admin/ForgotPassWord/CheckOtp";
import { Home } from "./Components/Client/Home/Home";
import HomeBanner from "./Components/Admin/HomeBanner/HomeBanner";
import Dashboard from "./Components/Admin/DashBoard/Dashboard";
import ProjectsListTable from "./Components/Admin/Projects/ProjectsListTable";
import TestimonialsListTable from "./Components/Admin/Testimonials/TestimonialsListTable";
import EventListTable from "./Components/Admin/LatestEvents/EventListTable";
import H1B_Appliers from "./Components/Admin/H1B/H1B_Appliers";
import { EventImages } from "./Components/Client/Home/EventImages";
import AdminPrivacy from "./Components/Admin/Privacy/AdminPrivacy";
import { Privacy } from "./Components/Client/Privacy/Privacy";
import Terms from "./Components/Client/Home/Terms";
import TermsListTable from "./Components/Admin/Terms/TermsListTable";
import Career from "./Components/Client/Career/Career";
import CareerListTable from "./Components/Admin/Career/CareerListTable";
import Enterprise from "./Components/Client/Solutions/Enterprise";
import ServiceHome from "./Components/Client/Home/ServiceHome";
import Mobility from "./Components/Client/Solutions/Mobility";
import Reality from "./Components/Client/Solutions/Reality";
import OurProducts from "./Components/Client/Services/OurProducts";
import OurClients from "./Components/Client/Home/OurClients";
import Contact from "./Components/Client/Contact/Contact";
import ContactListTable from "./Components/Admin/Contact/ContactListTable";
import About from "./Components/Client/AboutUs/About";
import AdminFaq from "./Components/Admin/AdminFaq/AdminFaq";
import Faq from "./Components/Client/Home/Faq";
import MicrosoftServices from "./Components/Client/Home/MicrosoftServices";
import BusinessIntellegence from "./Components/Client/Solutions/Business Intellegence/BusinessIntellegence";
import BIAssessment from "./Components/Client/Solutions/Business Intellegence/BIAssessment";
import BIMigration from "./Components/Client/Solutions/Business Intellegence/BIMigration";
import BIReportsandAnalysis from "./Components/Client/Solutions/Business Intellegence/BIReportsandAnalysis";
import { NewsandEvents } from "./Components/Client/Home/NewsandEvents";
import ClientsListTable from "./Components/Admin/Clients/ClientsListTable";
import ALMLServices from "./Components/Client/Home/AIMLService";
import Apply_H1B from "./Components/Client/Career/Apply_H1B";



function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route path="/EventImages" element={<EventImages />}></Route>
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/Terms" element={<Terms />} />
        <Route path="/Career" element={<Career />}></Route>
        <Route path="/Apply_H1B" element={<Apply_H1B />}></Route>
        <Route path="/ServiceHome" element={<ServiceHome />} />
        <Route path="/Enterprise" element={<Enterprise />} />
        <Route path="/Mobility" element={<Mobility />} />
        <Route path="/Reality" element={<Reality />} />
        <Route path="/OurProducts" element={<OurProducts />} />
        <Route path="/OurClients" element={<OurClients />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/About" element={<About />} />
        <Route path="/Faq" element={<Faq />} />
        <Route path="/MicrosoftServices" element={<MicrosoftServices />} />
        <Route path="/AIML" element={<ALMLServices />} />
        <Route
          path="/BusinessIntellegence"
          element={<BusinessIntellegence />}
        ></Route>
        <Route path="/NewsEvents" element={<NewsandEvents />} />
        <Route path="/BIAssessment" element={<BIAssessment />}></Route>
        <Route path="/BIMigration" element={<BIMigration />}></Route>
        <Route path="/BIReports" element={<BIReportsandAnalysis />}></Route>
        <Route path="/Admin" element={<LoginView />}></Route>
        <Route path="/OtpView" element={<OtpView />} />
        <Route path="/ChangePassword" element={<ConfirmPassword />} />
        <Route path="/ForgotView" element={<ForgotView />} />
        <Route path="/DashboardView" element={<Dashboard />}></Route>
        <Route path="/HomeBanner" element={<HomeBanner />}></Route>
        <Route path="/Projects" element={<ProjectsListTable />}></Route>
        <Route path="/Testimonial" element={<TestimonialsListTable />}></Route>
        <Route path="/Events" element={<EventListTable />}></Route>
        <Route path="/AdminPrivacy" element={<AdminPrivacy />} />
        <Route path="/AdminTerms" element={<TermsListTable />} />
        <Route path="/AdminCareer" element={<CareerListTable />}></Route>
        <Route path="/ContactListTable" element={<ContactListTable />} />
        <Route path="/AdminFaq" element={<AdminFaq />} />
        <Route path="/clients" element={<ClientsListTable />} />
        <Route path="/H1B_Appliers" element={<H1B_Appliers />} />
      </Routes>
    </Router>
  );
}
export default App;
