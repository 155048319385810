import React from "react";
import AxiosServices from "./AxiosServices";

const Auth = new AxiosServices();

class ContactServices 
{
  Create = async (data) => {
    return await Auth.post(`Contact/Create`, data);
  };
  GetContactList = async () => {
    return Auth.get(`Contact/ContactList`);
  };
}

export default ContactServices;